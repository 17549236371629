import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import { formatedTime } from '../../utils/timeUtils'
import { firestore } from 'firebase';
import { getUserRef, obtenerTotalSubcomentarios, getSubcomentariosRef, setSubcomentarClase, setNoMeGustaComentario, deleteComentario, setMeGustaComentario } from '../../firebase/firebase.utils'
import SubComentarioItem from './SubComentarioItem';
class ComentarioItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comentario: '',
            userComentario: '',
            likes: 0,
            likesUser: [],
            liked: false,
            subcomentario: '',
            subcomentarios: [],
            responder: false,
            numSubcomentarios: 3,
            loadingSubcomentarios: true,
            totalSubcomentarios: 0,
            masSubcomentarios: true,
        }
        obtenerTotalSubcomentarios(props.comentario.id, this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso).then((result) => {
            this.setState({ totalSubcomentarios: result })
        })
    }
    meGustaComentario = () => {
        this.setState({ likes: this.state.likes + 1, liked: true })
        setMeGustaComentario(this.props.curso, this.props.modulo, this.props.clase, this.props.comentario.id, this.props.comentario.userID, this.props.user)
    }
    noMeGustaComentario = () => {
        this.setState({ likes: this.state.likes - 1, liked: false })
        setNoMeGustaComentario(this.props.curso, this.props.modulo, this.props.clase, this.props.comentario.id, this.props.comentario.userID, this.props.user)
    }
    borrarComentario = async () => {
        await deleteComentario(this.props.curso, this.props.modulo, this.props.clase, this.props.comentario.id, this.props.comentario.userID, this.props.user)
        this.props.deleteComentario()
    }
    handleSubcomentario = (e) => {
        this.setState({ subcomentario: e.target.value })
    }
    handleCancelar = (e) => {
        e.preventDefault()
        this.setState({ subcomentario: '', responder: false })
    }
    handleResponder = async (e) => {
        e.preventDefault()
        if (this.state.subcomentario.trim() != "") {
            this.setState({ loadingSubcomentarios: true })
            const newSubcomment = await setSubcomentarClase(this.state.subcomentario, this.props.user, this.props.match.params.idcurso, this.props.match.params.idmodulo, this.props.match.params.idclase, this.props.comentario.id)
            newSubcomment.get().then(subcomentario => {
                var arraySubcomentarios = this.state.subcomentarios
                this.setState({ subcomentarios: [] })
                var subcomentarioRaw = subcomentario.data()
                subcomentarioRaw.id = subcomentario.id
                arraySubcomentarios.unshift(subcomentarioRaw)
                this.setState({ subcomentario: '', responder: false, loadingSubcomentarios: false, subcomentarios: arraySubcomentarios, totalSubcomentarios: this.state.totalSubcomentarios + 1 })
            })
        }
    }
    showResponder = () => {
        this.setState({ responder: !this.state.responder })
    }
    showPlace = (indice) => {
        switch (indice + 1) {
            case 1:
                return "PrimerLugar";
                break;
            case 2:
                return "SegundoLugar";
                break;
            case 3:
                return "TercerLugar";
                break;
            case 4:
                return "CuartoLugar";
                break;
            case 5:
                return "QuintoLugar";
                break;
            default:
                return "";
                break
        }
    }
    deleteSubcomentario = () => {
        this.setState({ subcomentarios: [], totalSubcomentarios: this.state.totalSubcomentarios - 1, loadingSubcomentarios: true })
        this.obtenerSubcomentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.props.comentario.id, this.state.numSubcomentarios)
    }
    handleCargarSubcomentarios = () => {

        this.setState({ subcomentarios: [], loadingSubcomentarios: true })
        this.obtenerSubcomentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.props.comentario.id, this.state.numSubcomentarios + 5)
        this.setState({ numSubcomentarios: this.state.numSubcomentarios + 5 })
    }
    obtenerSubcomentarios = async (idClase, idModulo, idCurso, idComentario, numSubcomentarios) => {
        const subcomentariosRef = await getSubcomentariosRef(idCurso, idModulo, idClase, idComentario, numSubcomentarios)
        await subcomentariosRef.get().then(qSnapshot => {
            var arraySubcomentarios = []
            qSnapshot.forEach(subcomentario => {
                var subcomentarioRaw = subcomentario.data()
                subcomentarioRaw.id = subcomentario.id
                arraySubcomentarios.push(subcomentarioRaw)
            })
            if (qSnapshot.size < this.state.totalSubcomentarios) {
                this.setState({ masSubcomentarios: true })
            } else {
                this.setState({ masSubcomentarios: false })
            }
            this.setState({ subcomentarios: arraySubcomentarios, loadingSubcomentarios: false })
        })
    }
    componentDidMount() {
        this.obtenerSubcomentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.props.comentario.id, this.state.numSubcomentarios)
        this.setState({
            comentario: this.props.comentario.comentario,
            baneado: this.props.comentario.baneado,
            createdAt: new Date(this.props.comentario.createdAt.seconds * 1000).toLocaleString('es-MX'),
            userComentario: this.props.comentario.userID,
            likes: this.props.comentario.likes,
            likesUser: this.props.comentario.likesUser,
            place: this.showPlace(this.props.indice)
        })
        if (this.props.comentario.likesUser) {
            this.props.comentario.likesUser.find(e => {
                if (e == this.props.user) {
                    this.setState({ liked: true })
                }
            })
        }
        getUserRef(this.props.comentario.userID).get().then(user => {
            if (user.exists) {
                this.setState({ userComentario: user.data() })
            }
        })
    }
    render() {
        return (
            <Fragment>
                <div className="row mb-1">
                    <div className="col-2 col-lg-1  mb-1" style={{ textAlign: 'right' }}>
                        <div className="avatar avatar-circle" style={{ marginTop: '10px' }}>
                            <img className="avatar-img" src={this.state.userComentario.photoURL} alt="Image Description" />
                        </div>
                    </div>
                    <div className="col-10 col-lg-11  mb-1">
                        <div className="col-lg-12">
                            {this.props.topComentarios ? <span className={this.state.place}>{this.props.indice + 1}° <i className="fas fa-trophy"></i></span> : null} - <b>{this.state.userComentario.displayName}</b> - {this.state.createdAt} dijo:
                            <p className={this.state.baneado ? "comentario comentarioBaneado" : "comentario "}>{this.state.baneado ? 'Este comentario ha sido bloqueado por infringir las Normas de la Comunidad.' : this.state.comentario}</p>
                            {this.state.baneado ?
                                null
                                :
                                this.state.liked ?
                                    <button onClick={this.noMeGustaComentario} className="btn btn-likeActive"><i className="fas fa-thumbs-up"></i> {this.state.likes} Me gusta</button>
                                    :
                                    <button onClick={this.meGustaComentario} className="btn btn-like"><i className="far fa-thumbs-up"></i> {this.state.likes} Me gusta</button>
                            }
                            {this.state.baneado ?
                                null
                                :
                                <button onClick={this.showResponder} className="btn btn-like"><i className="fas fa-reply"></i> Responder</button>
                            }
                            {this.props.comentario.userID == this.props.user ?
                                <button onClick={this.borrarComentario} className="btn btn-like d-none d-md-block float-right">Eliminar</button>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                {this.props.user && this.state.responder && this.props.userStatus == "activo" ?
                    <div className="row " style={{ marginTop: '0' }}>
                        <div className="col-2 col-lg-1  mb-1" style={{ textAlign: 'right' }}>
                        </div>
                        <div className="col-2 col-lg-1  mb-1" style={{ textAlign: 'right' }}>
                            <div className="avatar avatar-circle" >
                                <img className="avatar-img" src={this.props.userImagen} alt="User Profile" />
                            </div>
                        </div>
                        <div className="col-8 col-lg-10  mb-1">
                            <div className="comentariosBox">
                                <textarea className="form-control " value={this.state.subcomentario} onChange={this.handleSubcomentario} rows="3" id="descriptionTextarea" placeholder="Escribe alguna respuesta..." style={{ marginTop: '0px', marginBottom: '0px', height: '80px' }} />
                            </div>
                        </div>
                        <div className="col-12" style={{ textAlign: 'right' }}>
                            <button onClick={this.handleCancelar} className="btn btn-link mr-2 mb-1">Cancelar</button>
                            <button onClick={this.handleResponder} className="btn btn-primary mb-1">Responder</button>
                        </div>
                    </div>
                    :
                    <Fragment></Fragment>
                }
                <div className="loaderMin" style={{ display: this.state.loadingSubcomentarios ? 'flex' : 'none' }}>
                    <div className="Box"></div>
                </div>
                <div style={{ display: this.state.loadingSubcomentarios ? 'none' : 'block' }}>
                    {this.state.subcomentarios.map((subcomentario, idx) => (
                        <SubComentarioItem key={idx} deleteSubcomentario={this.deleteSubcomentario} curso={this.props.curso} modulo={this.props.modulo} clase={this.props.clase} comentario={this.props.comentario.id} user={this.props.user} userImagen={this.state.userImagen} subcomentario={subcomentario} />
                    ))}
                </div>
                {this.state.masSubcomentarios ?
                    <div className="row mb-3">
                        <div className="col-10 offset-2">
                            <button className="btn btn-primary " style={{ width: '100%' }} onClick={this.handleCargarSubcomentarios}>Cargar mas respuestas</button>
                        </div>
                    </div>
                    :
                    null
                }
            </Fragment>
        )
    }
}
export default withRouter(ComentarioItem)