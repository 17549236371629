import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'

import QRCode from 'qrcode.react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faGavel, faServer } from '@fortawesome/free-solid-svg-icons'
import {  faCircle } from '@fortawesome/free-regular-svg-icons'


class DocumentacionUsuario extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentación - Usuario';
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Documentación - Usuario">

                    <main id="content" role="main">
                        <div class=" faqBg">
                            <div class="container space-top-1 space-top-lg-2 textWhite space-bottom-2 position-relative z-index-2">
                                <div class="w-md-80 w-lg-60 text-center mx-md-auto">
                                    <h1 className="textWhite">Documentación</h1>
                                    <h3 className="textWhite">Usuario</h3>
                                </div>
                            </div>
                        </div>
                        <div class="container space-2 space-bottom-lg-3">
                            <div class="row justify-content-lg-center">
                                <div class="col-lg-10">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/">Red Itmex</Link></li>
                                        <li class="breadcrumb-item"><Link to="/documentacion">Documentación</Link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Usuario</li>
                                    </ol>
                                </nav>
                                    <div id="basics">
                                        <table class="table table-bordered w-100 mb-4">
                                            <tbody>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/usuario/folleto-bienvenida/pdf/v1">Folleto de Bienvenida</a></td>
                                                    <td>v1</td>
                                                    <td>27/09/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/usuario/folleto-bienvenida/pdf/v1" renderAs="svg" size="100" includeMargin={true}/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
            </div>
        )
    }
}

export default DocumentacionUsuario;