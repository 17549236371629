import React from 'react';
import { Link } from 'react-router-dom'

const Recompensa = (props) => (
    <div className="col-6 col-md-3 mb-3">
        <center><img style={{ width: '50%' }} src={props.recompensa.awardImage} /></center><br />
        <b>{props.recompensa.descripcion}</b><br />
        {new Intl.NumberFormat().format(props.recompensa.puntos)} <i className="fas fa-donate PrimerLugar"></i>
        {props.disponible ? 
            <Link to="/contacto"><button className="btn btn-primary btn-sm" style={{ width: '100%', padding: '2px 0' }}>Canjear</button></Link>
            :
            <button className="btn btn-danger btn-sm disabled" style={{ width: '100%', padding: '2px 0' }}>Bloqueado</button>
        }
        </div>
)

export default Recompensa;