import React from 'react';
import Layout from '../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { getCursosRef, getModulosRef, getClasesRef } from '../firebase/firebase.utils';

class CursosHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursos: [],
            loading: true,
        }
    }


    obtenerCursos = async () => {
        var cursosList = []
        const cursosRef = await getCursosRef()
        await cursosRef.limit(6).get().then(qSnapshot => {
            qSnapshot.forEach(async curso => {
                const modulosList = []
                const modulosRef = getModulosRef(curso.id)
                await modulosRef.get().then(q2Snapshot => {
                    q2Snapshot.forEach(modulo => {
                        modulosList.push(modulo.data())
                    })
                })

                const dataRaw = curso.data()
                dataRaw.id = curso.id
                dataRaw.modulos = modulosList
                cursosList.push(dataRaw)
                // doc.data() is never undefined for query doc snapshots
                this.setState({ cursos: cursosList, loading: false })

            });
        })
    }

    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);

        let event2 = new Event('academia');
        window.dispatchEvent(event2);
        document.title = 'Red Itmex - Academia';
        this.obtenerCursos().then(() => {
        })
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Academia">
                    <div className="bg-dark" style={{ overflow: 'hidden' }}>
                        <div className="cube"></div>
                        <div className="cube"></div>
                        <div className="cube"></div>
                        <div className="cube"></div>
                        <div className="cube"></div>
                        <div className="cube"></div>
                        <div className="container space-2 ">
                            <div className="row justify-content-lg-between align-items-lg-center">
                                <div className="col-sm-12 col-lg-6">
                                    <div className="mb-5">
                                        <h1 className="display-4 mb-3 textWhite">
                                            Con <b>red itmex</b>
                                            <br />
                                            <span className="font-weight-bold">
                                                <span className="js-text-animation"></span>
                                            </span>
                                        </h1>
                                        <p className="lead">Nuevos cursos pensados en ti para que sigas aprendiendo estes donde estes.</p>
                                    </div>

                                    <div className="d-sm-flex align-items-sm-center flex-sm-wrap">
                                        <a className="btn btn-primary mb-2" href="/academia/cursos">Ver todos los cursos</a>

                                        <div className="mx-2"></div>

                                        <a className="js-fancybox video-player video-player-btn media align-items-center text-dark mb-2" href="javascript:;"
                                            data-hs-fancybox-options='{
                                                "src": "/assets/img/itmexportada_trim3.mp4",
                                                "caption": "Red Itmex - Academia",
                                                "speed": 700,
                                                "buttons": ["fullScreen", "close"],
                                                "youtube": {
                                                "autoplay": 1
                                                }
                                            }'>
                                            <span className="video-player-icon shadow-soft mr-3">
                                                <i className="fa fa-play"></i>
                                            </span>
                                            <span className="media-body textWhite">
                                                ¿Cómo funciona?
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.cursos == [] ?
                            <div></div>
                            :
                            <div>
                                <div className="loaderMin" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                                    <div className="Box"></div>
                                </div>
                                <div className="space-bottom-2 space-bottom-lg-3" style={{ display: this.state.loading ? 'none' : 'block', background: 'url(/assets/svg/components/abstract-shapes-9.svg)', backgroundRepeat: 'no-repeat', backgroundPosition: "left" }}>
                                    <div className="position-relative">
                                        <div className="container space-2">
                                            <div className="row align-items-md-center mb-7">
                                                <div className="col-md-6 mb-4 mb-md-0">
                                                    <h2>Revisa los últimos cursos que creamos para ti.</h2>
                                                </div>
                                                <div className="col-md-6 text-md-right">
                                                    <Link className="font-weight-bold" to="/academia/cursos/">Ver todos los cursos <i className="fa fa-angle-right fa-sm ml-1"></i></Link>
                                                </div>
                                            </div>
                                            <center>
                                            {this.state.cursos.map((curso) => (
                                                <article key={curso.id} className="pt-2 mr-3" style={{ textAlign: 'left', display: 'inline-block', width: '250px', maxWidth: '250px ' }}>
                                                    <Link className="card bg-img-hero min-h-270rem transition-3d-hover" to={"/academia/curso/" + curso.id} style={{ width: '250px', backgroundImage: 'url(' + curso.cursoImagen + ')' }}>
                                                        <div className="card-body">
                                                            <span className="d-block small text-white-70 font-weight-bold text-cap mb-2">{curso.categoria}</span>
                                                            <h3 className="text-white">{curso.title}</h3>
                                                        </div>
                                                        <div className="card-footer border-0 bg-transparent pt-0">
                                                            <span className="text-white font-size-1 font-weight-bold">Ver curso</span>
                                                        </div>
                                                    </Link>
                                                </article>
                                            ))}
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </Layout>
            </div>
        )
    }
}

export default CursosHome;