import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class FormatoSimplificado1347530PlanBasicoPlus_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Formato Simplificado 1347530 Plan Básico +';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_FORMATO_SIMPLIFICADO_1347530_BASICO%2B_2024_V2.pdf?alt=media&token=2f9ce392-6eb6-4393-95ca-9b9702811f65';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default FormatoSimplificado1347530PlanBasicoPlus_pdf