import React from 'react';
import Layout from '../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'

import { getUserData, signOutItmex } from '../auth/auth.utils'
import { auth, createUserProfileDocument } from '../firebase/firebase.utils'
import UserSideMenu from './components/UserSideMenu';


class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUserData(),
            currentUser: {},
            loading: true,
        }
    }
    unsubscribeFromAuth = null;
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Mis Cursos';
        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);

                userRef.onSnapshot(snapShot => {

                    this.setState({
                        currentUser: {
                            id: snapShot.id,
                            ...snapShot.data()
                        },
                        loading: false
                    });
                })
            }
        })
    }
    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }
    salir = e => {
        e.preventDefault();
        signOutItmex(() => {
            this.props.history.push('/login');
        });
    }
    render() {
        return (
            <div className=" ">

                <Layout title="Red Itmex - Planes">

                    <main id="content" role="main" className="bg-light">
                        <div className="bg-navy" style={{ backgroundImage: 'url(/assets/svg/components/abstract-shapes-20.svg)' }}>
                            <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="d-none d-lg-block">
                                            <h1 className="h2 text-white">¡Bienvenido!</h1>
                                        </div>

                                        <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                                            <li className="breadcrumb-item">Cuenta</li>
                                            <li className="breadcrumb-item active" aria-current="page">Mi Perfil</li>
                                        </ol>
                                    </div>

                                    <div className="col-auto">
                                        <div className="d-none d-lg-block">
                                            <button onClick={this.salir} className="btn btn-sm btn-soft-light" href="#">Cerrar Sesión</button>
                                        </div>

                                        <button type="button" className="navbar-toggler btn btn-icon btn-sm rounde-circle d-lg-none"
                                            aria-label="Toggle navigation"
                                            aria-expanded="false"
                                            aria-controls="sidebarNav"
                                            data-toggle="collapse"
                                            data-target="#sidebarNav">
                                            <span className="navbar-toggler-default">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
                                                </svg>
                                            </span>
                                            <span className="navbar-toggler-toggled">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container space-1 space-top-lg-0 mt-lg-n10">
                            <div className="row">
                                <UserSideMenu />
                                <div className="col-lg-9">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-header-title">Mi información</h5>
                                        </div>

                                        <div className="loaderMin" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                                            <div className="Box"></div>
                                        </div>
                                        <div className="card-body" style={{ display: this.state.loading ? 'none' : 'block' }}>

                                            <form>

                                                <div className="row form-group">
                                                    <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label input-label">Nombre Completo</label>

                                                    <div className="col-sm-9">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" name="firstName" id="firstNameLabel" placeholder="Nombre Completo" aria-label="Nombre Completo" value={this.state.currentUser.displayName} disabled />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row form-group">
                                                    <label htmlFor="emailLabel" className="col-sm-3 col-form-label input-label">Email</label>

                                                    <div className="col-sm-9">
                                                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="correo@example.com" aria-label="correo@example.com" value={this.state.currentUser.email} disabled />
                                                    </div>
                                                </div>

                                                <div className="row form-group">
                                                    <label htmlFor="phoneLabel" className="col-sm-3 col-form-label input-label">Teléfono <span className="input-label-secondary">(Opcional)</span></label>

                                                    <div className="col-sm-9">
                                                        <div className="input-group align-items-center">
                                                            <input type="text" className="form-control" name="phone" id="phoneLabel" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" value="+x(xxx)xxx-xx-xx" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <button className="btn btn-white" >Cancelar</button>
                                            <span className="mx-2"></span>
                                            <button className="btn btn-primary" >Guardar Cambios</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
            </div>
        )
    }
}

export default UserProfile;