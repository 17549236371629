import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class FormatoSimplificado1347568PlanGamerPlusS_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Formato Simplificado 1347568 Plan Gamer + SIMÉTRICO';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_FORMATO_SIMPLIFICADO_1347568_GAMER%2BS_2024_V1.pdf?alt=media&token=cf8a1790-eaa8-4844-8862-640dd382652c';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default FormatoSimplificado1347568PlanGamerPlusS_pdf