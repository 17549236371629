import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'

import QRCode from 'qrcode.react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faGavel, faServer } from '@fortawesome/free-solid-svg-icons'
import {  faCircle } from '@fortawesome/free-regular-svg-icons'


class DocumentacionLegal extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentación - Legal';
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Documentación - Legal">

                    <main id="content" role="main">
                        <div class=" faqBg">
                            <div class="container space-top-1 space-top-lg-2 textWhite space-bottom-2 position-relative z-index-2">
                                <div class="w-md-80 w-lg-60 text-center mx-md-auto">
                                    <h1 className="textWhite">Documentación</h1>
                                    <h3 className="textWhite">Legal</h3>
                                </div>
                            </div>
                        </div>
                        <div class="container space-2 space-bottom-lg-3">
                            <div class="row justify-content-lg-center">
                                <div class="col-lg-10">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/">Red Itmex</Link></li>
                                        <li class="breadcrumb-item"><Link to="/documentacion">Documentación</Link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Legal</li>
                                    </ol>
                                </nav>
                                    <div id="basics">
                                        <table class="table table-bordered w-100 mb-4">
                                            <tbody>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/aviso-atencion-a-usuarios/pdf/v3">Aviso Atención a Usuarios</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/aviso-atencion-a-usuarios/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/aviso-mapas-de-cobertura/pdf/v3">Aviso Mapas de Cobertura</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/aviso-mapas-de-cobertura/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/titulo-de-concesion/pdf/v3">Título de Concesion</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/titulo-de-concesion/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/colaboracion-en-materia-de-seguridad-y-justicia/pdf/v3">Colaboración en Materia de Seguridad y Justicia</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/colaboracion-en-materia-de-seguridad-y-justicia/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/lineamientos-de-información-transparente/pdf/v3">Lineamientos de Información Transparente</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/lineamientos-de-información-transparente/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/codigo-de-politicas-de-gestion-de-trafico/pdf/v3">Código de Políticas de Gestión de Tráfico</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/codigo-de-politicas-de-gestion-de-trafico/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/codigo-de-prácticas-comerciales/pdf/v3">Código de Prácticas Comerciales</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/codigo-de-prácticas-comerciales/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/aviso-de-privacidad/pdf/v3">Aviso de Privacidad</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/aviso-de-privacidad/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/carta-de-derechos-mínimos-de-los-usuarios/pdf/v3">Carta de Derechos Mínimos de los Usuarios</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/carta-de-derechos-mínimos-de-los-usuarios/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/contrato-de-adhesion/pdf/v3">Contrato de Adhesión</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/contrato-de-adhesion/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/caracteristicas-especificaciones-y-alcances-de-paquetes-de-internet-wireless/pdf/v3">Características, Especificaciones y Alcances de Paquetes de Internet Wireless</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/caracteristicas-especificaciones-y-alcances-de-paquetes-de-internet-wireless/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-536878-plan-basico/pdf/v3">Formato Simplificado 536878 Plan Básico</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-536878-plan-basico/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-536888-plan-premium/pdf/v3">Formato Simplificado 536888 Plan Premium</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-536888-plan-premium/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-536892-plan-gold/pdf/v3">Formato Simplificado 536892 Plan Gold</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-536892-plan-gold/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-536901-plan-gamer/pdf/v3">Formato Simplificado 536901 Plan Gamer</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-536901-plan-gamer/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/caracteristicas-especificaciones-y-alcances-de-paquetes-de-internet-fibra-optica/pdf/v3">Características, Especificaciones y Alcances de Paquetes de Internet Fibra Óptica</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/caracteristicas-especificaciones-y-alcances-de-paquetes-de-internet-fibra-optica/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347530-plan-basico-plus/pdf/v3">Formato Simplificado 1347530 Plan Básico +</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347530-plan-basico-plus/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347531-plan-premium-plus/pdf/v3">Formato Simplificado 1347531 Plan Premium +</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347531-plan-premium-plus/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347543-plan-gold-plus/pdf/v3">Formato Simplificado 1347543 Plan Gold +</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347543-plan-gold-plus/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347545-plan-gamer-plus/pdf/v3">Formato Simplificado 1347545 Plan Gamer +</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347545-plan-gamer-plus/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347564-plan-basico-plus-simetrico/pdf/v3">Formato Simplificado 1347564 Plan Básico + Simétrico</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347564-plan-basico-plus-simetrico/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347566-plan-premium-plus-simetrico/pdf/v3">Formato Simplificado 1347566 Plan Premium + Simétrico</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347566-plan-premium-plus-simetrico/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347567-plan-gold-plus-simetrico/pdf/v3">Formato Simplificado 1347567 Plan Gold + Simétrico</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347567-plan-gold-plus-simetrico/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347568-plan-gamer-plus-simetrico/pdf/v3">Formato Simplificado 1347568 Plan Gamer + Simétrico</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347568-plan-gamer-plus-simetrico/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347559-plan-empresarial-plus/pdf/v3">Formato Simplificado 1347559 Plan Empresarial +</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/formato-simplificado-1347559-plan-empresarial-plus/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/procedimiento-de-atencion-de-dudas-aclaraciones-quejas-y-reclamaciones/pdf/v3">Procedimiento de Atención de Dudas, Aclaraciones, Quejas y Reclamaciones</a></td>
                                                    <td>v3</td>
                                                    <td>07/26/2024</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/legal/procedimiento-de-atencion-de-dudas-aclaraciones-quejas-y-reclamaciones/pdf/v3" renderAs="canvas" size="100" includeMargin={true}/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
            </div>
        )
    }
}

export default DocumentacionLegal;