import React from 'react';
import ClaseItemModuloCursoCatalogo from './ClaseItemModuloCursoCatalogo';


import { formatedTime } from '../../utils/timeUtils'
const ModuloItemCursoCatalogo = (props) => (
    <div>

        <div className="card border mb-1">
            <div className="card-header card-collapse" id={"M" + props.modulo.indiceM}>
                <a className="btn btn-link btn-sm btn-block card-btn collapsed p-3" href="" role="button" data-toggle="collapse" data-target={"#C" + props.modulo.indiceM}
                    aria-expanded="false" aria-controls={"C" + props.modulo.indiceM}>
                    <span className="row">
                        <span className="col-8">
                            <span className="media">
                                <span className="card-btn-toggle mr-3 ml-0">
                                    <span className="card-btn-toggle-default"><i className="fas fa-plus-circle"></i></span>
                                    <span className="card-btn-toggle-active"><i className="fas fa-minus-circle"></i></span>
                                </span>
                                <span className="media-body">
                                    <span className="text-body font-weight-bold mr-5">{props.modulo.indiceM} - {props.modulo.nombreModulo}</span>
                                </span>
                            </span>
                        </span>
                        <span className="col-4 text-right">
                            <span className="row">
                                <span className="col-lg-6">
                                    <span className="text-muted">{props.modulo.clases.length} clases</span>
                                </span>
                                <span className="col-lg-6">
                                    <span className="text-muted">{formatedTime(props.modulo.duracionModulo)}</span>
                                </span>
                            </span>
                        </span>
                    </span>
                </a>
            </div>
            <div id={"C" + props.modulo.indiceM} className="collapse" aria-labelledby={"M" + props.modulo.indiceM}>
                <div className="card-body p-0">

                        {
                            !props.modulo.clases ?
                                <div>No hay clases disponibles aún.</div>
                                :
                                <div>
                                    {props.modulo.clases.map((clase) => (
                                        <ClaseItemModuloCursoCatalogo key={clase.id} agregarClase={props.agregarClase} curso={props.curso}  user={props.user} misclases={props.misclases}  inscrito={props.inscrito} clase={clase} />
                                    ))}
                                </div>
                        }

                </div>
            </div>
        </div>

    </div>
)
export default ModuloItemCursoCatalogo