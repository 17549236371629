


import { auth } from '../firebase/firebase.utils'


export const signOutItmex = (next) => {
    if(typeof window !== 'undefined'){
        localStorage.removeItem('gtok');
        localStorage.removeItem('userItmex');
        next();
    }
    auth.signOut()
}
export const authenticate = (async (dataUser, next) =>{
    if(typeof window !== 'undefined'){
        await localStorage.setItem('userItmex', JSON.stringify(dataUser));
        next()
    }
});

export const isAuthenticated = () => {
    if(typeof window == 'undefined'){
        return false;
    }
    if( localStorage.getItem('userItmex')){
        return true
    }else{
        return false
    }
}

export const getUserData = () => {
    if(typeof window == 'undefined'){
        return false;
    }
    if(localStorage.getItem('userItmex')){
        return JSON.parse(localStorage.getItem('userItmex'))
    }else{
        return false
    }
}
