import React, { Fragment } from 'react';
import Layout from '../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { auth, getCursoRef, getCursosRef, getMisClasesRef, getClasesRef, getModulosRef, getMisCursosRef, createUserProfileDocument } from '../firebase/firebase.utils'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';
import ModuloItemCursoCatalogo from './components/ModuloItemCursoCatalogo';
import { timeUtils, formatedTime } from '../utils/timeUtils'
//import StripeCheckoutButton from './components/StripeCheckoutButton'
const stripePromise = loadStripe('pk_live_51HB8ugESMHoNPrX2D1GVMtGDuYRmyZGBpxgVLs1OoMekr58hlkZKU7hSEQ16IK0kquAUqzSexYKkr8yewVghsK1000bWPPN9IN')

class CursoDetalle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curso: {
                keyLeaningItems: [],
                modulos: [{ clases: [] }]
            },
            numModulos: 0,
            duracionCurso: new timeUtils('00:00:00').hours,
            numVideos: 0,
            numArticulos: 0,
            numDescargables: 0,
            loading: true,
            misCursos: [],
            misClases: [],
            user: '',
            userStripe: '',
        }
    }
    createCheckoutSession = async () => {
        var response = await axios(
            {
                url: '/idStripe',
                method: 'post',
                data: {
                    items: [{
                        price_data: {
                            currency: 'mxn',
                            product_data: {
                                name: this.state.curso.title,
                                images: [this.state.curso.cursoImagen]
                            },
                            unit_amount: this.state.curso.precio * 100,
                        },
                        quantity: 1,
                    }],
                    idCurso: this.state.curso.id,
                    idStripe: this.state.userStripe,
                }
            }
        ).then((responseJson) => {
            var sessionID = responseJson.data.session_id;
            return sessionID;
        })
        return response
    }
    comprarCurso = async (event) => {
        this.setState({ loading: true })
        const sessionId = await this.createCheckoutSession();
        //console.log(sessionId)
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId }).then((result) => {
            console.log(result)
        });
    };
    agregarClase = (idclase, status) => {
        var misclases = this.state.misClases;
        if (!status) {
            misclases.push(idclase);
            this.setState({ misClases: misclases })
        } else {
            var misclases = misclases.filter(e => {
                if (e != idclase) {
                    return e
                }
            })
            this.setState({ misClases: misclases })
        }
    }
    obtenerCurso = async (id, idUser) => {
        if (idUser) {
            await getMisCursosRef(idUser).then((arrayCursos) => {
                if (arrayCursos.length > 0) {
                    this.setState({ misCursos: arrayCursos })
                }
            })
            await getMisClasesRef(idUser, id).then((arrayClases) => {
                this.setState({ misClases: arrayClases })
            })
        }
        var cursoData = {}
        const cursosRef = await getCursoRef(id)
        await cursosRef.get().then(async curso => {
            if (curso.exists) {
                const modulosList = []
                const modulosRef = getModulosRef(curso.id)
                var duracionClases = new timeUtils("00:00:00").hours
                var numArticulosD = 0
                var numVideosD = 0
                var numDescargablesD = 0
                await modulosRef.get().then(q2Snapshot => {
                    q2Snapshot.forEach(async modulo => {
                        var duracionModulo = 0;
                        const clasesList = []
                        const clasesRef = getClasesRef(curso.id, modulo.id)
                        await clasesRef.get().then(q2Snapshot => {
                            q2Snapshot.forEach(clase => {
                                var claseRawData = clase.data();
                                claseRawData.id = clase.id;
                                claseRawData.modulo = modulo.id;
                                claseRawData.curso = curso.id;
                                clasesList.push(claseRawData);
                                duracionClases = duracionClases + (new timeUtils(clase.data().duracion).hours)
                                duracionModulo = duracionModulo + (new timeUtils(clase.data().duracion).hours)
                                if (clase.data().tipo == 'video') {
                                    numVideosD++
                                } else if (clase.data().tipo == 'articulo') {
                                    numArticulosD++
                                } else if (clase.data().tipo == 'descargable') {
                                    numDescargablesD++
                                }
                            })
                        })
                        const moduloRaw = modulo.data()
                        moduloRaw.clases = clasesList;
                        moduloRaw.duracionModulo = duracionModulo;
                        modulosList.push(moduloRaw);
                        { this.setState({ numModulos: modulosList.length, numVideos: numVideosD, numArticulos: numArticulosD, numDescargables: numDescargablesD, loading: false, duracionCurso: duracionClases }) }
                    })
                    const dataRaw = curso.data()
                    dataRaw.descripcionShort = dataRaw.descripcionShort.replace(/{salto}/g, '\n\n')
                    dataRaw.descripcionLarge = dataRaw.descripcionLarge.replace(/{salto}/g, '\n\n')
                    dataRaw.id = curso.id
                    if (this.state.misCursos.find(e => e == curso.id)) {
                        dataRaw.inscrito = true
                    } else {
                        dataRaw.inscrito = false
                    }
                    dataRaw.modulos = modulosList
                    cursoData = dataRaw
                    document.title = 'Red Itmex - ' + dataRaw.title
                    // doc.data() is never undefined for query doc snapshots
                    this.setState({ curso: cursoData })
                });
            } else {
                this.props.history.push(`/academia/cursos`);
            }
        })
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        let event2 = new Event('js2');
        window.dispatchEvent(event2);
        document.title = 'Red Itmex - Curso';


        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                userRef.onSnapshot(snapShot => {
                    this.setState({ user: snapShot.id, userStripe: snapShot.data().idStripe })
                })
                this.obtenerCurso(this.props.match.params.id, userRef.id)
            } else {
                this.obtenerCurso(this.props.match.params.id, false)
            }
        })
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Nombre del Curso">
                    <div className="loader" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                        <div className="Box"></div>
                    </div>
                    <main id="content" role="main" style={{ display: this.state.loading ? 'none' : 'block' }}>
                        <div className="position-relative">
                            <div className="gradient-y-overlay-lg-white bg-img-hero space-2" style={{ backgroundColor: this.state.curso.color }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7 col-lg-8">
                                            {/* <small className="btn btn-xs btn-success btn-pill text-uppercase mb-2">Bestseller</small> */}
                                            <h1 className="text-lh-sm">{this.state.curso.title}</h1>
                                            <p>{this.state.curso.snapshotDes}</p>

                                            <div className="d-flex align-items-center flex-wrap">
                                                <div className="d-flex align-items-center mr-4">
                                                    <div className="avatar-group">
                                                        <span className="avatar avatar-xs avatar-circle">
                                                            <img className="avatar-img" src="/assets/img/160x160/img22.png" alt="Autor" />
                                                        </span>
                                                    </div>
                                                    <span className="pl-2">Creado por <span className="link-underline">{this.state.curso.instructor}</span></span>
                                                </div>
                                                {/* 
                                                <div className="d-flex align-items-center flex-wrap">
                                                    <ul className="list-inline mt-n1 mb-0 mr-2">
                                                        <li className="list-inline-item mx-0"><img src="../../assets/svg/illustrations/star.svg" alt="Review rating" width="16" /></li>
                                                        <li className="list-inline-item mx-0"><img src="../../assets/svg/illustrations/star.svg" alt="Review rating" width="16" /></li>
                                                        <li className="list-inline-item mx-0"><img src="../../assets/svg/illustrations/star.svg" alt="Review rating" width="16" /></li>
                                                        <li className="list-inline-item mx-0"><img src="../../assets/svg/illustrations/star.svg" alt="Review rating" width="16" /></li>
                                                        <li className="list-inline-item mx-0"><img src="../../assets/svg/illustrations/star-half.svg" alt="Review rating" width="16" /></li>
                                                    </ul>

                                                    <span className="d-inline-block">
                                                        <span className="text-dark font-weight-bold mr-1">4.87</span>
                                                        <span className="text-muted">(1.5k+ reviews)</span>
                                                    </span>
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container space-top-md-2 position-md-absolute top-0 right-0 left-0">
                                <div className="row justify-content-end">
                                    <div id="stickyBlockStartPoint" className="col-md-5 col-lg-4 position-relative z-index-2">
                                        <div className="js-sticky-block card border"
                                            data-hs-sticky-block-options='{
                                                "parentSelector": "#stickyBlockStartPoint",
                                                "breakpoint": "md",
                                                "startPoint": "#stickyBlockStartPoint",
                                                "endPoint": "#stickyBlockEndPoint",
                                                "stickyOffsetTop": 12,
                                                "stickyOffsetBottom": 12
                                                }'>
                                            <div className="position-relative p-1">
                                                <a className="js-fancybox video-player" href=""
                                                    data-caption={this.state.curso.title}
                                                    data-hs-fancybox-options='{
                                                    "src": "/assets/img/itmexportada_trim3.mp4",
                                                    "buttons": ["fullScreen", "close"]}'
                                                >
                                                    <img className="card-img-top" src={this.state.curso.cursoImagen} alt={this.state.curso.title} />
                                                    <span className="video-player-btn video-player-centered text-center">
                                                        <span className="video-player-icon mb-2">
                                                            <i className="fa fa-play"></i>
                                                        </span>
                                                        <span className="d-block text-center text-white">
                                                            Ver Prueba
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>

                                            <div className="card-body">
                                                {this.state.curso.inscrito ?
                                                    <Fragment>
                                                        <div className="mb-2">
                                                            <button className="btn btn-block btn-info transition-3d-hover"><i className="far fa-check-circle"></i> Inscrito</button>
                                                        </div>
                                                        <div className="progress mb-2">
                                                            <div className="progress-bar" role="progressbar" style={{ width: Math.round((this.state.misClases.length / this.state.curso.numClases) * 100) + "%" }} aria-valuenow="2" aria-valuemin="0" aria-valuemax={this.state.curso.numClases}>{Math.round((this.state.misClases.length / this.state.curso.numClases) * 100)}%</div>
                                                        </div>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <div className="mb-3">
                                                            <span className="h2 text-lh-sm mr-1 mb-0">${this.state.curso.precio} MXN</span>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Link className="btn btn-block btn-primary transition-3d-hover" to="/contacto">Contacta a un Asesor</Link>
                                                        </div>
                                                        <div className="container">
                                                            <hr className="hr-text" data-content="Ó" />
                                                        </div>
                                                        {this.state.user ?
                                                            <div className="mb-2">
                                                                <button onClick={this.comprarCurso} className="btn btn-block btn-primary transition-3d-hover ">Comprar ahora</button>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="mb-2">
                                                                    <Link to="/login" className="btn btn-block btn-primary transition-3d-hover">Comprar ahora</Link>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Fragment>
                                                }

                                                <h2 className="h4">Este curso incluye</h2>

                                                <div className="media text-body font-size-1 mb-2">
                                                    <div className="min-w-3rem text-center mr-3">
                                                        <i className="fa fa-video"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        {this.state.duracionCurso.toFixed(2)} horas de curso
                                                    </div>
                                                </div>

                                                <div className="media text-body font-size-1 mb-2">
                                                    <div className="min-w-3rem text-center mr-3">
                                                        <i className="fa fa-file"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        {this.state.numArticulos} artículos
                                                    </div>
                                                </div>

                                                <div className="media text-body font-size-1 mb-2">
                                                    <div className="min-w-3rem text-center mr-3">
                                                        <i className="fa fa-file-download"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        {this.state.numDescargables} recursos descargables
                                                    </div>
                                                </div>

                                                <div className="media text-body font-size-1 mb-2">
                                                    <div className="min-w-3rem text-center mr-3">
                                                        <i className="fa fa-infinity"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        Acceso de tiempo completo
                                                    </div>
                                                </div>

                                                <div className="media text-body font-size-1 mb-2">
                                                    <div className="min-w-3rem text-center mr-3">
                                                        <i className="fa fa-certificate"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        Certificado
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container space-top-2 space-top-md-1">
                            <div className="row">
                                <div className="col-md-7 col-lg-8">
                                    <div className="pt-0 mt-0">
                                        <h3 className="mb-4">Lo que aprenderás</h3>

                                        <div className="row">

                                            {this.state.curso.keyLeaningItems == [] ?
                                                <div>
                                                </div>
                                                :
                                                <Fragment>
                                                    {this.state.curso.keyLeaningItems.map((item, idx) => (
                                                        <div className="col-lg-6" key={idx}>
                                                            <div className="media text-body font-size-1 mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    {item}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Fragment>
                                            }
                                        </div>
                                    </div>

                                    <div className="border-top pt-7 mt-7">
                                        <div className="row mb-4">
                                            <div className="col-8">
                                                <h3 className="mb-0">Contenido del Curso</h3>
                                            </div>
                                            <div className="col-4 text-right">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <span className="font-size-1">{this.state.numModulos} módulos</span>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <span className="font-size-1">{formatedTime(this.state.duracionCurso)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.state.modulos == [] ?
                                                <div>No hay módulos disponibles aún.</div>
                                                :
                                                <div>
                                                    {this.state.curso.modulos.filter((modulo) => {
                                                        if (modulo.indiceM > 2) {
                                                            return false
                                                        }
                                                        return true
                                                    }).map((modulo, idx) => (
                                                        <ModuloItemCursoCatalogo key={idx} agregarClase={this.agregarClase} curso={this.state.curso.id} user={this.state.user} misclases={this.state.misClases} inscrito={this.state.curso.inscrito} modulo={modulo} />
                                                    ))}
                                                </div>
                                        }
                                        {this.state.numModulos >= 3 ?
                                            <Fragment>
                                                <div className="collapse" id="collapseCoursesContentSection">

                                                    {
                                                        <div>
                                                            {this.state.curso.modulos.filter((modulo) => {
                                                                if (modulo.indiceM > 2) {
                                                                    return true
                                                                }
                                                                return false
                                                            }).map((modulo, idx) => (
                                                                <ModuloItemCursoCatalogo key={idx} agregarClase={this.agregarClase} curso={this.state.curso.id} user={this.state.user} misclases={this.state.misClases} inscrito={this.state.curso.inscrito} modulo={modulo} />
                                                            ))}
                                                        </div>
                                                    }

                                                </div>

                                                <div className="card border">
                                                    <a className="link link-collapse btn btn-link btn-sm btn-block card-btn text-center p-3" data-toggle="collapse" href="#collapseCoursesContentSection" role="button" aria-expanded="false" aria-controls="collapseCoursesContentSection">
                                                        <span className="link-collapse-default">{this.state.numModulos - 2} módulo más</span>
                                                        <span className="link-collapse-active">Ver menos</span>
                                                    </a>
                                                </div>
                                            </Fragment>
                                            :
                                            <div>
                                            </div>
                                        }
                                    </div>

                                    <div className="border-top pt-7 mt-7">
                                        <h3 className="mb-4">Descripción</h3>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {this.state.curso.descripcionShort}
                                        </div>
                                        <div className="collapse" style={{ whiteSpace: 'pre-wrap' }} id="collapseDescriptionSection">
                                            {this.state.curso.descripcionLarge}
                                        </div>

                                        <a className="link link-collapse small font-size-1 font-weight-bold pt-1" data-toggle="collapse" href="#collapseDescriptionSection" role="button" aria-expanded="false" aria-controls="collapseDescriptionSection">
                                            <span className="link-collapse-default">Leer más</span>
                                            <span className="link-collapse-active">Leer menos</span>
                                            <span className="link-icon ml-1">+</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="stickyBlockEndPoint"></div>

                        <div className="space-top-1"></div>

                    </main>
                </Layout>
            </div>
        )
    }
}
export default CursoDetalle;