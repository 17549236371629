import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from './auth/auth.utils';
import ReactGA from 'react-ga';
import { Component } from 'react';
export const RutaAutenticada = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                ReactGA.pageview(props.location.pathname);
                return isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location }
                            }}
                        />
                    )
            }
            }
        />
    )
};

export const RutaNoAutenticada = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {

                ReactGA.pageview(props.location.pathname);
                return !isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/perfil/',
                                state: { from: props.location }
                            }}
                        />
                    )
            }
            }
        />
    )
};
export const RutaAnalytics = ({component: Component, ...rest}) =>{
    return (
        <Route  
            {...rest}
            render={
                props =>{
                    ReactGA.pageview(props.location.pathname);
                    return <Component {...props} />;
                }
            }
            />
    )
}
