import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class ProcedimientoDeAtencionDeDudasAclaracionesQuejasYReclamaciones_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Procedimiento de Atención de Dudas, Aclaraciones, Quejas y Reclamaciones';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_PROCEDIMIENTO_DE_ATENCION_DE_DUDAS_ACLARACIONES_QUEJAS_Y_RECLAMACIONES_2024_V1.pdf?alt=media&token=5360fb79-9824-46b8-8ed0-f202aaf2da15';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default ProcedimientoDeAtencionDeDudasAclaracionesQuejasYReclamaciones_pdf