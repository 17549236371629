import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios'

const config = {
    apiKey: process.env.REACT_APP_CONFIGFIREBASE_API_KEY,
    authDomain: "reditmextrescero.firebaseapp.com",
    databaseURL: "https://reditmextrescero.firebaseio.com",
    projectId: "reditmextrescero",
    storageBucket: "reditmextrescero.appspot.com",
    messagingSenderId: "104316742204",
    appId: "1:104316742204:web:158209a70f4669e0d5f139",
    measurementId: "G-NZ3WBBB63T"
}
const configBluePing = {
    apiKey: process.env.REACT_APP_CONFIGBLUEPING_API_KEY,
    authDomain: "bluepingdashboard.firebaseapp.com",
    projectId: "bluepingdashboard",
    storageBucket: "bluepingdashboard.appspot.com",
    messagingSenderId: "635020680775",
    appId: "1:635020680775:web:d00db3a0ad4dd75c525b62",
    measurementId: "G-41H077C5DN"
}

firebase.initializeApp(config);
var secondaryApp = firebase.initializeApp(configBluePing, "AuthenticateSecondary")
export const firestoreBluePing = secondaryApp.firestore()

//COMENTARIOS
export const setComentarClase = async (comentario, userID, cursoID, moduloID, claseID) => {
    const createdAt = new Date()
    const newComment = firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios')
        .add({ createdAt, userID, comentario, likes: 0 })
    firestore.collection('users').doc(userID).update({
        puntuacion: firebase.firestore.FieldValue.increment(1)
    })
    return newComment;
}
export const setSubcomentarClase = async (subcomentario, userID, cursoID, moduloID, claseID, comentarioID) => {
    const createdAt = new Date()
    const newSubcomment = firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID).collection('subcomentarios')
        .add({ createdAt, userID, subcomentario, likes: 0 })
    firestore.collection('users').doc(userID).update({
        puntuacion: firebase.firestore.FieldValue.increment(1)
    })
    return newSubcomment;
}
export const obtenerTotalComentarios = async (claseID, moduloID, cursoID) => {
    const totalComents = await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios')
        .get().then((comentarios) => {
            return comentarios.size
        })
    return totalComents;
}
export const obtenerTotalSubcomentarios = async (comentarioID, claseID, moduloID, cursoID) => {
    const totalSubcoments = await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID).collection('subcomentarios')
        .get().then((subcomentarios) => {
            return subcomentarios.size
        })
    return totalSubcoments;
}
export const setMeGustaComentario = async (cursoID, moduloID, claseID, comentarioID, userComentarioID, userID) => {
    await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID)
        .update({
            likes: firebase.firestore.FieldValue.increment(1),
            likesUser: firebase.firestore.FieldValue.arrayUnion(userID)
        })
    firestore.collection('users').doc(userComentarioID).update({
        puntuacion: firebase.firestore.FieldValue.increment(1)
    })
}
export const setMeGustaSubcomentario = async (cursoID, moduloID, claseID, comentarioID, subcomentarioID, userComentarioID, userID) => {
    await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID).collection('subcomentarios').doc(subcomentarioID)
        .update({
            likes: firebase.firestore.FieldValue.increment(1),
            likesUser: firebase.firestore.FieldValue.arrayUnion(userID)
        })
    firestore.collection('users').doc(userComentarioID).update({
        puntuacion: firebase.firestore.FieldValue.increment(1)
    })
}
export const deleteComentario = async (cursoID, moduloID, claseID, comentarioID, userComentarioID, userID) => {
    await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID)
        .delete()
    firestore.collection('users').doc(userComentarioID).update({
        puntuacion: firebase.firestore.FieldValue.increment(-1)
    })
}
export const deleteSubcomentario = async (cursoID, moduloID, claseID, comentarioID, subcomentarioID, userComentarioID, userID) => {
    await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID).collection('subcomentarios').doc(subcomentarioID)
        .delete()
    firestore.collection('users').doc(userComentarioID).update({
        puntuacion: firebase.firestore.FieldValue.increment(-1)
    })
}
export const setNoMeGustaComentario = async (cursoID, moduloID, claseID, comentarioID, userComentarioID, userID) => {
    await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID)
        .update({
            likes: firebase.firestore.FieldValue.increment(-1),
            likesUser: firebase.firestore.FieldValue.arrayRemove(userID)
        })
    firestore.collection('users').doc(userComentarioID).update({
        puntuacion: firebase.firestore.FieldValue.increment(-1)
    })
}
export const setNoMeGustaSubcomentario = async (cursoID, moduloID, claseID, comentarioID, subcomentarioID, userComentarioID, userID) => {
    await firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID).collection('subcomentarios').doc(subcomentarioID)
        .update({
            likes: firebase.firestore.FieldValue.increment(-1),
            likesUser: firebase.firestore.FieldValue.arrayRemove(userID)
        })
    firestore.collection('users').doc(userComentarioID).update({
        puntuacion: firebase.firestore.FieldValue.increment(-1)
    })
}
export const getUserRef = (idUser) => {
    const userRef = firestore.collection('users').doc(idUser)
    return userRef;
}
export const getPaymentRef = async (descripcionPayment, amountTotal, idPayment, userId, cursoID, costoPayment) => {
    var response = ''
    await firestore.collection('payments').where("idPayment", "==", idPayment).get().then(async (payment) => {
        if (payment.empty) {
            await firestore.collection('payments').add({ descripcionPayment: descripcionPayment, total: amountTotal, idPayment: idPayment, user: userId, payedAt: new Date(), curso: cursoID })
                .then(async (payRef) => {
                    await firestore.collection('users').doc(userId).collection('miscursos').doc(cursoID).set({ signedAt: new Date() })
                        .then(async (ref) => {
                            await firestore.collection('users').doc(userId).update({
                                puntuacion: firebase.firestore.FieldValue.increment(costoPayment * 100)
                            })
                            response = true
                        })
                        .catch((err) => {
                            response = false
                        })
                }).catch((err) => {
                    response = false
                })
        } else {
            response = false
        }
    })
    return response
}
export const getCursoRef = (idCurso) => {
    const cursoRef = firestore.collection('cursos').doc(idCurso)
    return cursoRef;
}
export const getCodigoRef = async (idCodigo) => {
    var codeRef = firestore.collection('codes').doc(idCodigo)
    await firestore.collection('codes').doc(idCodigo).get().then((code) => {
        if (code.exists) {
        } else {
            codeRef = null
        }
    })
    return codeRef
}
export const redimirCodigo = async (idCodigo, idUser) => {
    var result = false
    await firestore.collection('codes').doc(idCodigo).get().then(async (code) => {
        if (code.exists) {
            if (code.data().tipo == "curso") {
                if (code.data().infinito) {
                    var arrayUsers = []
                    if (code.data().users) {
                        arrayUsers = code.data().users;
                    }
                    if (!arrayUsers.find(e => e == idUser)) {
                        await firestore.collection('codes').doc(idCodigo).set({
                            ...code.data(),
                            users: arrayUsers.concat([idUser])
                        }).then(async () => {
                            await firestore.collection('users').doc(idUser).collection('miscursos').doc(code.data().curso).set({ signedAt: new Date() })
                                .then(() => {
                                    result = { error: false, tipo: "curso", idCurso: code.data().curso }
                                    return result
                                }).catch((e) => {
                                    result = { error: true, errorMessage: "Hubo un error en nuestra Base de Datos. Contacta a un administrador de Red Itmex para arreglarlo. " + e }
                                })
                        }).catch((e) => {
                            result = { error: true, errorMessage: "Hubo un error en nuestra Base de Datos. Contacta a un administrador de Red Itmex para arreglarlo." + e }
                        })
                    } else {
                        result = { error: true, errorMessage: "Este código ya lo has usado. Intenta de nuevo." }
                    }

                } else {
                    if (code.data().usosTotales < code.data().usosPermitidos) {
                        var arrayUsers = []
                        if (code.data().users) {
                            arrayUsers = code.data().users;
                        }
                        if (!arrayUsers.find(e => e == idUser)) {
                            await firestore.collection('codes').doc(idCodigo).set({
                                ...code.data(),
                                users: arrayUsers.concat([idUser]),
                                usosTotales: code.data().usosTotales + 1,
                                usado: code.data().usosTotales + 1 == code.data().usosPermitidos ? true : false,
                            }).then(async () => {
                                await firestore.collection('users').doc(idUser).collection('miscursos').doc(code.data().curso).set({})
                                    .then(() => {
                                        result = { error: false, tipo: "curso", idCurso: code.data().curso }
                                        return result
                                    }).catch((e) => {
                                        result = { error: true, errorMessage: "Hubo un error en nuestra Base de Datos. Contacta a un administrador de Red Itmex para arreglarlo. " + e }
                                    })
                            }).catch((e) => {
                                result = { error: true, errorMessage: "Hubo un error en nuestra Base de Datos. Contacta a un administrador de Red Itmex para arreglarlo." + e }
                            })
                        } else {
                            result = { error: true, errorMessage: "Este código ya lo has usado. Intenta de nuevo." }
                        }
                    } else {
                        result = { error: true, errorMessage: "Este código ya fue usado. Intenta de nuevo." }
                    }
                }
            }
        } else {
            result = { error: true, errorMessage: "Este código no existe. Intenta de nuevo." }
        }
    })
    return result
}
export const getCoberturasRef = () => {
    const coberturasRef = firestore.collection('comunidades')
    return coberturasRef;
}
export const getCursosRef = () => {
    const modulosRef = firestore.collection('cursos').orderBy("popularidad", "desc")
    return modulosRef;
}
export const getComentariosRef = async (cursoID, moduloID, claseID, numComentarios) => {
    const comentariosRef = firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios')
        .orderBy("createdAt", "desc").limit(numComentarios)
    return comentariosRef;
}
export const getSubcomentariosRef = async (cursoID, moduloID, claseID, comentarioID, numComentarios) => {
    const subcomentariosRef = firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios').doc(comentarioID).collection('subcomentarios')
        .orderBy("createdAt", "desc").limit(numComentarios)
    return subcomentariosRef;
}
export const getComentariosTOPRef = async (cursoID, moduloID, claseID, numComentarios) => {
    const comentariosTOPRef = firestore.collection('cursos').doc(cursoID).collection('modulos').doc(moduloID).collection('clases').doc(claseID).collection('comentarios')
        .orderBy("likes", "desc").orderBy("createdAt", "desc").limit(numComentarios)
    return comentariosTOPRef;
}
export const getMunicipiosZonasRef = (idEstado) => {
    const municipiosZonasRef = firestore.collection('comunidades').doc(idEstado).collection('municipiozona')
    if (municipiosZonasRef) {
        return municipiosZonasRef;
    } else {
        return false
    }
}
export const getComunidadesRef = (idEstado, idMunicipio) => {
    const comunidadRef = firestore.collection('comunidades').doc(idEstado).collection('municipiozona').doc(idMunicipio).collection('zonas').orderBy("nombre", "asc")
    if (comunidadRef) {
        return comunidadRef;
    } else {
        return false
    }
}
export const getComunidadesDisponiblesRef = (idEstado, idMunicipio) => {
    const comunidadRef = firestore.collection('comunidades').doc(idEstado).collection('municipiozona').doc(idMunicipio).collection('zonas').where("disponible", "!=", false).orderBy("disponible", "asc").orderBy("nombre", "asc")
    if (comunidadRef) {
        return comunidadRef;
    } else {
        return false
    }
}
export const getModulosRef = (idCurso) => {
    if (idCurso) {
        const modulosRef = firestore.collection('cursos').doc(idCurso).collection('modulos').orderBy("indiceM", "asc")
        if (modulosRef) {
            return modulosRef;
        } else {
            return false
        }
    }
}
export const getClasesRef = (idCurso, idModulo) => {
    const clasesRef = firestore.collection('cursos').doc(idCurso).collection('modulos').doc(idModulo).collection("clases").orderBy("indice", "asc")
    return clasesRef;
}
export const getClaseRef = async (idCurso, idModulo, idClase) => {
    const claseRef = await firestore.collection('cursos').doc(idCurso).collection('modulos').doc(idModulo).collection("clases").doc(idClase).get()
    if (claseRef.exists) {
        var claseRawData = claseRef.data();
        claseRawData.id = claseRef.id;
        return claseRawData;
    } else {
        return false;
    }
}
export const getModuloRef = async (idCurso, idModulo) => {
    const moduloRef = await firestore.collection('cursos').doc(idCurso).collection('modulos').doc(idModulo).get()
    if (moduloRef.exists) {
        var moduloData = moduloRef.data()
        moduloData.id = moduloRef.id
        return moduloData;
    } else {
        return false;
    }
}
export const getPreviousClaseRef = async (idCurso, idModulo, idClase, indiceClase) => {
    var previousClassData = {}
    var previousClaseRef = await firestore.collection('cursos')
        .doc(idCurso).collection('modulos')
        .doc(idModulo)
        .collection("clases")
        .where("indice", "==", indiceClase - 1)
        .get()
        .then(
            querySnapshot => {
                querySnapshot.forEach(doc => {
                    // doc.data() is never undefined for query doc snapshots
                    if (doc.exists) {
                        previousClassData = doc.data()
                        previousClassData.id = doc.id
                        previousClassData.curso = idCurso
                        previousClassData.modulo = idModulo
                    }
                });
            }
        ).catch(e => {
            previousClassData = false;
        })
    if (!previousClassData.indice) {
        var moduloActual = await getModuloRef(idCurso, idModulo);
        if (moduloActual.id) {
            var refPreviousModulo = await getModuloIndiceRef(idCurso, moduloActual.indiceM - 1)
            if (refPreviousModulo) {
                var previousClaseRef = await firestore.collection('cursos')
                    .doc(idCurso).collection('modulos')
                    .doc(refPreviousModulo.id)
                    .collection("clases")
                    .where("indice", "==", indiceClase - 1)
                    .get()
                    .then(
                        querySnapshot => {
                            querySnapshot.forEach(doc => {
                                // doc.data() is never undefined for query doc snapshots
                                if (doc.exists) {
                                    previousClassData = doc.data()
                                    previousClassData.id = doc.id
                                    previousClassData.curso = idCurso
                                    previousClassData.modulo = refPreviousModulo.id
                                }
                            });
                        }
                    ).catch(e => {
                        previousClassData = false;
                    })
                if (!previousClassData.indice) {
                    previousClassData = false;
                    return previousClassData
                }
                else {
                    return previousClassData
                }
            } else {
                previousClassData = false;
            }
        } else {
            return previousClassData;
        }
    } else {
        return previousClassData;
    }
}
export const getNextClaseRef = async (idCurso, idModulo, idClase, indiceClase) => {
    var nextClassData = {}
    var nextClaseRef = await firestore.collection('cursos')
        .doc(idCurso).collection('modulos')
        .doc(idModulo)
        .collection("clases")
        .where("indice", "==", indiceClase + 1)
        .get()
        .then(
            querySnapshot => {
                querySnapshot.forEach(doc => {
                    // doc.data() is never undefined for query doc snapshots
                    if (doc.exists) {
                        nextClassData = doc.data()
                        nextClassData.id = doc.id
                        nextClassData.curso = idCurso
                        nextClassData.modulo = idModulo
                    }
                });
            }
        ).catch(e => {
            nextClassData = false;
        })
    if (!nextClassData.indice) {
        var moduloActual = await getModuloRef(idCurso, idModulo);
        if (moduloActual.id) {
            var refNextModulo = await getModuloIndiceRef(idCurso, moduloActual.indiceM + 1)
            if (refNextModulo) {
                var nextClaseRef = await firestore.collection('cursos')
                    .doc(idCurso).collection('modulos')
                    .doc(refNextModulo.id)
                    .collection("clases")
                    .where("indice", "==", indiceClase + 1)
                    .get()
                    .then(
                        querySnapshot => {
                            querySnapshot.forEach(doc => {
                                // doc.data() is never undefined for query doc snapshots
                                if (doc.exists) {
                                    nextClassData = doc.data()
                                    nextClassData.id = doc.id
                                    nextClassData.curso = idCurso
                                    nextClassData.modulo = refNextModulo.id
                                }
                            });
                        }
                    ).catch(e => {
                        nextClassData = false;
                    })
                if (!nextClassData.indice) {
                    nextClassData = false;
                    return nextClassData
                }
                else {
                    return nextClassData
                }
            } else {
                nextClassData = false;
            }
        } else {
            return nextClassData;
        }
    } else {
        return nextClassData;
    }
}
export const getModuloIndiceRef = async (idCurso, indiceModulo) => {
    var indiceModuloData = {}
    await firestore.collection('cursos')
        .doc(idCurso)
        .collection('modulos')
        .where("indiceM", "==", indiceModulo)
        .get()
        .then(
            querySnapshot => {
                querySnapshot.forEach(doc => {
                    // doc.data() is never undefined for query doc snapshots
                    if (doc.exists) {
                        indiceModuloData = doc.data()
                        indiceModuloData.id = doc.id
                    }
                });
            }
        ).catch(e => {
            indiceModuloData = false;
        })
    if (indiceModuloData.id) {
        return indiceModuloData
    } else {
        return false
    }
}
export const getMisCursosRef = async (idUser) => {
    var misCursosArray = []
    await firestore.collection('users').doc(idUser).collection('miscursos').get().then((miscursos) => {
        if (!miscursos.empty) {
            miscursos.docs.forEach((doc) => {
                if (doc.exists) {
                    misCursosArray.push(doc.id)
                }
            })
        } else {
            return false
        }
    })
    return misCursosArray
}
export const getAwardsRef = async () => {
    var awardsArray = []
    await firestore.collection('awards').orderBy("indice", "asc").get().then((awards) => {
        if (!awards.empty) {
            awards.docs.forEach((doc) => {
                if (doc.exists) {
                    var recompensaRawData = doc.data()
                    recompensaRawData.id = doc.id
                    awardsArray.push(recompensaRawData)
                }
            })
        } else {
            return false
        }
    })
    return awardsArray
}
export const getMisClasesRef = async (idUser, idCurso) => {
    var misClasesArray = []
    await firestore.collection('users').doc(idUser).collection('miscursos').doc(idCurso).collection('misclases').get().then((misclases) => {
        if (!misclases.empty) {
            misclases.docs.forEach((doc) => {
                if (doc.exists) {
                    misClasesArray.push(doc.id)
                }
            })
        } else {
            return false
        }
    })
    return misClasesArray
}
export const setReproduccion = async (idCurso, idModulo, idClase, repro) => {
    firestore.collection('cursos')
        .doc(idCurso).collection('modulos')
        .doc(idModulo).collection('clases')
        .doc(idClase).update({ reproducciones: repro + 1 })
        .then(function () {
            //console.log("¡Clase agregada exitosamente!");
        })
        .catch(function (error) {
            console.error("Error writing document: ", error);
        });
}
export const setMisClasesRef = async (idUser, idCurso, idClase, status) => {
    if (status) {
        await firestore.collection('users')
            .doc(idUser).collection('miscursos')
            .doc(idCurso).collection('misclases')
            .doc(idClase).set({})
            .then(function () {
                //console.log("¡Clase agregada exitosamente!");
                firestore.collection('users').doc(idUser).update({
                    puntuacion: firebase.firestore.FieldValue.increment(2),
                    clasesTaken: firebase.firestore.FieldValue.increment(1)
                })
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    } else {
        await firestore.collection('users')
            .doc(idUser).collection('miscursos')
            .doc(idCurso).collection('misclases')
            .doc(idClase).delete()
            .then(function () {
                //console.log("¡Clase borrada exitosamente!");
                firestore.collection('users').doc(idUser).update({
                    puntuacion: firebase.firestore.FieldValue.increment(-2),
                    clasesTaken: firebase.firestore.FieldValue.increment(-1)
                })
            })
            .catch(function (error) {
                console.error("Error deleting document: ", error);
            });
    }
}
export const getMiCurso = async (idCurso) => {
    var dataRaw = {}
    const micursoRef = await firestore.collection('cursos').doc(idCurso).get().then((curso) => {
        if (curso.exists) {
            dataRaw = curso.data()
            dataRaw.id = curso.id
        } else {
            return
        }
    })
    return dataRaw
}
export const createCustomerStripe = async (userId, displayName, email) => {
    const userRef = await firestore.collection('users').doc(userId)
    const snapShot = await userRef.get();
    if (!(snapShot.data().idStripe)) {
        var response = await axios(
            {
                url: '/stripeCustomer',
                method: 'post',
                data: {
                    description: userId,
                    displayName: displayName,
                    email: email
                }
            }
        ).then(async (responseJson) => {
            await firestore.collection('users').doc(userId).update({
                idStripe: responseJson.data.id
            })
        })
    }
}
export const createUserProfileDocument = async (userAuth,credentials,provider) => {
    if (!userAuth) return;
    const userRef = await firestore.doc(`users/${userAuth.uid}`)
    const snapShot = await userRef.get();
    
    if (!(snapShot.exists)) {
        var { displayName, email, photoURL } = userAuth;
        const createdAt = new Date()
        if(credentials){
            photoURL = photoURL+"?height=500&access_token="+credentials.accessToken
        }
        await userRef.set({
            displayName,
            email,
            photoURL,
            puntuacion: 0,
            provider: provider,
            status: 'activo',
            createdAt
        }).then(() => {
        }).catch((err) => {
        })
        return userRef;
    } else {
        return userRef;
    }
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();


const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

var providerFb = new firebase.auth.FacebookAuthProvider();
providerFb.addScope('email');
providerFb.setCustomParameters({ display: 'popup' });
export const signInWithFacebook = () => auth.signInWithPopup(providerFb);
export default firebase;


//BLUE PING

export const setVisitaBluePing = async (
    accountID, 
    location,
    path,
    navigatorValues
    ) => {
    console.log("Registrando visita. ¡Bienvenido!")
    const createdAt = new Date()
    const newVisit = firestoreBluePing.collection('clientsBluePing').doc(accountID).collection("legos").doc("blueAnalytics").collection("data")
        .add({ 
            ...location,
            ...path,
            ...navigatorValues,
            createdAt
        }).catch((error)=>{
            console.log(error)
        })
    return newVisit
}
export const sendBlueMail = async (
    contactName,
    contactLastName,
    contactEmail,
    contactPhone,
    contactSubject,
    contactMensaje
    ) => {
    var successError = { success: null, success_message: null, success_code: null, error: null, error_message: null, error_code: null, response: null}
    console.log("Enviando correo...")
    const leido = false;
    const createdAt = new Date()
    const newMail = await firestoreBluePing.collection('clientsBluePing').doc("pRNV5AvyjEsxHfjqkxzh").collection("legos").doc("blueMail").collection("data")
        .add({ 
            to: ["isalvadorp@hotmail.com","luisarturozerep@gmail.com"],
            message: {
                subject: "[REDITMEX.COM] Nuevo mensaje en tu App de Contacto",
                html: "¡Hola!, has recibido un nuevo mensaje en tu aplicación de contacto de www.reditmex.com. <br/><br/> Esta fue la información que recibimos:<br/><br/> <b>Asunto:</b> "+contactSubject+"<br/> <b>Nombre:</b> "+contactName+" "+contactLastName+"<br/> <b>Email:</b> "+contactEmail+"<br/> <b>Teléfono:</b> "+contactPhone+"<br/> <b>Mensaje:</b> <br/> "+contactMensaje+"<br/><br/> Te recomendamos contestarle lo más pronto posible. <br/><br/> Saludos,<br/> Equipo de CodeTec Institute",
                text: "¡Hola!, has recibido un nuevo mensaje en tu aplicación de contacto de www.reditmex.com. <br/><br/> Esta fue la información que recibimos:<br/><br/> <b>Asunto:</b> "+contactSubject+"<br/> <b>Nombre:</b> "+contactName+" "+contactLastName+"<br/> <b>Email:</b> "+contactEmail+"<br/> <b>Teléfono:</b> "+contactPhone+"<br/> <b>Mensaje:</b> <br/> "+contactMensaje+"<br/><br/> Te recomendamos contestarle lo más pronto posible. <br/><br/> Saludos,<br/> Equipo de CodeTec Institute"
            },
            contactMensaje,
            leido,
            createdAt
        }).then((mail)=>{
            console.log("correo enviado")
            successError.success= true;
            successError.success_message="Correo enviado";
            successError.success_code=200;
            successError.response = mail;
            return successError
        }).catch((error)=>{
            console.log(error)
            successError.error= true;
            successError.error_message=error.message;
            successError.error_code=error.code;
            successError.response = error;
            return successError
        })
    return successError
}