import React from 'react';
import { Link } from 'react-router-dom'

const cursoItemCatalogo = (props) => (
    <div className="col-sm-6 col-lg-4 mb-3 mb-sm-8" style={{ padding: '10px' }}>
        <Link className="d-block border-bottom mb-5 h-100" to={`/academia/curso/${props.curso.id}`}>
            <article className="card h-100">
                <div className="card-img-top position-relative">
                    <img className="card-img-top" src={props.curso.cursoImagen} alt={props.curso.title} />
                    <figure className="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                        </svg>
                    </figure>
                </div>

                <div className="card-body">
                    <h3>{props.curso.title}</h3>
                    <p style={{ color: '#000' }}>{props.curso.snapshotDes.substr(0, 140) + "..."}</p>
                </div>

                <div className="card-footer border-0 pt-0">
                    <div className="media align-items-center">
                        <div className="avatar-group">
                            <div className="avatar avatar-xs avatar-circle" href="#" data-toggle="tooltip" data-placement="top" title="" data-original-title={props.curso.instructor}>
                                {props.curso.nivel == "Principiante" ?
                                    <i className="fas fa-battery-quarter textBlack"></i>
                                    :
                                    props.curso.nivel == "Intermedio" ?
                                        <i className="fas fa-battery-half textBlack"></i>
                                        :
                                        props.curso.nivel == "Avanzado" ?
                                            <i className="fas fa-battery-three-quarters textBlack"></i>
                                            :
                                            props.curso.nivel == "Master" ?
                                                <i className="fas fa-battery-full textBlack"></i>
                                                :
                                                null
                                }
                            </div>

                        </div>
                        <div style={{ position: 'absolute', top: '0', right: '10px', color: '#fff', fontWeight: 'bold' }}>
                            {props.inscrito ? 'Inscrito' : '$' + props.curso.precio}
                        </div>
                        <div style={{ position: 'absolute', top: '0', left: '10px', color: '#fff', fontWeight: 'bold' }}>
                            <span className={"badge badge-pill badge-" + props.curso.categoria}>{props.curso.categoria}</span>
                        </div>
                        <div className="media-body d-flex justify-content-end text-muted font-size-1 ml-2">
                            Instructor: {props.curso.instructor}
                        </div>
                    </div>
                </div>
            </article>
        </Link>
    </div>
)

export default cursoItemCatalogo;