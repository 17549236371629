import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'
import axios from 'axios'
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';

class Blog extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Blog';
        this.registrarVisita()

    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div>

                <Layout title="Red Itmex - Blog">
                    <div class="container space-top-1 space-top-lg-1 space-bottom-1 space-bottom-md-1">
                        <div class="w-md-80 w-lg-75 text-center mx-md-auto">
                            <h1 class="display-4">Blog</h1>
                        </div>
                        <div class="space-2 text-center">
                            <h3>Todavía no publicamos contenido. ¡Espéralo pronto!</h3>
                        </div>
                    </div>
                </Layout>
            </div >
        )
    }
};
export default Blog;