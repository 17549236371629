import React from 'react';
import Layout from './Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import Ads from './Ads';
class Nosotros extends React.Component {
    constructor(props){
      super(props);
    }
    componentDidMount(){
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Nosotros';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render(){
        return(
            <div className=" ">
                <Layout title="Red Itmex - Inicio">
                    <div className="sectionAboutUs " >
                        <div id='stars'></div>
                        <div id='stars2'></div>
                        <div id='stars3'></div>
                        <div  className="container space-2 space-top-md-5 space-top-lg-4">
                            <div  className="w-lg-80 text-center mx-lg-auto">
                                <div  className="mb-11">
                                    <h1  className="display-4 font-weight-semi-bold textWhite">Sobre Nosotros</h1>
                                    <p  className="lead font-weight-normal textWhite">
                                        Estamos en la misión de proveer servicio de internet a las zonas más remotas, acercar y enseñar tecnologías de vanguardia a las personas que construyen el futuro de nuestras comunidades.  
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div  className="container space-top-1 space-bottom-2 space-bottom-lg-3">
                            <div  className="row justify-content-lg-center">
                                <div  className="col-sm-3 col-lg-3 mb-7 mb-sm-0 u-ver-divider u-ver-divider--none-lg">
                                {/* Stats -->*/}
                                <div  className="text-center">
                                    <span  className="d-block h2 textWhite font-weight-semi-bold text-uppercase mb-0"><span  className="counter">75</span>%</span>
                                    <span  className="text-secondary textWhite"> de nuestra red usa energía verde</span>
                                </div>
                                {/* End Stats -->*/}
                                </div>
                                <div  className="col-sm-3 col-lg-3 mb-7 mb-sm-0 u-ver-divider u-ver-divider--none-lg">
                                    {/* Stats -->*/}
                                    <div  className="text-center">
                                    <span  className="d-block h2 textWhite font-weight-semi-bold text-uppercase mb-0"><span  className="counter">150</span> Km</span>
                                    <span  className="text-secondary textWhite">de enlaces de red en territorio hidalguense</span>
                                    </div>
                                    {/* End Stats -->*/}
                                </div>
                        
                                <div  className="col-sm-3 col-lg-3 mb-7 mb-sm-0">
                                    {/* Stats -->*/}
                                    <div  className="text-center">
                                    <span  className="d-block h2 textWhite font-weight-semi-bold text-uppercase mb-0">+<span  className="counter">1,000</span></span>
                                    <span  className="text-secondary textWhite">familias conectadas a la red itmex</span>
                                    </div>
                                    {/* End Stats -->*/}
                                </div>
                                <div  className="col-sm-3 col-lg-3 mb-7 mb-sm-0">
                                    {/* Stats -->*/}
                                    <div  className="text-center">
                                    <span  className="d-block h2 textWhite font-weight-semi-bold text-uppercase mb-0">+<span  className="counter">5,700</span></span>
                                    <span  className="text-secondary textWhite">seguidores en redes sociales</span>
                                    </div>
                                    {/* End Stats -->*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  className="container space-2 space-lg-2" style={{overflow: 'hidden'}}>
                        <div  className="row justify-content-lg-between">
                        <div  className="col-lg-4 mb-5 mb-lg-0" data-aos="fade-right" data-aos-offset="250">
                            <h2  className="font-weight-semi-bold">Creemos que el internet debe ser para todos.</h2>
                        </div>
                        <div  className="col-lg-6"  data-aos="fade-left" data-aos-offset="250">
                            <p>
                                Somos una empresa <b>orgullosamente hidalguense</b> <i  className="fas fa-heart" style={{color: '#f00'}}></i> que provee servicios integrales de Internet, Telecomunicaciones y Tecnologías de Información. Contamos con <b>más de 150 kilómetros</b> de enlaces de red que llegan a localidades remotas de Hidalgo. 
                            </p>
                            <p>
                                Estamos conscientes de la importancia y el alto impacto que genera la comunicación y la tecnología en la sociedad. Por lo que en Itmex becamos algunas instituciones y alumnos con lo cual se benefician <b>más de 800 alumnos</b> que tienen la oportunidad de desarrollar nuevas habilidades y competencias para su futuro.
                            </p>
                            <p>
                                En Itmex estamos comprometidos con el ambiente y buscamos trabajar en un camino de energías limpias. El <b>75%</b> de nuestras radiobases cuentan con energía solar permitiendo alta disponibilidad en el servicio y buscando mejorar la calidad del medio donde vivimos. Nuestra constante apuesta por la tecnología y la innovación en energías limpias es en busca de reducir nuestra huella ambiental. 
                            </p>
                        </div>
                        </div>
                    </div>
                    <div  className="text-center space-bottom-3">
                        <div  className="d-sm-inline-flex align-items-center bg-white shadow-soft rounded-pill p-2 pr-3">
                        <span  className="btn btn-xs btn-primary btn-pill mb-2 mb-sm-0 mr-2">Nuevo</span>
                        <span  className="d-block d-sm-inline-block">
                            Estamos llegando a nuevas comunidades.  
                            <a href="https://www.facebook.com/itmexoficial" target="_blank"> Entérate.</a>
                        </span>
                        </div>
                    </div>
                    <section className="row m-md-3 mt-3 mb-3">
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                    </section>
                </Layout>
            </div>
        )}

};
export default Nosotros;