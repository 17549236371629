import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class ContratoDeAdhesion_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Contrato de Adhesión';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_CONTRATO_DE_ADHESION_2022_V1.pdf?alt=media&token=b86061ed-67f2-4054-aa5b-28ee32f824a6';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default ContratoDeAdhesion_pdf