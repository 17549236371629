import React from 'react';
import Layout from '../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import CURSOS_DATA from '../data/data';
import CursoItemCatalogo from './components/CursoItemCatalogo';
import { auth, getCursosRef, getClasesRef, getModulosRef, getMisCursosRef, createUserProfileDocument } from '../firebase/firebase.utils'


class CursosCatalogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursos: [],
            loading: true,
            misCursos: []
        }
    }

    obtenerCursos = async (id) => {
        if (id) {
            await getMisCursosRef(id).then((arrayCursos) => {
                if (arrayCursos.length > 0) {
                    this.setState({ misCursos: arrayCursos })
                }
            })
        }
        var cursosList = []
        const cursosRef = await getCursosRef()
        await cursosRef.get().then(qSnapshot => {
            qSnapshot.forEach(async curso => {
                /* const modulosList = []
                const modulosRef = getModulosRef(curso.id)
                await modulosRef.get().then(q2Snapshot => {
                    q2Snapshot.forEach(modulo => {
                        modulosList.push(modulo.data())
                    })
                }) */

                const dataRaw = curso.data()
                dataRaw.id = curso.id
                if(this.state.misCursos.find(e => e == curso.id)){
                    dataRaw.inscrito = true
                }else{
                    dataRaw.inscrito = false
                }
/*                 dataRaw.modulos = modulosList*/
                cursosList.push(dataRaw)
                // doc.data() is never undefined for query doc snapshots
                this.setState({ cursos: cursosList, loading: false })
            });
        })
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Catálogo de Cursos';

        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                this.obtenerCursos(userRef.id)
            } else {
                this.obtenerCursos(false)
            }
        })
    }

    render() {
        return (
            <div className=" " >
                <Layout title="Red Itmex - Catálogo de Cursos">

                    <main id="content" role="main" style={{ marginBottom: '20px' }}>
                        <div className="space-top-1">
                            <div className="bg-primary " style={{ background: 'url(/assets/svg/illustrations/book.svg)', backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat' }}>
                                <div className="w-lg-50">
                                    <div className="py-8 px-6">
                                        <h1 className="display-4 text-white">Catálogo</h1>
                                        <p className="text-white mb-0">Encuentra hasta <span className="font-weight-bold">{this.state.cursos.length}</span> cursos.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                    <div className="loaderMin" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                        <div className="Box"></div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {
                                this.state.cursos == [] ?
                                    <div>No hay cursos disponibles.</div>
                                    :
                                    <div className="row">
                                        {this.state.cursos.map((curso) => (
                                            <CursoItemCatalogo key={curso.id}  curso={curso} user={false} inscrito={curso.inscrito ? true : false} />
                                        ))}
                                    </div>
                            }
                        </div>
                    </div>
                </Layout>
            </div>
        )
    }
}
export default CursosCatalogo;