import React, { Fragment } from 'react';
import Layout from '../core/Layout';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CURSOS_DATA from '../data/data';
import CursoItemCatalogo from '../academia/components/CursoItemCatalogo'
import UserProfile from './UserProfile';


import { signOutItmex } from '../auth/auth.utils'
import UserSideMenu from './components/UserSideMenu';
import { getCursosRef, getModulosRef, getClasesRef, getMiCurso, getMisCursosRef } from '../firebase/firebase.utils';
import { auth, createUserProfileDocument } from '../firebase/firebase.utils'
class UserCursos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursos: [],
            loading: true,
        }
    }
    obtenerMisCursos = async (id) => {
        await getMisCursosRef(id).then((arrayCursos) => {
            if (arrayCursos.length > 0) {
                var cursosList = []
                arrayCursos.forEach(async micurso => {
                    await getMiCurso(micurso).then(async (curso) => {
                        const modulosRef = getModulosRef(curso.id)
                        const modulosList = []
                        if (modulosRef) {
                            await modulosRef.get().then(q2Snapshot => {
                                q2Snapshot.forEach(modulo => {
                                    modulosList.push(modulo.data())
                                })
                            })
                        }
                        if (curso.id) {
                            curso.modulos = modulosList
                            cursosList.push(curso)
                        }
                        this.setState({ cursos: cursosList })
                    })
                    this.setState({ loading: false })
                })
            } else {
                this.setState({ loading: false })
            }
        })
        /* await cursosRef.get().then(qSnapshot => {
            qSnapshot.forEach(async curso => {
                const modulosList = []
                const modulosRef = getModulosRef(curso.id)
                await modulosRef.get().then(q2Snapshot => {
                    q2Snapshot.forEach(modulo => {
                        modulosList.push(modulo.data())
                    })
                })

                const dataRaw = curso.data()
                dataRaw.id = curso.id
                dataRaw.modulos = modulosList
                clasesList.push(dataRaw)
                // doc.data() is never undefined for query doc snapshots
                this.setState({ cursos: clasesList, loading: false })
            });
        }) */
    }
    componentDidMount() {
        AOS.init({})
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Mis Cursos';
        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                userRef.onSnapshot(snapShot => {
                    this.obtenerMisCursos(snapShot.id)
                })
            }
        })
    }
    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }
    salir = e => {
        e.preventDefault();
        signOutItmex(() => {
            this.props.history.push('/login');
        });
    }
    render() {
        return (
            <div className=" ">

                <Layout title="Red Itmex - Planes">

                    <main id="content" role="main" className="bg-light">
                        <div className="bg-navy" style={{ backgroundImage: 'url(/assets/svg/components/abstract-shapes-20.svg)' }}>
                            <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="d-none d-lg-block">
                                            <h1 className="h2 text-white">{this.state.usuario}Mis cursos</h1>
                                        </div>

                                        <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                                            <li className="breadcrumb-item">Cuenta</li>
                                            <li className="breadcrumb-item active" aria-current="page">Mis Cursos</li>
                                        </ol>
                                    </div>

                                    <div className="col-auto">
                                        <div className="d-none d-lg-block">
                                            <button onClick={this.salir} className="btn btn-sm btn-soft-light">Cerrar Sesión</button>
                                        </div>

                                        <button type="button" className="navbar-toggler btn btn-icon btn-sm rounde-circle d-lg-none"
                                            aria-label="Toggle navigation"
                                            aria-expanded="false"
                                            aria-controls="sidebarNav"
                                            data-toggle="collapse"
                                            data-target="#sidebarNav">
                                            <span className="navbar-toggler-default">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
                                                </svg>
                                            </span>
                                            <span className="navbar-toggler-toggled">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container space-1 space-top-lg-0 mt-lg-n10">
                            <div className="row">

                                <UserSideMenu />
                                <div className="col-lg-9">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-header-title">Mis Cursos</h5>
                                            <span>{this.state.cursos.length} cursos</span>
                                        </div>
                                        <div className="loaderMin" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                                            <div className="Box"></div>
                                        </div>
                                        <div className="card-body" style={{ display: this.state.loading ? 'none' : 'block' }}>

                                            {this.state.cursos.length > 0 ?
                                                <Fragment>
                                                    <div className="row">
                                                        {
                                                            this.state.cursos.map((curso) => (
                                                                <CursoItemCatalogo key={curso.id} user={true} curso={curso} inscrito={true} />
                                                            ))
                                                        }
                                                    </div>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <center><h2>No estás inscrito en ningún curso.</h2></center>
                                                </Fragment>
                                            }


                                        </div>

                                        <Link className="card-footer btn btn-block btn-sm btn-link" to="/academia/cursos/">Buscar más cursos</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
            </div>
        )
    }
}

export default UserCursos;