import React from 'react';
import Layout from './Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';

class Becas extends React.Component {
    constructor(props){
      super(props);
    }
    componentDidMount(){
      AOS.init({
      })
      let event = new Event('js');
      window.dispatchEvent(event);
      document.title='Red Itmex - Becas';
      this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render(){
        return(
            <div>
                <Layout title="Red Itmex - Becas">
                <main id="content" role="main" className="">
                    {/* Hero Section -->*/}
                    <div className="bg-primary bg-img-hero bg-cover" style={{backgroundImage: 'url(/assets/img/n2.jpg)', backgroundPosition: 'center'}}>
                    <div className="container space-2 space-lg-3">
                        <div className="w-lg-65 text-center mx-lg-auto">
                        <span className="btn btn-xs btn-soft-white btn-pill mb-3">#DonandoBytes</span>
                        <h1 className="text-white font-weight-medium mb-0">¡Llevemos internet a las escuelas más remotas!</h1>
                        </div>
                    </div>
                    </div>
                    {/* End Hero Section -->*/}
                    <div className="container space-2 space-lg-0">
                        <div className="row">
                            <div id="stickyBlockStartPoint" className="col-lg-4 mt-lg-n11 mb-7 mb-lg-0">
                                {/* Sidebar Content -->*/}
                                <div className="js-sticky-block card border-0 bg-white shadow-soft"
                                    data-hs-sticky-block-options='{
                                    "parentSelector": "#stickyBlockStartPoint",
                                    "breakpoint": "md",
                                    "startPoint": "#stickyBlockStartPoint",
                                    "endPoint": "#stickyBlockEndPoint",
                                    "stickyOffsetTop": 24,
                                    "stickyOffsetBottom": 24
                                    }'>

                                    <div className="card-body p-4">
                                    <div className="border-bottom pb-2 mb-4">
                                        <dl className="row font-size-1">
                                        <dt className="col-sm-4">Edición</dt>
                                        <dd className="col-sm-8 text-secondary">2020</dd>
                                        </dl>
                                        <dl className="row font-size-1">
                                        <dt className="col-sm-4">Fecha</dt>
                                        <dd className="col-sm-8 text-secondary">Próximamente</dd>
                                        </dl>
                                        <dl className="row font-size-1">
                                        <dt className="col-sm-4">Meta</dt>
                                        <dd className="col-sm-8 text-secondary">Próximamente</dd>
                                        </dl>
                                    </div>

                                    <h4 className="h6 mb-3">Formas de Ayudar</h4>
                                    <dl className="row font-size-1">
                                        <dt className="col-sm-4">
                                        <figure id="icon34" className="svg-preloader ie-height-56 max-width-8 w-100">
                                            <img className="js-svg-injector" src="/assets/svg/icons/icon-34.svg" alt="SVG"
                                                data-parent="#icon34" />
                                        </figure>
                                        </dt>
                                        <dd className="col-sm-8 text-secondary">
                                        <h4 className="font-size-1 font-weight-semi-bold mb-0">En Línea</h4>
                                        <p className="font-size-1 mb-0"><button className="btn btn-sm btn-primary transition-3d-hover disabled" style={{width: '100%', cursor: 'not-allowed'}}>Donar</button></p>
                                        </dd>
                                    </dl>
                                    <dl className="row font-size-1 mb-0">
                                        <dt className="col-sm-4">
                                        <figure id="icon41" className="svg-preloader ie-height-56 max-width-8 w-100">
                                            <img className="js-svg-injector" src="/assets/svg/icons/icon-8.svg" alt="SVG"
                                                data-parent="#icon41" />
                                        </figure>
                                        </dt>
                                        <dd className="col-sm-8 text-secondary">
                                        <h4 className="font-size-1 font-weight-semi-bold mb-0">En Sucursal</h4>
                                        <p className="font-size-1 mb-0">Av. 5 de Mayo No.1, Colonia Centro, Ixmiquilpan Hidalgo, C.P. 42300</p>
                                        </dd>
                                    </dl>
                                    </div>
                                </div>
                                {/* End Sidebar Content -->*/}
                            </div>

                            <div className="col-lg-8 space-lg-2">
                            <div className="pl-lg-4">
                                <h2 className="h4">¡Más información en los próximos días!</h2>
                    
                                {/* <div className="pt-7 pb-9">
                                <img className="img-fluid rounded" src="../../assets/img/900x450/img13.jpg" alt="Image Description">
                                </div> -->*/}
                    
                    
                    
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Sticky Block End Point -->*/}
                    <div id="stickyBlockEndPoint"></div>
                    
                    <hr className="featurette-divider" />
                    
                </main>
                </Layout>
            </div>
        )
    }
};
export default Becas;