import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class FormatoSimplificado1347564PlanBasicoPlusS_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Formato Simplificado 1347564 Plan Básico + SIMÉTRICO';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_FORMATO_SIMPLIFICADO_1347564_BASICO%2BS_2024_V1.pdf?alt=media&token=ca837bf7-5486-465a-aea0-708cbf128293';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default FormatoSimplificado1347564PlanBasicoPlusS_pdf