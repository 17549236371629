import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class AvisoDePrivacidad_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Aviso de Privacidad';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_AVISO_DE_PRIVACIDAD_2024_V1.pdf?alt=media&token=988b152e-b9fe-448b-8a21-625d86a80961';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default AvisoDePrivacidad_pdf