import React from 'react';
import Layout from './Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import Ads from './Ads';
class Promociones extends React.Component {
    constructor(props){
      super(props);
    }
    componentDidMount(){
      AOS.init({
      })
      let event = new Event('js');
      window.dispatchEvent(event);
      document.title='Red Itmex - Promociones';
      this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render(){
        return(
            <div>
                
                <Layout title="Red Itmex - Promociones">
                <div className="gradient-half-primary-v2" style={{width: '100%'}}>
                    <div className="container space-1 space-top-md-1 space-top-lg-1 ">
                        <div className="row justify-content-lg-between d-none d-md-block">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" style={{width: '100%'}}>
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    {/*<!-- <li data-target="#carouselExampleIndicators2" data-slide-to="1" className=""></li> -->*/}
                                </ol>
                                <div className="carousel-inner" style={{width: '100%'}}>
                                    <div className="">
                                        <div className="carousel-item active" style={{ backgroundColor: '#3a3a3a', color: 'white', width:'100%',  textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/large/1.jpg" style={{width:'100%'}}/>
                                        {/* <h1>¡No tenemos promociones!</h1>
                                            <h4>Vuelve pronto</h4> */}
                                        </div>
                                        <div className="carousel-item" style={{ backgroundColor: '#3a3a3a', color: 'white', width:'100%',  textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/large/2.jpg" style={{width:'100%'}}/>
                                        </div>
                                        <div className="carousel-item" style={{ backgroundColor: '#3a3a3a', color: 'white', width:'100%',  textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/large/3.jpg" style={{width:'100%'}}/>
                                        </div>
                                        <div className="carousel-item" style={{ backgroundColor: '#3a3a3a', color: 'white', width:'100%',  textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/large/4.jpg" style={{width:'100%'}}/>
                                        </div>
                                    </div>
                                    {/*<!-- <div className="carousel-item" style="background-image:url('/public/img/n1.jpg'); background-repeat: none; background-size: cover; #3a3a3a; color: white; width:100%; height: 475px; text-align: center; padding-top:170px;">
                                    </div> -->*/}
                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                        <div className="row justify-content-lg-between d-block d-md-none d-lg-none d-xl-none">
                            <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel" style={{width: '100%'}}>
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators2" data-slide-to="0" className="active"></li>
                                    {/*<!-- <li data-target="#carouselExampleIndicators2" data-slide-to="1" className=""></li> -->*/}
                                </ol>
                                <div className="carousel-inner" style={{width: '100%'}}>
                                    <div className="">
                                        <div className="carousel-item active" style={{  backgroundColor: '#3a3a3a', color: 'white', width:'100%', textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/min/1.jpg" style={{width:'100%'}}/>
                                        </div>
                                        <div className="carousel-item" style={{  backgroundColor: '#3a3a3a', color: 'white', width:'100%', textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/min/2.jpg" style={{width:'100%'}}/>
                                        </div>
                                        <div className="carousel-item" style={{  backgroundColor: '#3a3a3a', color: 'white', width:'100%', textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/min/3.jpg" style={{width:'100%'}}/>
                                        </div>
                                        <div className="carousel-item" style={{  backgroundColor: '#3a3a3a', color: 'white', width:'100%', textAlign: 'center'}}>
                                            <img src="/assets/img/promociones/min/4.jpg" style={{width:'100%'}}/>
                                        </div>
                                    </div>
                                    {/*<!-- <div className="carousel-item" style="background-image:url('/public/img/n1.jpg'); background-repeat: none; background-size: cover; #3a3a3a; color: white; width:100%; height: 475px; text-align: center; padding-top:170px;">
                                    </div> -->*/}
                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>   
                </div>
                
                <section className="row m-md-3 mt-3 mb-3">
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                    </section>
                </Layout>
            </div>
        )
    }
};
export default Promociones;