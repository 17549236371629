import React from 'react';
import Layout from '../core/Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'

import { getUserData, signOutItmex } from '../auth/auth.utils'
import UserSideMenu from './components/UserSideMenu';


import { auth, createUserProfileDocument, getCodigoRef, redimirCodigo } from '../firebase/firebase.utils'

class UserCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUserData(),
            codigo: '',
            error: false,
            success: false,
            errorMessage: '',
            successMessage: ''
        }
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                userRef.onSnapshot(snapShot => {
                    //console.log(snapShot.id)
                    this.setState({
                        currentUser: {
                            id: snapShot.id,
                        },
                        loading: false
                    });
                })
            }
        })
        document.title = 'Red Itmex - Canjear Código';
    }
    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }
    salir = e => {
        e.preventDefault();
        signOutItmex(() => {
            this.props.history.push('/login');
        });
    }
    handleChange = (e) => {
        this.setState({ codigo: e.target.value })
    }
    handleSubmitCodigo = async e => {
        e.preventDefault();
        if (this.state.codigo) {
            const codeDB = await getCodigoRef(this.state.codigo);
            if (codeDB) {
                const redimir = await redimirCodigo(this.state.codigo, this.state.currentUser.id)
                if(!redimir.error){
                    this.setState({ codigo: '', error: false, success: true, successMessage: redimir.idCurso, errorMessage: '' })
                }else{
                    this.setState({ codigo: '', error: true, success: false, errorMessage: redimir.errorMessage })
                }
            } else {
                this.setState({ codigo: '', error: true, success: false, errorMessage: "Ingresa un código válido" })
            }
        }
        else {
            this.setState({ codigo: '', error: true, success: false, errorMessage: "Ingresa un código válido" })
        }
    }
    render() {
        return (
            <div className=" ">

                <Layout title="Red Itmex - Planes">

                    <main id="content" role="main" className="bg-light">
                        <div className="bg-navy" style={{ backgroundImage: 'url(/assets/svg/components/abstract-shapes-20.svg)' }}>
                            <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="d-none d-lg-block">
                                            <h1 className="h2 text-white">Canjear Código</h1>
                                        </div>

                                        <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                                            <li className="breadcrumb-item">Cuenta</li>
                                            <li className="breadcrumb-item active" aria-current="page">Código</li>
                                        </ol>
                                    </div>

                                    <div className="col-auto">
                                        <div className="d-none d-lg-block">
                                            <button onClick={this.salir} className="btn btn-sm btn-soft-light" href="#">Cerrar Sesión</button>
                                        </div>

                                        <button type="button" className="navbar-toggler btn btn-icon btn-sm rounde-circle d-lg-none"
                                            aria-label="Toggle navigation"
                                            aria-expanded="false"
                                            aria-controls="sidebarNav"
                                            data-toggle="collapse"
                                            data-target="#sidebarNav">
                                            <span className="navbar-toggler-default">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
                                                </svg>
                                            </span>
                                            <span className="navbar-toggler-toggled">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container space-1 space-top-lg-0 mt-lg-n10">
                            <div className="row">

                                <UserSideMenu />
                                <div className="col-lg-9">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-header-title">Canjear Código</h5>
                                        </div>

                                        <div className="card-body">
                                            {this.state.error ?
                                                <div className="alert alert-danger" role="alert">
                                                    <i className="fas fa-exclamation-triangle"></i> {this.state.errorMessage}
                                                </div>
                                                :
                                                <div></div>
                                            }
                                            {this.state.success ?
                                                <div className="alert alert-success" role="success">
                                                    <i className="fas fa-thumbs-up"></i> ¡Felicidades! Tu curso ha sido agregado. <Link to={"/academia/curso/"+this.state.successMessage} style={{color: 'white', textDecoration: 'none', fontWeight: 'bold'}}>Ver aquí</Link>.
                                                </div>
                                                :
                                                <div></div>
                                            }
                                            <div className="row form-group">
                                                <label htmlFor="codigoLabel" className="col-sm-3 col-form-label input-label">Código</label>

                                                <div className="col-sm-9">
                                                    <div className="input-group">
                                                        <input type="text" onChange={this.handleChange} className="form-control" name="codigo" id="codigoLabel" placeholder="XXXXXXXXXXXX" value={this.state.codigo} aria-label="Código" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <span className="mx-2"></span>
                                            <button onClick={this.handleSubmitCodigo} className="btn btn-primary" >Canjear Código</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
            </div>
        )
    }
}

export default UserCode;