import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import Ads from './Ads';
class PreguntasFrecuentesInternet extends React.Component {
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - reguntas Frecuentes Internet';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div className=" ">

                <Layout title="Red Itmex - Preguntas Frecuentes Internet">
                    <div id="SVGwave1BottomSMShape" className="position-relative priceSection" style={{marginBottom: '-120px', overflow: 'hidden'}}>
                        <ul className="bg-bubbles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <div className="container space-top-2 space-top-md-4 space-bottom-4">
                            <div className="w-md-80 w-lg-60 text-center mx-auto">
                                <div className="mb-6">
                                    <h1 className="text-white">Preguntas Frecuentes</h1>
                                </div>
                            </div>
                        </div>
                        {/* SVG Background */}
                        <figure className="position-absolute right-0 bottom-0 left-0">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" width="100%" height="140px" viewBox="0 0 300 100" style={{ marginBottom: '-8px', enableBackground: 'new 0 0 300 100' }} xmlSpace="preserve" className="injected-svg js-svg-injector" data-parent="#SVGwave1BottomSMShape">

                                <g>
                                    <defs>
                                        <rect id="waveBottom1SMID1" width="300" height="100"></rect>
                                    </defs>
                                    <clipPath id="waveBottom1SMID2">
                                        <use xlinkHref="#waveBottom1SMID1" style={{ overflow: 'visible' }}></use>
                                    </clipPath>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" clipPath="url(#waveBottom1SMID2)" d="M10.9,63.9c0,0,42.9-34.5,87.5-14.2c77.3,35.1,113.3-2,146.6-4.7C293.7,41,315,61.2,315,61.2v54.4H10.9V63.9z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" clipPath="url(#waveBottom1SMID2)" d="M-55.7,64.6c0,0,42.9-34.5,87.5-14.2c77.3,35.1,113.3-2,146.6-4.7c48.7-4.1,69.9,16.2,69.9,16.2v54.4H-55.7     V64.6z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" fillOpacity="0" clipPath="url(#waveBottom1SMID2)" d="M23.4,118.3c0,0,48.3-68.9,109.1-68.9c65.9,0,98,67.9,98,67.9v3.7H22.4L23.4,118.3z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" clipPath="url(#waveBottom1SMID2)" d="M-54.7,83c0,0,56-45.7,120.3-27.8c81.8,22.7,111.4,6.2,146.6-4.7c53.1-16.4,104,36.9,104,36.9l1.3,36.7l-372-3     L-54.7,83z"></path>
                                </g>
                            </svg>
                        </figure>
                        {/* End SVG Background */}
                    </div>
                <div className="container space-2 space-md-3">

                    {/* FAQ */}
                    <div className="space-bottom-2 space-bottom-md-3" id="faq">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="pr-md-4">
                                    <h3>¿Dónde puedo pagar mis servicios?</h3>
                                    <p>
                                        ¡Pagar tu servicio es muy sencillo!. Puedes pagar directamente en <Link to="/contacto">nuestras oficinas</Link> o solicitando el número de cuenta a nuestros ejecutivos.
                                    </p>
                                    <p>
                                        <b>Dirección:</b> Av. 5 de Mayo No.1, Colonia Centro, Ixmiquilpan Hidalgo, C.P. 42300
                                    </p>
                                </div>
                            </div>


                            <div className="col-md-6 mb-5 mb-md-0">
                                <div className="pr-md-4">
                                    <h3>¿Por qué debo tener encendidos los equipo todo el tiempo?</h3>
                                    <p>
                                        Para que haya un mejor monitoreo en tu servicio y para que nuestros técnicos puedan dar mantenimiento a tus equipos de manera remota sin costo alguno, con el fin de mejorar tu experiencia en internet.
                                    </p>
                                </div>
                            </div>

                            <div className="w-100"></div>

                            <div className="col-md-6 mb-5">
                                <div className="">
                                    <h3>¿Qué día tengo que pagar mi servicio?</h3>
                                    <p>
                                        Todos los servicios tienen como fecha límite de pago el día <b>5 de cada mes</b>. Una vez pasando esta fecha, se cobrarán cargos por reconexión.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 mb-5">
                                <div className="pr-md-4">
                                    <h3 >¿Qué es el cargo por reconexión?</h3>
                                    <p>
                                        Este cargo es por pagar posteriormente a la fecha límite de pago y es de <b>$30.00</b>.
                                    </p>
                                </div>
                            </div>

                            <div className="w-100"></div>

                            <div className="col-md-6 mb-5">
                                <div className="">
                                    <h3>¿Qué es la asistencia a domicilio?</h3>
                                    <p>
                                        Este servicio es una Visita técnica por causas que no son imputables a la empresa, tales como instalación de servicios adicionales solicitados, reparaciones y mantenimiento.
                                    </p>
                                    <p>
                                        El costo de la asistencia a domicilio es de <b>$80.00 pesos mexicanos</b>. (no incluye reposición de un equipo dañado).
                                    </p>
                                </div>
                            </div>



                            <div className="col-md-6">
                                <div className="">
                                    <h3>Ya cuento con servicio de internet, pero quiero cambiarme</h3>
                                    <p>
                                        Si actualmente cuentas con algún proveedor de internet y quieres utilizar los mismos equipos para no volver a invertir. Nosotros hacemos una valoración técnica y te informamos al momento si es posible utilizar los equipos con los que actualmente cuentas o necesitas una nueva instalación. <b>¡Bienvenido a la Red Itmex!</b>
                                    </p>
                                </div>
                            </div>

                            <div className="w-100"></div>

                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿Existe un plazo mínimo de contratación del servicio?</h3>
                                    <p>
                                    El costo de instalación es de $600.00 MXN con contrato a 12 meses. La instalación sin contrato es por $1,500.00 MXN. El equipo se instala en comodato (prestado). Revisa nuestras promociones.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿Dónde tienen cobertura?</h3>
                                    <p>
                                        Puedes revisar las comunidades donde tenemos cobertura <Link to="/cobertura">aquí</Link>. Si tu comunidad no aparece en la lista, puedes <Link to="/contacto">preguntarnos</Link> sobre la disponibilidad en tu área de residencia.
                                    </p>
                                </div>
                            </div>

                            <div className="w-100"></div>

                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿Qué es la fibra óptica?</h3>
                                    <p>
                                        La fibra óptica es una tecnología de transmisión de datos muy rápida. Imagina un hilo delgado y transparente, hecho de vidrio o plástico, por donde viaja tu internet a la velocidad de la luz. ¡Asombroso!, ¿no?
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿Qué es velocidad simétrica?</h3>
                                    <p>
                                        La velocidad simétrica en internet se refiere a cuando la velocidad de subida (upload) es igual a la velocidad de bajada (download). Esto significa que puedes enviar archivos o hacer videollamadas con la misma rapidez que puedes descargar películas o música. Nuestros planes Gamer+ y Empresarial cuentan con velocidad simétrica. ¡Equilibrio perfecto!
                                    </p>
                                </div>
                            </div>

                            <div className="w-100"></div>

                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿La fibra óptica es mejor que el internet por antenas?</h3>
                                    <p>
                                        Aunque el internet por antenas (inalámbrico) es grandioso, la fibra óptica lleva la experiencia de navegación a otro nivel. Es más rápida, tiene mejor rendimiento y sufre menos interferencias. Además, la fibra óptica permite velocidades simétricas, lo que es genial para actividades como gaming o streaming (Netflix, YouTube, Spotify, etc).
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿La fibra óptica es resistente al mal tiempo?</h3>
                                    <p>
                                        ¡Sí, es uno de los beneficios de la fibra óptica! Las tormentas eléctricas no afectan la calidad de la señal de fibra óptica como pueden hacerlo con las conexiones inalámbricas. Así que, aunque afuera esté lloviendo a cántaros, tu conexión seguirá funcionando genial.
                                    </p>
                                </div>
                            </div>

                            <div className="w-100"></div>

                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿Cómo se instala la fibra óptica?</h3>
                                    <p>
                                        Un técnico especializado de <b>Red Itmex</b> se encargará de llevar a cabo la instalación. El cable de fibra óptica llegará hasta tu casa y se conectará a un dispositivo llamado ONT/Modem. De ahí podrás disfrutar de una conexión ultrarrápida. No te preocupes, nuestro equipo te explicará todo durante la instalación.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿La fibra óptica funciona con todos los dispositivos?</h3>
                                    <p>
                                        ¡Sí! Puedes conectar cualquier dispositivo que tenga capacidad para conectarse a internet, como smartphones, tablets, ordenadores, consolas de videojuegos y más. 
                                    </p>
                                </div>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿Puedo cambiar mi plan de internet inalámbrico a fibra óptica?</h3>
                                    <p>
                                    ¡Por supuesto! Estamos más que dispuestos a ayudarte si eres usuario de la <b>Red Itmex</b> la migración será <b>gratuita</b>. Si eres nuevo usuario te ayudaremos a hacer el cambio y a explicarte todos los beneficios que obtendrás al cambiar a fibra óptica.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <h3>¿Qué hago si tengo problemas con mi conexión de fibra óptica?</h3>
                                    <p>
                                        En <b>Red Itmex</b> siempre estamos listos para ayudarte. Si tienes algún problema, no dudes en <Link to="/contacto">contactarnos</Link>. Nuestro equipo de soporte técnico está disponible para ayudarte a solucionar cualquier problema que puedas tener.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End FAQ */}

                    {/* Divider */}
                    <div className="w-80 mx-auto">
                        <hr className="my-0" />
                    </div>
                    {/* End Divider */}
                </div>
                </Layout>
            </div >
        )
    }
};
export default PreguntasFrecuentesInternet;