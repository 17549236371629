import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class TituloDeConcesion_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Título de Concesion';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_TITULO_DE_CONCESION_2024_V1.pdf?alt=media&token=8c161f84-415f-41bc-96c2-9c9d931c3376';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default TituloDeConcesion_pdf