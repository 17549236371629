export class timeUtils {
    constructor(initTime) {
      this.hours = this.getHoursTotal(initTime)
      this.minutes = this.getMinutesTotal(initTime)
      this.seconds = this.getSecondsTotal(initTime)
    }
    getHoursTotal(time){
        var hours = time.split(":")[0]
        hours = parseInt(hours)
        var minutes = this.getMinutes(time)/60
        var seconds = (this.getSeconds(time)/60)/60
        hours = hours + minutes + seconds
        return hours
    }
    getMinutesTotal(time){
        var hours = this.getHoursTotal(time);
        return hours*60
    }
    getSecondsTotal(time){
        var hours = this.getHoursTotal(time);
        return hours*60*60
    }
    getMinutes(time){
        var minutes = time.split(":")[1]
        minutes = parseInt(minutes)
        return minutes
    }
    getSeconds(time){
        var seconds = time.split(":")[2]
        seconds = parseInt(seconds)
        return seconds
    }
  } 
  export const formatedTime = (time) => {
    time = parseFloat(time)
    var hours = Math.floor(time)
    var minutes = (Math.floor((time - Math.floor(time))*60))
    var seconds = Math.round((((time - Math.floor(time))*60) - minutes)*60)
    return pad_with_zeroes(hours,2) + ":" + pad_with_zeroes(minutes,2) + ":" + pad_with_zeroes(seconds,2)
  }

function pad_with_zeroes(number, length) {

    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }

    return my_string;

}