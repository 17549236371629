import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import ScrollToTop from './scrollToTop'


//RUTAS DEL CORE
import Home from './core/Home';
import Nosotros from './core/Nosotros.js';
import Cobertura from './core/Cobertura.js';
import PreguntasFrecuentesInternet from './core/PreguntasFrecuentesInternet.js';
import PlanesFibra from './core/PlanesFibra.js';
import Promociones from './core/Promociones.js';
import Concursos from './core/Concursos.js';
import Blog from './core/Blog.js';
import Podcasts from './core/Podcasts.js';
import Becas from './core/Becas.js';
import Contacto from './core/Contacto.js';
import Privacidad from './core/Privacidad.js';

//RUTAS DE CONCURSOS
import C1Septiembre2020 from './concursos/C1Septiembre2020.js';

import E404 from './core/E404.js'

//RUTAS DE ACADEMIA
import AcademiaFaq from './academia/AcademiaFaq';
import CursosHome from './academia/CursosHome';
import CursosCatalogo from './academia/CursosCatalogo';
import CursoDetalle from './academia/CursoDetalle';
import ClaseDetalle from './academia/ClaseDetalle';

import PagoExito from './academia/PagoExito';
//RUTAS DE USUARIO
import Login from './auth/Login';
import UserCursos from './account/UserCursos';
import UserRecompensas from './account/UserRecompensas';
import UserCode from './account/UserCode';
import UserProfile from './account/UserProfile';

//TOOLS
import QRCodeTool from './tools/qrcode'

//documentacion
import Documentacion from './documentacion/documentacion'
import DocumentacionLegal from './documentacion/legal/documentacionLegal'
import DocumentacionFiber from './documentacion/fiber/documentacionFiber'


import AvisoAtencionAUsuarios_pdf from './documentacion/legal/pdf/AvisoAtencionAUsuarios_pdf'
import AvisoDePrivacidad_pdf from './documentacion/legal/pdf/AvisoDePrivacidad_pdf'
import AvisoMapasDeCobertura_pdf from './documentacion/legal/pdf/AvisoMapasDeCobertura_pdf'
import CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetFibraOptica_pdf from './documentacion/legal/pdf/CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetFibraOptica_pdf'
import CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetWireless_pdf from './documentacion/legal/pdf/CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetWireless_pdf'
import CartaDeDerechosMinimosDeLosUsuarios_pdf from './documentacion/legal/pdf/CartaDeDerechosMinimosDeLosUsuarios_pdf'
import CodigoDePoliticasDeGestionDeTrafico_pdf from './documentacion/legal/pdf/CodigoDePoliticasDeGestionDeTrafico_pdf'
import CodigoDePracticasComerciales_pdf from './documentacion/legal/pdf/CodigoDePracticasComerciales_pdf'
import ColaboracionEnMateriaDeSeguridadYJusticia_pdf from './documentacion/legal/pdf/ColaboracionEnMateriaDeSeguridadYJusticia_pdf'
import ContratoDeAdhesion_pdf from './documentacion/legal/pdf/ContratoDeAdhesion_pdf'
import FormatoSimplificado536878PlanBasico_pdf from './documentacion/legal/pdf/FormatoSimplificado536878PlanBasico_pdf'
import FormatoSimplificado536888PlanPremium_pdf from './documentacion/legal/pdf/FormatoSimplificado536888PlanPremium_pdf'
import FormatoSimplificado536892PlanGold_pdf from './documentacion/legal/pdf/FormatoSimplificado536892PlanGold_pdf'
import FormatoSimplificado536901PlanGamer_pdf from './documentacion/legal/pdf/FormatoSimplificado536901PlanGamer_pdf'
import FormatoSimplificado1347530PlanBasicoPlus_pdf from './documentacion/legal/pdf/FormatoSimplificado1347530PlanBasicoPlus_pdf.js'
import FormatoSimplificado1347531PlanPremiumPlus_pdf from './documentacion/legal/pdf/FormatoSimplificado1347531PlanPremiumPlus_pdf'
import FormatoSimplificado1347543PlanGoldPlus_pdf from './documentacion/legal/pdf/FormatoSimplificado1347543PlanGoldPlus_pdf'
import FormatoSimplificado1347545PlanGamerPlus_pdf from './documentacion/legal/pdf/FormatoSimplificado1347545PlanGamerPlus_pdf'
import FormatoSimplificado1347559PlanEmpresarialPlus_pdf from './documentacion/legal/pdf/FormatoSimplificado1347559PlanEmpresarialPlus_pdf'
import FormatoSimplificado1347564PlanBasicoPlusS_pdf from './documentacion/legal/pdf/FormatoSimplificado1347564PlanBasicoPlusS_pdf.js'
import FormatoSimplificado1347566PlanPremiumPlusS_pdf from './documentacion/legal/pdf/FormatoSimplificado1347566PlanPremiumPlusS_pdf'
import FormatoSimplificado1347567PlanGoldPlusS_pdf from './documentacion/legal/pdf/FormatoSimplificado1347567PlanGoldPlusS_pdf'
import FormatoSimplificado1347568PlanGamerPlusS_pdf from './documentacion/legal/pdf/FormatoSimplificado1347568PlanGamerPlusS_pdf'
import LineamientosDeInformacionTransparente_pdf from './documentacion/legal/pdf/LineamientosDeInformacionTransparente_pdf'
import ProcedimientoDeAtencionDeDudasAclaracionesQuejasYReclamaciones_pdf from './documentacion/legal/pdf/ProcedimientoDeAtencionDeDudasAclaracionesQuejasYReclamaciones_pdf'
import TituloDeConcesion_pdf from './documentacion/legal/pdf/TituloDeConcesion_pdf'

import NapPro_pdf from './documentacion/fiber/pdf/nap-pro'
import NapUlt_pdf from './documentacion/fiber/pdf/nap-ult'
import NapBasx_pdf from './documentacion/fiber/pdf/nap-basx'
import NapBasv_pdf from './documentacion/fiber/pdf/nap-basv'
import CeeRtix_pdf from './documentacion/fiber/pdf/cee-rtix'
import CeeMex_pdf from './documentacion/fiber/pdf/cee-mex'
import RosRtixf_pdf from './documentacion/fiber/pdf/ros-rtixf'
import CfoAdssf_pdf from './documentacion/fiber/pdf/cfo-adssf'
import CfoAsuxf_pdf from './documentacion/fiber/pdf/cfo-asuxf'
import CfoDroaf_pdf from './documentacion/fiber/pdf/cfo-droaf'
import CfoDrobf_pdf from './documentacion/fiber/pdf/cfo-drobf'
import CfoRtip_pdf from './documentacion/fiber/pdf/cfo-rtip'
import RacRtixf_pdf from './documentacion/fiber/pdf/rac-rtixf'
import OdfRtixf_pdf from './documentacion/fiber/pdf/odf-rtixf'
import FasRtic_pdf from './documentacion/fiber/pdf/fas-rtic'
import BraRtiuf_pdf from './documentacion/fiber/pdf/bra-rtiuf'
import SusRtix_pdf from './documentacion/fiber/pdf/sus-rtix'
import SusDrom_pdf from './documentacion/fiber/pdf/sus-drom'
import SusDrop_pdf from './documentacion/fiber/pdf/sus-drop'

import { RutaAutenticada, RutaNoAutenticada, RutaAnalytics } from './RutaAutenticada';
import ReactGA from 'react-ga';
import DocumentacionUsuario from './documentacion/usuario/documentacion';
import FolletoBienvenida_pdf from './documentacion/usuario/pdf/folletoBienvenida';
const Routes = () =>{
    return (
    <BrowserRouter>
        <ScrollToTop>
            <Switch>
                <RutaAnalytics path="/" exact component={Home}/>
                <RutaAnalytics path="/nosotros" exact component={Nosotros}/>
                <RutaAnalytics path="/cobertura" exact component={Cobertura}/>
                <RutaAnalytics path="/internet/faq" exact component={PreguntasFrecuentesInternet}/>
                <RutaAnalytics path="/planesfibra" exact component={PlanesFibra}/>
                <RutaAnalytics path="/promociones" exact component={Promociones}/>
                <RutaAnalytics path="/blog" exact component={Blog}/>
                <RutaAnalytics path="/podcasts" exact component={Podcasts}/>
                <RutaAnalytics path="/concursos" exact component={Concursos}/>
                <RutaAnalytics path="/becas" exact component={Becas}/>
                <RutaAnalytics path="/contacto" exact component={Contacto}/>
                <RutaAnalytics path="/privacidad" exact component={Privacidad}/>

                <RutaAnalytics path="/c/concurso-septiembre-2020" exact component={C1Septiembre2020}/>
                
                <RutaAutenticada path="/pagoexito/:sessionId" exact component={PagoExito}/>
                
                <RutaNoAutenticada path="/login" exact component={Login}/>

                <RutaAnalytics path="/academia/" exact component={CursosHome}/>
                <RutaAnalytics path="/academia/faq" exact component={AcademiaFaq}/>
                <RutaAnalytics path="/academia/cursos/" exact component={CursosCatalogo}/>
                <RutaAnalytics path="/academia/curso/:id" exact component={CursoDetalle}/>
                <RutaAnalytics path="/academia/curso/:idcurso/:idmodulo/:idclase" exact component={ClaseDetalle}/>

                
                <RutaAutenticada path="/perfil/miscursos" exact component={UserCursos}/>  
                <RutaAutenticada path="/perfil/codigo" exact component={UserCode}/> 
                <RutaAutenticada path="/perfil/recompensas" exact component={UserRecompensas}/>
                <RutaAutenticada path="/perfil/" component={UserProfile}/>     


                
                {/* Tools */}
                <RutaAnalytics path="/tools/qrcode" exact component={QRCodeTool}/>

                {/* Documentacion */}
                <RutaAnalytics path="/documentacion/" exact component={Documentacion} />
                <RutaAnalytics path="/documentacion/legal" exact component={DocumentacionLegal} />
                <RutaAnalytics path="/documentacion/usuario" exact component={DocumentacionUsuario} />
                {/* <RutaAnalytics path="/documentacion/fiber" exact component={DocumentacionFiber} /> */}

                <RutaAnalytics path="/documentacion/usuario/folleto-bienvenida/pdf/v1" exact component={FolletoBienvenida_pdf} />
                
                <RutaAnalytics path="/documentacion/legal/aviso-atencion-a-usuarios/pdf/v3" exact component={AvisoAtencionAUsuarios_pdf} />
                <RutaAnalytics path="/documentacion/legal/aviso-de-privacidad/pdf/v3" exact component={AvisoDePrivacidad_pdf} />
                <RutaAnalytics path="/documentacion/legal/aviso-mapas-de-cobertura/pdf/v3" exact component={AvisoMapasDeCobertura_pdf} />
                <RutaAnalytics path="/documentacion/legal/titulo-de-concesion/pdf/v3" exact component={TituloDeConcesion_pdf} />
                <RutaAnalytics path="/documentacion/legal/colaboracion-en-materia-de-seguridad-y-justicia/pdf/v3" exact component={ColaboracionEnMateriaDeSeguridadYJusticia_pdf} />
                <RutaAnalytics path="/documentacion/legal/lineamientos-de-información-transparente/pdf/v3" exact component={LineamientosDeInformacionTransparente_pdf} />
                <RutaAnalytics path="/documentacion/legal/codigo-de-politicas-de-gestion-de-trafico/pdf/v3" exact component={CodigoDePoliticasDeGestionDeTrafico_pdf} />
                <RutaAnalytics path="/documentacion/legal/codigo-de-prácticas-comerciales/pdf/v3" exact component={CodigoDePracticasComerciales_pdf} />
                <RutaAnalytics path="/documentacion/legal/carta-de-derechos-mínimos-de-los-usuarios/pdf/v3" exact component={CartaDeDerechosMinimosDeLosUsuarios_pdf} />
                <RutaAnalytics path="/documentacion/legal/contrato-de-adhesion/pdf/v3" exact component={ContratoDeAdhesion_pdf} />
                <RutaAnalytics path="/documentacion/legal/caracteristicas-especificaciones-y-alcances-de-paquetes-de-internet-wireless/pdf/v3" exact component={CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetWireless_pdf} />
                <RutaAnalytics path="/documentacion/legal/caracteristicas-especificaciones-y-alcances-de-paquetes-de-internet-fibra-optica/pdf/v3" exact component={CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetFibraOptica_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-536878-plan-basico/pdf/v3" exact component={FormatoSimplificado536878PlanBasico_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-536888-plan-premium/pdf/v3" exact component={FormatoSimplificado536888PlanPremium_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-536892-plan-gold/pdf/v3" exact component={FormatoSimplificado536892PlanGold_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-536901-plan-gamer/pdf/v3" exact component={FormatoSimplificado536901PlanGamer_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347530-plan-basico-plus/pdf/v3" exact component={FormatoSimplificado1347530PlanBasicoPlus_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347531-plan-premium-plus/pdf/v3" exact component={FormatoSimplificado1347531PlanPremiumPlus_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347543-plan-gold-plus/pdf/v3" exact component={FormatoSimplificado1347543PlanGoldPlus_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347545-plan-gamer-plus/pdf/v3" exact component={FormatoSimplificado1347545PlanGamerPlus_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347564-plan-basico-plus-simetrico/pdf/v3" exact component={FormatoSimplificado1347564PlanBasicoPlusS_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347566-plan-premium-plus-simetrico/pdf/v3" exact component={FormatoSimplificado1347566PlanPremiumPlusS_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347567-plan-gold-plus-simetrico/pdf/v3" exact component={FormatoSimplificado1347567PlanGoldPlusS_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347568-plan-gamer-plus-simetrico/pdf/v3" exact component={FormatoSimplificado1347568PlanGamerPlusS_pdf} />
                <RutaAnalytics path="/documentacion/legal/formato-simplificado-1347559-plan-empresarial-plus-simetrico/pdf/v3" exact component={FormatoSimplificado1347559PlanEmpresarialPlus_pdf} />
                <RutaAnalytics path="/documentacion/legal/procedimiento-de-atencion-de-dudas-aclaraciones-quejas-y-reclamaciones/pdf/v3" exact component={ProcedimientoDeAtencionDeDudasAclaracionesQuejasYReclamaciones_pdf} />
                                
                <RutaAnalytics path="/documentacion/fiber/nap-pro/pdf/v1" exact component={NapPro_pdf} />
                <RutaAnalytics path="/documentacion/fiber/nap-ult/pdf/v1" exact component={NapUlt_pdf} />
                <RutaAnalytics path="/documentacion/fiber/nap-basx/pdf/v1" exact component={NapBasx_pdf} />
                <RutaAnalytics path="/documentacion/fiber/nap-basv/pdf/v1" exact component={NapBasv_pdf} />
                <RutaAnalytics path="/documentacion/fiber/cee-rtix/pdf/v1" exact component={CeeRtix_pdf} />
                <RutaAnalytics path="/documentacion/fiber/cee-mex/pdf/v1" exact component={CeeMex_pdf} />
                <RutaAnalytics path="/documentacion/fiber/ros-rtixf/pdf/v1" exact component={RosRtixf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/cfo-adssf/pdf/v1" exact component={CfoAdssf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/cfo-asuxf/pdf/v1" exact component={CfoAsuxf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/cfo-droaf/pdf/v1" exact component={CfoDroaf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/cfo-drobf/pdf/v1" exact component={CfoDrobf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/cfo-rtip/pdf/v1" exact component={CfoRtip_pdf} />
                <RutaAnalytics path="/documentacion/fiber/rac-rtixf/pdf/v1" exact component={RacRtixf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/odf-rtixf/pdf/v1" exact component={OdfRtixf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/fas-rtic/pdf/v1" exact component={FasRtic_pdf} />
                <RutaAnalytics path="/documentacion/fiber/bra-rtiuf/pdf/v1" exact component={BraRtiuf_pdf} />
                <RutaAnalytics path="/documentacion/fiber/sus-rtix/pdf/v1" exact component={SusRtix_pdf} />
                <RutaAnalytics path="/documentacion/fiber/sus-drom/pdf/v1" exact component={SusDrom_pdf} />
                <RutaAnalytics path="/documentacion/fiber/sus-drop/pdf/v1" exact component={SusDrop_pdf} />

                <RutaAnalytics component={E404} />            
            </Switch>
        </ScrollToTop>
    </BrowserRouter>
    );
}
export default Routes;
