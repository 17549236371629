import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class CodigoDePoliticasDeGestionDeTrafico_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Código de Políticas de Gestión de Tráfico';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_CODIGO_POLITICAS_GESTION_TRAFICO_2023_V1.pdf?alt=media&token=0ef7ff41-8573-423b-9348-32bea6663a12';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default CodigoDePoliticasDeGestionDeTrafico_pdf