import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { signInWithGoogle, signInWithFacebook } from '../firebase/firebase.utils'
import { authenticate } from './auth.utils';
import { createUserProfileDocument, createCustomerStripe } from '../firebase/firebase.utils'

import { Helmet } from 'react-helmet'
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: 'luisarturozerep@gmail.com',
            password: 'cisco123',
            redirectToReferrer: false
        };
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Iniciar Sesión';
    }
    handleClick = e => {
        e.preventDefault();
    }
    handleGoogleSignIn = e => {
        e.preventDefault();
        signInWithGoogle().then(async result => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            const refUser = await createUserProfileDocument(user, false, 'Google')
            await refUser.get().then(async snapshot => {
                if (snapshot.exists) {
                    const id = snapshot.id
                    const displayName = snapshot.data().displayName
                    const createdAt = snapshot.data().createdAt
                    const email = snapshot.data().email
                    const photoURL = snapshot.data().photoURL
                    if (!snapshot.data().idStripe) {
                        await createCustomerStripe(id, displayName, email)
                    }
                    await authenticate({ id, displayName, createdAt, email, photoURL }, () => {
                        this.props.history.push(`/perfil`);
                    })
                }
            })
        }).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            console.log(error)
        })
    }
    handleFacebookSignIn = e => {
        e.preventDefault();
        signInWithFacebook().then(async result => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            const refUser = await createUserProfileDocument(user, result.credential, 'Facebook')
            await refUser.get().then(async snapshot => {
                if (snapshot.exists) {
                    const id = snapshot.id
                    const displayName = snapshot.data().displayName
                    const createdAt = snapshot.data().createdAt
                    const email = snapshot.data().email
                    const photoURL = snapshot.data().photoURL
                    if (!snapshot.data().idStripe) {
                        await createCustomerStripe(id, displayName, email)
                    }
                    await authenticate({ id, displayName, createdAt, email, photoURL }, () => {
                        this.props.history.push(`/perfil`);
                    })
                }
            })
        }).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            console.log(error)
        })
    }
    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <div>

                <Helmet>
                    <meta name="description" content="Crea tu cuenta en Red Itmex. Inicia Sesión y disfruta del contenido original y exclusivo que tenemos para ti." />
                    <meta name="robots" content="index" />
                    <meta property="og:url" content="https://www.reditmex.com/login" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Red Itmex - Iniciar Sesión" />
                    <meta property="og:description" content="Crea tu cuenta en Red Itmex. Inicia Sesión y disfruta del contenido original y exclusivo que tenemos para ti." />
                    <meta property="og:image" content="https://www.reditmex.com/assets/img/FBThumbnail01.jpg" />
                    <meta property="fb:app_id" content="418170456085047" />
                </Helmet>
                <main id="content" role="main">
                    <div class="d-flex align-items-center position-relative vh-lg-100">
                        <div class="col-lg-5 col-xl-4 d-none d-lg-flex align-items-center vh-lg-100 px-0" style={{ backgroundImage: "url(/assets/img/fotos/loginbg/f" + Math.floor(Math.random() * 10) + ".jpg", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundColor: "#000", backgroundAttachment: "" }}>
                            <div class=" w-100 p-5">
                                <div>
                                    <Link to="/"><img src="/assets/img/logos/LOGOMINBLANCO.png" style={{ height: '2.5rem', position: 'absolute', top: '2rem' }} /></Link>
                                </div>
                                <div class=" partnersLogin position-absolute right-0 bottom-0 left-0 text-center p-5">
                                    <span class="d-block text-white-70 mb-3">Usado por</span>
                                    <div class="d-flex justify-content-center">
                                        <img class="mx-auto" style={{ height: '1.5rem' }} src="/assets/img/partners/shanioffer/LOGOFULLBLANCO.png" alt="ShaniOffer" />
                                        <img class="mx-auto" style={{ height: '1.5rem' }} src="/assets/img/partners/smproducciones/LOGOFULL_BLANCO.png" alt="SMProducciones" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row no-gutters">
                                <div class="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0">
                                    <form class="js-validate">
                                        <div class="mb-5 mb-md-7">
                                            <h1 class="h2">Bienvenido a Red Itmex</h1>
                                            <p>Ingresa tus credenciales para administrar tu cuenta.</p>
                                            <div class="alert alert-primary" role="alert">El ingreso con correo y contraseña todavía no está disponible. Usa Facebook o Google Authenticator.</div>
                                        </div>

                                        <div class="js-form-message form-group">
                                            <label class="input-label" for="signinSrEmail">Correo electrónico</label>
                                            <input type="email" class="form-control" name="email" id="signinSrEmail" tabindex="1" placeholder="Correo electrónico" aria-label="Correo electrónico" required
                                                disabled />
                                        </div>

                                        <div class="js-form-message form-group">
                                            <label class="input-label" for="signinSrPassword" tabindex="0">
                                                <span class="d-flex justify-content-between align-items-center">
                                                    Contraseña
                                                </span>
                                            </label>
                                            <input type="password" class="form-control" name="password" id="signinSrPassword" tabindex="2" placeholder="********" aria-label="********" required
                                                disabled />
                                        </div>

                                        <div class="row align-items-center mb-5">
                                            <div class="col-sm-6 mb-3 mb-sm-0">
                                            </div>

                                            <div class="col-sm-6 text-sm-right">
                                                <button type="submit" class="disabled btn btn-primary transition-3d-hover">Iniciar Sesión</button>
                                            </div>
                                        </div>
                                        {/* <!-- Button -->*/}
                                        <div className="row align-items-center ">
                                            <div className="col-lg-12">
                                                <button type="button" className="facebook-button" onClick={this.handleFacebookSignIn}>
                                                    <span className="facebook-button__icon">
                                                        <i className="fab fa-facebook" ></i>
                                                    </span>
                                                    <span className="facebook-button__text">Entrar con Facebook</span>
                                                </button>
                                            </div>
                                        </div>
                                        {/* <!-- End Button -->*/}

                                        {/* <!-- Button -->*/}
                                        <div className="row align-items-center">
                                            <div className="col-lg-12">
                                                <button type="button" className="google-button" onClick={this.handleGoogleSignIn}>
                                                    <span className="google-button__icon">
                                                        <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335" /><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05" /><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4" /><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" /></svg>
                                                    </span>
                                                    <span className="google-button__text">Entrar con Google</span>
                                                </button>
                                            </div>
                                        </div>
                                        {/* <!-- End Button -->*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default Login;