
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { getUserData, signOutItmex } from '../../auth/auth.utils'
import { auth, createUserProfileDocument, getMisCursosRef } from '../../firebase/firebase.utils'

class UserSideMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUserData(),
            currentUser: {},
            loading: true,
        }
    }
    unsubscribeFromAuth = null;
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Mis Cursos';
        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                userRef.onSnapshot(snapShot => {
                    //console.log(snapShot.id)
                    getMisCursosRef(snapShot.id).then((arrayMisCursos) =>{
                        
                        this.setState({
                            currentUser: {
                                id: snapShot.id,
                                numCursos: arrayMisCursos.length,
                                ...snapShot.data()
                            },
                            loading: false
                        });
                    })
                })
            }
        })
    }
    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }
    salir = e => {
        e.preventDefault();
        signOutItmex(() => {
            this.props.history.push('/login');
        });
    }
    render() {
        return (

            <div className="col-lg-3">

                <div className="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light" >

                    <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical" >
                        <div className="card mb-5" >
                            <div className="loaderMin" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                                <div className="Box"></div>
                            </div>
                            <div className="card-body" style={{ display: this.state.loading ? 'none' : 'block' }}>
                                <div className="d-none d-lg-block text-center mb-5">
                                    <div className="avatar avatar-xxl avatar-circle mb-3">
                                        <img className="avatar-img" src={this.state.currentUser.photoURL} alt="Nombre de Usuario" />
                                        <img className="avatar-status avatar-lg-status" src="/assets/svg/illustrations/top-vendor.svg" alt="Image Description" data-toggle="tooltip" data-placement="top" title="Verified user" />
                                    </div>

                                    <h4 className="card-title">{this.state.currentUser.displayName}</h4>
                                    <p className="card-text font-size-1">{this.state.currentUser.email}</p>
                                    <hr />
                                    <p><i className="fas fa-donate PrimerLugar"></i> {new Intl.NumberFormat().format(this.state.currentUser.puntuacion)} - Monedas Itmex</p>
                                </div>

                                <h6 className="text-cap small">Mi cuenta</h6>

                                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={`/perfil/`}>
                                            <i className="fas fa-id-card nav-icon"></i>
                                                                Perfil
                                                            </Link>
                                    </li>
                                </ul>

                                <h6 className="text-cap small">Academia</h6>

                                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={`/perfil/miscursos/`}>
                                            <i className="fas fa-th-large nav-icon"></i>
                                                Mis cursos
                                                <span className="badge badge-soft-navy badge-pill nav-link-badge">{this.state.currentUser.numCursos}</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={`/perfil/codigo/`}>
                                            <i className="fas fa-laptop-code nav-icon"></i>
                                                Canjear código
                                            </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={`/perfil/recompensas/`}>
                                            <i className="fas fa-gifts  nav-icon"></i>
                                                Recompensas
                                            </Link>
                                    </li>
                                </ul>

                                <h6 className="text-cap small">Internet Itmex</h6>

                                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                                    <li className="nav-item">
                                        <i className="fas fa-tools nav-icon"></i>
                                            Próximamente
                                        </li>
                                </ul>

                                <div className="d-lg-none">
                                    <div className="dropdown-divider"></div>

                                    <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                                        <li className="nav-item">
                                            <a onClick={this.salir} className="nav-link text-primary" style={{ cursor: 'pointer' }}>
                                                <i className="fas fa-sign-out-alt nav-icon"></i>
                                                        Cerrar Sesión
                                                    </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default withRouter(UserSideMenu);