import React from 'react';
import Layout from '../core/Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'

class C1Septiembre2020 extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Maestros Septiembre 2020';
    }
    render() {
        return (
            <div>
                <Layout title="Red Itmex - Maestros Septiembre 2020">
                    <div class="container space-top-3 space-top-lg-3 space-bottom-1 space-bottom-md-1">
                        <div class="w-lg-60 mx-lg-auto">
                            <div class="mb-4">
                                <h1 class="h2">¡Gracias maestr@s y alumn@s!</h1>
                            </div>

                            <div class="border-top border-bottom py-4 mb-5">
                                <div class="row align-items-md-center">
                                    <div class="col-md-7 mb-5 mb-md-0">
                                        <div class="media align-items-center">
                                            <div class="avatar avatar-circle">
                                                <img class="avatar-img" src="/assets/img/logos/logominico.png" alt="Image Description" />
                                            </div>
                                            <div class="media-body font-size-1 ml-3">
                                                <span class="h6"><b>Red Itmex</b></span>
                                                <span class="d-block text-muted">Septiembre 07, 2020</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Sabemos que es difícil quedarse en casa pero queremos darte las gracias por ayudar tanto, gracias porque quedándote en casa estás protegiendo a otras personas del virus, gracias por ser tan valientes y porque haces que todo lo seamos. Hoy queremos demostrar que aunque no estábamos preparados, tú como alumno o maestro te estás adaptando para seguir con el compromiso de aprender o enseñar y queremos mostrarlo a nuestra comunidad.</p>
                            <p>Toma una foto en donde se vea cómo estás recibiendo o dando clases con internet, muestra que estamos siendo fuertes en estos momentos y que un virus no va a parar el compromiso de aprender.</p>
                        </div>
                        <div class="my-4 my-sm-8">
                            <img class="img-fluid rounded" src="/assets/img/concursos/1920x800/c1c2.jpg" alt="Concurso Septiembre 2020" />
                        </div>
                        <div class="w-lg-60 mx-lg-auto">
                            <p>Toma la foto más <b>creativa</b> que represente la nueva normalidad con clases a distancia.
                            Manda por WhatsApp o <a href="https://www.facebook.com/itmexoficial">Facebook</a> la foto con el nombre al que está registrado tu servicio o número de cliente.
                            Espera la confirmación de nuestro equipo confirmando tu participación al concurso</p>

                            <h3>Premios</h3>
                            <ul class="list-article">
                                <li>El primer lugar recibe 3 meses de internet gratis</li>
                                <li>Segundo lugar 3 meses al doble de velocidad.</li>
                                <li>Tercer lugar recibe 50% de descuento en su mensualidad.</li>
                            </ul>
                            <p>Todos los participantes tendrán un 5% de descuento en su siguiente pago.</p>
                            <p>Puedes mandar tu foto desde el 7 al 12 de septiembre. Las fotos de los ganadores serán mostradas el 14 de septiembre en Facebook y WhatsApp.</p>
                            <h3>Términos</h3>
                            <p>Al terminar los meses del premio el usuario vuelve a su plan que tenía contratado, no es transferible ni acumulable, no puede participar en algún otro concurso mientras esté beneficiado de los premios.</p>
                        </div>

                        <div class="w-lg-60 mx-lg-auto">
                            <img class="img-fluid rounded" src="/assets/img/concursos/flyer.png" alt="Concurso Septiembre 2020" />
                        </div>
                        <div class="w-lg-60 mx-lg-auto">
                            <div class="media align-items-center border-top border-bottom py-5 my-8">
                                <div class="avatar avatar-circle avatar-xl">
                                    <img class="avatar-img" src="/assets/img/logos/logominico.png" alt="Red Itmex" />
                                </div>
                                <div class="media-body ml-3">
                                    <small class="d-block small font-weight-bold text-cap">Escrito por</small>
                                    <h3 class="mb-0"><Link to="/nosotros">Red Itmex</Link></h3>
                                    <p class="mb-0">Estamos en la misión de proveer servicio de internet a las zonas más remotas, acercar y enseñar tecnologías de vanguardia a las personas que construyen el futuro de nuestras comunidades.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div >
        )
    }
};
export default C1Septiembre2020;