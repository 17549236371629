import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faGavel, faServer, faUser } from '@fortawesome/free-solid-svg-icons'
import {  faCircle } from '@fortawesome/free-regular-svg-icons'


class Documentacion extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentación';
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Documentación">

                    <main id="content" role="main">
                        <div class=" faqBg">
                            <div class="container space-top-1 space-top-lg-2 textWhite space-bottom-2 position-relative z-index-2">
                                <div class="w-md-80 w-lg-60 text-center mx-md-auto">
                                    <h1 className="textWhite">Documentación</h1>
                                </div>
                            </div>
                        </div>
                        <div class="container space-2 space-bottom-lg-3">
                            <div class="row justify-content-lg-center">
                                <div class="col-lg-10">
                                    <div id="basics" class="space-bottom-1">
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-4 mb-4">
                                                <div class="card w-100" >
                                                    <div class="card-body text-center">
                                                        <h1>
                                                            <span className="fa-layers fa-fw fa-2x">
                                                                <FontAwesomeIcon icon={faCircle}/>
                                                                <FontAwesomeIcon icon={faGavel} transform={'shrink-8'} />
                                                            </span>
                                                        </h1>
                                                        <h3 class="m-4">Legal</h3>
                                                        <Link to="/documentacion/legal/" class="btn btn-sm btn-primary w-100">Ir a Documentación</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-4 mb-4">
                                                <div class="card w-100" >
                                                    <div class="card-body text-center">
                                                        <h1>
                                                            <span className="fa-layers fa-fw fa-2x">
                                                                <FontAwesomeIcon icon={faCircle}/>
                                                                <FontAwesomeIcon icon={faUser} transform={'shrink-8'} />
                                                            </span>
                                                        </h1>
                                                        <h3 class="m-4">Usuario</h3>
                                                        <Link to="/documentacion/usuario/" class="btn btn-sm btn-primary w-100">Ir a Documentación</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="col-sm-12 col-lg-4 mb-4">
                                                <div class="card w-100" >
                                                    <div class="card-body text-center">
                                                        <h1>
                                                            <span className="fa-layers fa-fw fa-2x">
                                                                <FontAwesomeIcon icon={faCircle}/>
                                                                <FontAwesomeIcon icon={faServer} transform={'shrink-8'} />
                                                            </span>
                                                        </h1>
                                                        <h3 class="m-4">Fibra Óptica</h3>
                                                        <Link to="/documentacion/fiber/" class="btn btn-sm btn-primary w-100">Ir a Documentación</Link>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
                        </div>
        )
    }
}

export default Documentacion;