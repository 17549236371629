import React from 'react';
import { withRouter } from 'react-router-dom';
import Layout from './Layout';
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';

class Privacidad extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Política de Privacidad';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Política de Privacidad">
                    <div className="w-lg-80 mx-lg-auto">
                        <div className="card">
                            <div className="card-header position-relative bg-primary space-1 space-md-2 p-4 px-md-7 px-md-9">
                                <h1 className="text-white">Política de Privacidad</h1>
                                <p className="text-white-70">Última modificación: Julio 18, 2020</p>

                                <figure className="position-absolute right-0 bottom-0 left-0 mb-n1">
                                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1" width="100%" height="32">
                                        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                                    </svg>
                                </figure>
                            </div>

                            <div className="card-body p-4 p-md-9">
                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h2 className="h3">Introducción</h2>
                                    </div>

                                    <p>El siguiente es un documento legal que explica como cuidamos tus datos cuando contratas con nosotros. Gracias por tu confianza. Si algún concepto requiere más explicación, no dudes en escribirnos un correo a <a href="mailto:asistencia@reditmex.com">asistencia@reditmex.com</a>.</p>
                                </div>

                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Datos del Responsable</h3>
                                    </div>

                                    <p>Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que Red Itmex (“Itmex”) con domicilio para oír todo tipo de notificaciones el ubicado en Av. 5 de Mayo No.1, Colonia Centro, C.P. 42300, municipio de Ixmiquilpan en el estado de Hidalgo, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.</p>
                                    <p>Itmex no recopila información personal de los usuarios excepto cuando éstos dan dicha información voluntariamente al contratar o modificar sus servicios, o bien, registrándose dentro del Sitio o enviando correos electrónicos o cualquier otra comunicación al o a través del Sitio.</p>
                                    <p>La confidencialidad y efectiva protección de dicha información es primordial para Itmex, quien mantendrá una protección adecuada, de conformidad con los principios y obligaciones previstos por la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP) y demás normatividad aplicable, a efecto de asegurar la seguridad, integridad y privacidad de la información de los usuarios compilada en el Sitio y/o en los Contratos.</p>
                                </div>

                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Datos Personales</h3>
                                    </div>
                                    <p>La información que se solicita en cualquier documento relacionado con la contratación y prestación de los servicios es la siguiente:</p>
                                    <ol type="a"><li>Nombre y apellidos.</li><li>Dirección completa.</li><li>Datos fiscales.</li><li>Teléfono particular y/o oficina.</li><li>Teléfono celular.</li><li>Correo electrónico.</li><li>INE/IFE.</li><li>CURP.</li><li>Dirección IP.</li><li>Perfil social de Facebook, Twitter y/o Instagram.</li></ol>
                                </div>

                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Finalidad del Tratamiento de Datos</h3>
                                    </div>
                                    <p>Sus datos personales podrán ser utilizados para las siguientes finalidades:</p>
                                    <ol type="a"><li>Contrataciones: altas, renovaciones y bajas.</li><li>Consultas, investigaciones y revisiones en relación con sus quejas o reclamaciones. </li><li>Realizar investigaciones de mercado, encuestas de satisfacción o cualquier otra actividad con fines promocionales o de satisfacción por parte de Itmex.</li></ol>                                
                                </div>

                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Transferencia de Datos</h3>
                                    </div>
                                    <p>Para el cumplimiento de su objeto comercial, Itmex podrá compartir la información personal con sus socios comerciales, a efecto de dar cumplimiento con la relación jurídica existente entre el usuario e Itmex.</p>
                                    <ol type="a"><li>Itmex no transferirá a terceros su información personal salvo su previo consentimiento cuando éste sea requerido legalmente.</li><li>Itmex se compromete a respetar en todo momento, y a que nuestros socios comerciales respeten la presente Política de Privacidad.</li><li>Itmex tratará sus datos personales por el período de tiempo establecido en la LFPDPPP y las disposiciones legales que resulten aplicables.</li></ol>
                                    <p>Una vez concluido dicho período de tiempo, se bloqueará su tratamiento y se eliminará de conformidad con lo señalado en dichos ordenamientos. Por otro lado, le garantizamos que todos los contratos de prestación de servicios de terceros que impliquen el manejo de su información personal a nombre y por cuenta de Itmex incluirán una cláusula de que los mismos otorgan el nivel de protección de datos personales previsto por la LFPDPPP.</p>
                                </div>
                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Ejercicio de Derechos ARCO</h3>
                                    </div>
                                    <p>Con apego a lo previsto por la LFPDPPP, usted, o si lo tuviere, su representante legal debidamente acreditado, podrá ejercitar sus derechos de acceso, rectificación, cancelación y oposición poniéndose en contacto con el Oficial de Privacidad.</p>
                                    <p>Sus derechos consisten en lo siguiente:</p>
                                    <p><b>Acceso</b>.- Implica poder conocer en todo momento sus datos personales en posesión del Concesionario, así como conocer la Política de Privacidad correspondiente.</p>
                                    <p><b>Rectificación</b>.- Si alguno de sus datos es inexacto o incompleto, usted podrá solicitar su modificación, adjuntando la documentación que acredite dicha corrección.</p>
                                    <p><b>Cancelación</b>.- Usted podrá requerir cuando así lo considere, la cancelación de sus datos; en caso de la misma sea procedente, su información personal entrará en un período de bloqueo para proceder posteriormente a su eliminación. Al entrar a dicho periodo, su información ya no podrá ser tratada por Itmex.</p>
                                    <p><b>Oposición</b>.- Usted podrá en todo momento y por causa legítima objetar el tratamiento de sus datos personales. Si su solicitud resulta procedente, Itmex ya no podrá tratar los mismos. En caso de que usted desee limitar el uso o divulgación de sus datos personales, le agradeceremos enviar un correo electrónico a <a href="mailto:asistencia@reditmex.com">asistencia@reditmex.com</a> solicitando cualquiera que sea la limitación que imponer sobre sus datos. En un lapso menos a 24 horas recibirá la confirmación correspondiente a dicho cambio.</p>
                                </div>
                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Cambios a las Políticas de Privacidad</h3>
                                    </div>
                                    <p>Cualquier modificación a este política de privacidad le será notificada y estarán disponibles al público a través de anuncios visibles en nuestros establecimientos o centros de atención a clientes; trípticos o folletos disponibles en nuestros establecimientos o centros de atención a clientes; en nuestra página de Internet; o se las haremos llegar al último correo electrónico que nos haya proporcionado. Usted podrá oponerse a los nuevos términos, previa modificación al tratamiento u obtención de su información, a través de la solicitud correspondiente al Oficial de Privacidad <a href="mailto:asistencia@reditmex.com">asistencia@reditmex.com.</a></p>
                                </div>
                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Uso de Cookies</h3>
                                    </div>
                                    <p>Este Sitio podrá usar "cookies" con relación a ciertas funciones o características. Las "cookies" son tipos específicos de información que un sitio web transfiere al disco duro de la computadora del usuario con el propósito de guardar ciertos registros. Las "cookies" hacen que el uso de un sitio web sea más fácil con funciones tales como, guardar contraseñas y preferencias mientras un visitante se encuentre en el Sitio.</p>
                                    <p>Itmex o el Sitio no usa "cookies" para obtener información personal de la computadora de los usuarios que no haya sido originalmente enviada en el "cookie". Mientras la mayoría de los buscadores aceptan "cookies" automáticamente, usted puede predeterminar su buscador para que rechace "cookies". Es importante hacer notar que el Sitio puede llegar a permitir publicidad o funciones de otras compañías que envíen "cookies" a su computadora. No Itmex ni el Sitio controlan, de manera alguna, la manera de uso de "cookies" de otras compañías.</p>
                                </div>
                                <div className="mb-7">
                                    <div className="mb-3">
                                        <h3>Aceptación de los Términos</h3>
                                    </div>
                                    <p>Usted acepta que ha leído los términos antes expuestos y acepta que utilicemos sus datos para los fines señalados en el presente documento. Si usted no está de acuerdo con los términos antes expuestos, deberá abstenerse de proporcionarnos sus datos personales.</p>
                                    <p>Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de nuestras acciones o respuestas, o en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el IFAI, para mayor información visite <a href="www.ifai.org.mx" target="_blank" rel="noopener noreferrer">www.ifai.org.mx.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        )
    }
};
export default withRouter(Privacidad);