import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class CartaDeDerechosMinimosDeLosUsuarios_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Carta de Derechos Mínimos de los Usuarios';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_CARTA_DE_DERECHOS_MINIMOS_DE_LOS_USUARIOS_2023_V1.pdf?alt=media&token=dd907c62-63f8-44cf-a6ab-196a1e284953';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default CartaDeDerechosMinimosDeLosUsuarios_pdf