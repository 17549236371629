import React, { Fragment } from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { auth, getCursosRef, getCoberturasRef, getMunicipiosZonasRef, getComunidadesRef, getComunidadesDisponiblesRef } from '../firebase/firebase.utils'
import { Helmet } from 'react-helmet'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import Ads from './Ads';
class Cobertura extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            estados: [],
            loading: true
        }
    }
    random_rgba = () => {
        var o = Math.round, r = Math.random, s = 185;
        return 'rgba(' + o(r() * 50) + ',' + 0 + ',' + o(r() * s) + 95 + ',' + .5 + ')';
    }
    obtenerComunidades = async () => {
        const coberturasRef = await getCoberturasRef();
        const estados = []
        await coberturasRef.get().then(qSnapshot => {
            qSnapshot.forEach(async estado => {
                const municipiosZonas = []
                const municipiosZonasRef = getMunicipiosZonasRef(estado.id)
                await municipiosZonasRef.get().then(q2Snapshot => {
                    q2Snapshot.forEach(async municipio => {
                        const comunidades = [];
                        const comunidadesRef = getComunidadesDisponiblesRef(estado.id, municipio.id)
                        await comunidadesRef.get().then(q2Snapshot => {
                            q2Snapshot.forEach(async comunidad => {
                                var comunidadRawData = comunidad.data()
                                comunidadRawData.id = comunidad.id
                                comunidades.push(comunidadRawData)
                            })
                            var municipioRawData = municipio.data();
                            municipioRawData.id = municipio.id;
                            municipioRawData.comunidades = comunidades
                            municipiosZonas.push(municipioRawData)
                        })
                        this.setState({ estados: estados, loading: false })
                    })
                    var estadosRawData = estado.data();
                    estadosRawData.id = estado.id;
                    estadosRawData.municipioszonas = municipiosZonas
                    estados.push(estadosRawData)
                })

            })
        })
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Cobertura';
        this.obtenerComunidades()
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Cobertura">
                    <Helmet>
                        <meta name="description" content="Servicio de Internet en Ixmiquilpan, Cardonal, Tasquillo y Actopan. Descubre la cobertura con la que contamos actualmente. Estamos abriendo nuevas sucursales, si no ves tu comunidad aquí, pregunta por la disponibilidad." />
                        <meta name="robots" content="index" />
                        <meta property="og:url" content="https://www.reditmex.com/cobertura" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Red Itmex - Cobertura" />
                        <meta property="og:description" content="Servicio de Internet en Ixmiquilpan, Cardonal, Tasquillo y Actopan. Descubre la cobertura con la que contamos actualmente. Estamos abriendo nuevas sucursales, si no ves tu comunidad aquí, pregunta por la disponibilidad." />
                        <meta property="og:image" content="https://www.reditmex.com/assets/img/FBThumbnail01.jpg" />
                        <meta property="fb:app_id" content="418170456085047" />
                    </Helmet>
                    
                    <div className="loader" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                        <div className="Box"></div>
                    </div>
                    <main id="content" role="main" style={{ display: this.state.loading ? 'none' : 'block' }}>
                        <div id="SVGHero" className="position-relative bg-img-hero space-top-2" style={{ backgroundImage: 'url(/assets/img/fotos/ixmf4.jpg)', backgroundPosition: 'center' }}>
                            <div className="container space-top-2 space-bottom-3 space-md-3 space-bottom-lg-4">
                                <div className="w-md-65 w-lg-50">
                                    <h1 className="text-white">Gracias a ti</h1>
                                    <h1 className=" text-white font-weight-medium">¡Estamos llegando a nuevos lugares!</h1>
                                </div>
                            </div>
                            {/* <!-- SVG Background -->*/}
                            <figure className="position-absolute right-0 bottom-0 left-0">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" width="100%" height="85px" viewBox="0 0 1920 107.7" style={{ marginBottom: '-8px', enableBackground: 'new 0 0 1920 107.7' }} xmlSpace="preserve" className="injected-svg js-svg-injector" data-parent="#SVGHero">
                                    <style type="text/css">
                                    </style>
                                    <path className="wave-9-bottom-0 fill-white" d="M0,107.7V69.8c0,0,451-54.7,960-5.4S1920,0,1920,0v107.7H0z"></path>
                                    <path className="wave-9-bottom-1 fill-white" opacity=".3" d="M0,107.7v-81c0,0,316.2-8.9,646.1,54.5s794.7-114.1,1273.9-38v64.5H0z"></path>
                                </svg>
                            </figure>
                            {/* <!-- End SVG Background -->*/}
                        </div>
                        <div className="container">
                            <center><span className="btn btn-xs btn-soft-success btn-pill m-4">Tenemos presencia en</span></center>

                            <div className="space-2 space-bottom-lg-3">
                                {this.state.estados == [] ?
                                    <div>
                                    </div>
                                    :
                                    <Fragment>
                                        {this.state.estados.map((estado, idx) => (
                                            <div className="row justify-content-lg-center" key={idx}>
                                                <div className="col-lg-8">
                                                    <div id="basics" className="space-bottom-1">
                                                        <h2>{estado.estado}</h2>
                                                        {estado.municipioszonas == [] ?
                                                            <div>
                                                            </div>
                                                            :
                                                            <Fragment>
                                                                {estado.municipioszonas.map((municipioszonas, idx) => (
                                                                    <div id={"A" + municipioszonas.id + "Acordion"} key={idx}>
                                                                        <div className="card shadow-none mb-3">
                                                                            <div className="card-header card-collapse" id={"A" + municipioszonas.id}>
                                                                                <a className="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="" role="button" data-toggle="collapse" data-target={"#A" + municipioszonas.id + "C"} aria-expanded="false" aria-controls={"A" + municipioszonas.id + "C"}>
                                                                                    {municipioszonas.nombreZona}
                                                                                    <span className="card-btn-toggle">
                                                                                        <span className="card-btn-toggle-default"><i className="fas fa-plus"></i></span>
                                                                                        <span className="card-btn-toggle-active"><i className="fas fa-minus"></i></span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                            <div id={"A" + municipioszonas.id + "C"} className="collapse" aria-labelledby={"A" + municipioszonas.id} data-parent={"#A" + municipioszonas.id + "Acordion"}>

                                                                                {municipioszonas.comunidades == [] ?
                                                                                    <div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="card-body px-0">
                                                                                        <div className="row mb-1">
                                                                                            {municipioszonas.comunidades.map((comunidad, idx) => (
                                                                                                <Fragment key={idx}>
                                                                                                    <div className="col-3 col-lg-2 mb-1"><div style={{ width: '70px', height: '70px', backgroundColor: this.random_rgba() }}></div></div>
                                                                                                    <div className="col-8 col-lg-10 mb-1 align-self-center">{comunidad.nombre} {comunidad.nuevo ? <span className="badge badge-warning ml-1 text-white">Nuevo</span> : <span></span>} {comunidad.wireless ? <span className="badge badge-dark ml-1 text-white"><span className="fas fa-satellite-dish ml-1"></span> Wireless</span> : <span></span>} {comunidad.fibra ? <span className="badge badge-dark ml-1 text-white"><span className="fas fa-wifi ml-1"></span> Fibra Óptica</span> : <span></span>}</div>
                                                                                                </Fragment>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Fragment>
                                }
                                <div className="position-relative z-index-2 text-center">
                                    <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
                                        ¿No encuentras tu comunidad? Podemos llevarte internet <Link className="d-block d-sm-inline-block font-weight-bold ml-sm-3" to="/contacto">Contáctanos <span className="fas fa-angle-right ml-1"></span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    
                    {/* <section className="row m-md-3 mt-3 mb-3">
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                    </section> */}
                </Layout>
            </div>
        )
    }
};
export default Cobertura;