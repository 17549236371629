import React from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import Ads from './Ads';

class Home extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div>
                <Layout title="Red Itmex - Inicio">
                    <Helmet>
                        <meta name="description" content="Somos una empresa orgullosamente hidalguense que provee servicios integrales de Internet, Telecomunicaciones y Tecnologías de Información. Contamos con más de 150 kilómetros de enlaces de red que llegan a localidades remotas de Hidalgo." />
                        <meta name="robots" content="index" />
                        <meta property="og:url" content="https://www.reditmex.com/" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Red Itmex" />
                        <meta property="og:description" content="Proveedores de Servicios de Internet en Hidalgo" />
                        <meta property="og:image" content="https://www.reditmex.com/assets/img/FBThumbnail01.jpg" />
                        <meta property="fb:app_id" content="418170456085047" />
                    </Helmet>
                    <section className="row m-md-3 mr-0 ml-0" >
                        <div className="col-12 mb-2">
                            <div className="position-relative overflow-hidden  bg-dark text-white" style={{ display: 'flex', height: 'auto', minHeight: '100vh', width: '100%', backgroundColor: 'black', borderRadius: '30px', justifyContent: 'center'}}>
                                <div className="titleBanner  ">
                                    <div className="row align-items-center text-center" style={{ height: '100%', width: "100%", margin: '0' }}>
                                        <div className="col-12 p-0">
                                            <h1 className="textWhite d-none d-md-block" style={{ margin: '0px', fontSize: '6rem' }}>Todo sobre</h1>
                                            <h1 className="textWhite mb-3 d-none d-md-block" style={{ margin: '0px', marginTop: '-30px', fontSize: '6rem' }}>Internet</h1>
                                            <h1 className="textWhite mb-3 d-md-none" style={{ marginBottom: '0px', fontSize: '3rem' }}>Todo sobre Internet</h1>
                                            <div style={{position: 'relative', marginTop: '150px'}}>
                                                <h3 className="textWhite mb-3 " >Bienvenid@ a la <b>Red Itmex</b></h3>
                                                <h1 className="textWhite mt-3" style={{fontSize: '3rem'}}>
                                                    <a className="textWhite" target="_blank" href="https://www.facebook.com/itmexoficial"><i className="fab fa-facebook-square m-4"></i> </a>
                                                    <a className="textWhite" target="_blank" href="https://www.instagram.com/reditmex/"><i className="fab fa-instagram m-4"></i> </a>
                                                    <a className="textWhite" target="_blank" href="https://www.youtube.com/channel/UC6Sirelw0bYpaT4VktsK19A"><i className="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="textWhite" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i className="fab fa-spotify m-4"></i>  </a>
                                                    <a className="textWhite" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i className="fas fa-podcast m-4"></i>  </a>
                                                    <a className="textWhite" href="https://wa.me/5217712438160?text=Hola%2C+%C2%BFme+podr%C3%ADas+brindar+informaci%C3%B3n+del+servicio+de+internet%3F+%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB" target="_blank" ><i className="fab fa-whatsapp-square m-4"></i> </a>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <hr style={{border: '1px solid rgb(100,100,100,.1)'}}/>
                    <section className='w-100' id="planesInternet" style={{overflow: 'hidden'}}>
                        <div className="row m-md-3  mr-0 ml-0 align-items-center text-center" data-aos="fade-up" data-aos-offset="200">
                            <div className='col ' style={{padding: '100px 0px'}}>
                                <h3 className="" style={{ margin: '0px', fontSize: '4rem', fontWeight: '400' }}>Fibra Óptica es mayor velocidad,<br/> a un mejor precio.</h3>
                            </div>
                        </div>
                        <div  className="row m-md-3  mr-0 ml-0 align-items-center" >
                            {/* Pricing Section */}
                            <div className="col position-relative ">
                                <div className="container">
                                    <div className="space-bottom-1 space-bottom-md-1">
                                        <div className="">
                                            <div className="">
                                                <div className="row position-relative z-index-2 mb-5" >

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3"  data-aos="fade-right" data-aos-offset="200">
                                                        <div className="card h-100">
                                                            <div className="card-header text-center">
                                                                <span className="d-block h3">Básico+</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-dark align-top">$</span>
                                                                    <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                        <span id="pricingCount1">300</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Fibra Óptica
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        100 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        25 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1347531
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3"  data-aos="fade-down" data-aos-offset="200">
                                                        <div className="card bg-primaryFibra text-white h-100 shadow-primary-lg">
                                                            <div className="card-header border-0 bg-primaryFibra text-white text-center">
                                                                <span className="d-block h3 text-white">Premium+</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-white align-top">$</span>
                                                                    <span className="font-size-3 text-white font-weight-bold mr-n2">
                                                                        <span id="pricingCount2">400</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="border-top opacity-xs"></div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Fibra Óptica
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        200 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        50 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1347531
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3" data-aos="fade-up" data-aos-offset="200">
                                                        <div className="card h-100">
                                                            <div className="card-header text-center">
                                                                <span className="d-block h3">Gold+</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-dark align-top">$</span>
                                                                    <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                        <span id="pricingCount3">500</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Fibra Óptica
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        300 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        100 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1347543
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3" data-aos="fade-left" data-aos-offset="200">
                                                        <div className="card h-100">
                                                            <div className="card-header text-center">
                                                                <span className="d-block h3">Gamer+</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-dark align-top">$</span>
                                                                    <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                        <span id="pricingCount4">700</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Fibra Óptica
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        600 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        200 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1347545
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-6 offset-md-3 px-2 mb-3"  data-aos="fade" data-aos-offset="200">
                                                        <div className="card bg-dark text-white h-100 shadow-dark-lg">
                                                            <div className="card-header border-0 bg-dark text-white text-center">
                                                                <span className="d-block h3 text-white">Empresarial+</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-white align-top">$</span>
                                                                    <span className="font-size-3 text-white font-weight-bold mr-n2">
                                                                        <span id="pricingCount2">1000</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="border-top opacity-xs"></div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Fibra Óptica
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        1000 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        500 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1347559
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="position-relative z-index-2 text-center">
                                                    <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
                                                        ¿Te interesa alguno de nuestros planes? <Link className="d-block d-sm-inline-block font-weight-bold ml-sm-3" to="/contacto">Contáctanos <span className="fas fa-angle-right ml-1"></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="space-top-2"><i className="far fa-file-alt"></i>  El costo de instalación es de $600.00 MXN con contrato a 12 meses. La instalación sin contrato es por $1,500.00 MXN. El equipo se instala en comodato (prestado). Revisa nuestras promociones.</p>
                                    <p className=""><i className="fas fa-map-marker-alt"></i>  Revisa nuestra cobertura de red fibra óptica y wireless <Link to="/cobertura">aquí</Link>.</p>
                                    <p className=""><i className="far fa-question-circle"></i>  Puedes revisar nuestro apartado de preguntas frecuentes <Link to="/internet/faq">aquí</Link>.</p>
                                    <p className=""><i className="far fa-question-circle"></i>  Las tarifas se encuentran inscritas en el Registro Público de Concesiones del Instituto Federal de Telecomunicaciones y podrán consultarse en el hipervínculo correspondiente al Visor de Tarifas del Registro Público de Telecomunicaciones de ese Instituto: <a href="https://tarifas.ift.org.mx/ift_visor/" target='_blank'>aquí</a>.</p>
                                </div>

                            </div>
                            {/* End Pricing Section */}
                        </div>
                    </section>
                    <section className='bg-primary position-relative' data-aos="fade-up" data-aos-offset="200" style={{padding: '100px 0px', overflow: 'hidden'}}>
                        {/* SVG Background */}
                        <figure className="position-absolute wavesTop" >
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" width="100%" height="140px" viewBox="0 0 300 100" style={{ marginBottom: '-8px', enableBackground: 'new 0 0 300 100' }} xmlSpace="preserve" className="injected-svg js-svg-injector" data-parent="#SVGwave1BottomSMShape">

                                <g>
                                    <defs>
                                        <rect id="waveBottom1SMID1" width="300" height="100"></rect>
                                    </defs>
                                    <clipPath id="waveBottom1SMID2">
                                        <use xlinkHref="#waveBottom1SMID1" style={{ overflow: 'visible' }}></use>
                                    </clipPath>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" clipPath="url(#waveBottom1SMID2)" d="M10.9,63.9c0,0,42.9-34.5,87.5-14.2c77.3,35.1,113.3-2,146.6-4.7C293.7,41,315,61.2,315,61.2v54.4H10.9V63.9z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" clipPath="url(#waveBottom1SMID2)" d="M-55.7,64.6c0,0,42.9-34.5,87.5-14.2c77.3,35.1,113.3-2,146.6-4.7c48.7-4.1,69.9,16.2,69.9,16.2v54.4H-55.7     V64.6z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" fillOpacity="0" clipPath="url(#waveBottom1SMID2)" d="M23.4,118.3c0,0,48.3-68.9,109.1-68.9c65.9,0,98,67.9,98,67.9v3.7H22.4L23.4,118.3z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" clipPath="url(#waveBottom1SMID2)" d="M-54.7,83c0,0,56-45.7,120.3-27.8c81.8,22.7,111.4,6.2,146.6-4.7c53.1-16.4,104,36.9,104,36.9l1.3,36.7l-372-3     L-54.7,83z"></path>
                                </g>
                            </svg>
                        </figure>
                        {/* End SVG Background */}
                        {/* SVG Background */}
                        <figure className="position-absolute wavesDown" >
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" width="100%" height="140px" viewBox="0 0 300 100" style={{ marginBottom: '-8px', enableBackground: 'new 0 0 300 100' }} xmlSpace="preserve" className="injected-svg js-svg-injector" data-parent="#SVGwave1BottomSMShape">

                                <g>
                                    <defs>
                                        <rect id="waveBottom1SMID1" width="300" height="100"></rect>
                                    </defs>
                                    <clipPath id="waveBottom1SMID2">
                                        <use xlinkHref="#waveBottom1SMID1" style={{ overflow: 'visible' }}></use>
                                    </clipPath>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" clipPath="url(#waveBottom1SMID2)" d="M10.9,63.9c0,0,42.9-34.5,87.5-14.2c77.3,35.1,113.3-2,146.6-4.7C293.7,41,315,61.2,315,61.2v54.4H10.9V63.9z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" clipPath="url(#waveBottom1SMID2)" d="M-55.7,64.6c0,0,42.9-34.5,87.5-14.2c77.3,35.1,113.3-2,146.6-4.7c48.7-4.1,69.9,16.2,69.9,16.2v54.4H-55.7     V64.6z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" opacity=".4" fillOpacity="0" clipPath="url(#waveBottom1SMID2)" d="M23.4,118.3c0,0,48.3-68.9,109.1-68.9c65.9,0,98,67.9,98,67.9v3.7H22.4L23.4,118.3z"></path>
                                    <path className="wave-bottom-1-sm-0 fill-white" clipPath="url(#waveBottom1SMID2)" d="M-54.7,83c0,0,56-45.7,120.3-27.8c81.8,22.7,111.4,6.2,146.6-4.7c53.1-16.4,104,36.9,104,36.9l1.3,36.7l-372-3     L-54.7,83z"></path>
                                </g>
                            </svg>
                        </figure>
                        {/* End SVG Background */}
                        
                        
                        <div className="row m-md-3 mr-0 ml-0 align-items-center text-center" data-aos="fade-up" data-aos-offset="200">
                            <div className='col ' style={{padding: '100px 0px'}}>
                                <h3 className="text-white" style={{ margin: '0px', fontSize: '4rem', fontWeight: '400' }}>Con Internet Wireless podemos llegar mas lejos.</h3>
                            </div>
                        </div>
                        <div  className="row m-md-3  mr-0 ml-0 align-items-center" >
                            {/* Pricing Section */}
                            <div className="col position-relative ">
                                <div className="container">
                                    <div className="space-bottom-1 space-bottom-md-1">
                                        <div className="">
                                            <div className="">
                                                <div className="row position-relative z-index-2 mb-5" >

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3"  data-aos="fade-right" data-aos-offset="200" >
                                                        <div className="card h-100">
                                                            <div className="card-header text-center">
                                                                <span className="d-block h3">Básico</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-dark align-top">$</span>
                                                                    <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                        <span id="pricingCount1">300</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        3 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        1 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1345218
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3"  data-aos="fade-down" data-aos-offset="200">
                                                        <div className="card bg-primaryFibra text-white h-100 shadow-primary-lg">
                                                            <div className="card-header border-0 bg-primaryFibra text-white text-center">
                                                                <span className="d-block h3 text-white">Premium</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-white align-top">$</span>
                                                                    <span className="font-size-3 text-white font-weight-bold mr-n2">
                                                                        <span id="pricingCount2">400</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="border-top opacity-xs"></div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        8 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        1.2 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 mb-3">
                                                                    <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1345223
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3"  data-aos="fade-up" data-aos-offset="200">
                                                        <div className="card h-100">
                                                            <div className="card-header text-center">
                                                                <span className="d-block h3">Gold</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-dark align-top">$</span>
                                                                    <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                        <span id="pricingCount3">500</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        10 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        2 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1345224
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-3 px-2 mb-3"  data-aos="fade-left" data-aos-offset="200">
                                                        <div className="card h-100">
                                                            <div className="card-header text-center">
                                                                <span className="d-block h3">Gamer</span>
                                                                <span className="d-block mb-2">
                                                                    <span className="text-dark align-top">$</span>
                                                                    <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                        <span id="pricingCount4">700</span>
                                                                    </span>
                                                                    <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                                </span>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Soporte Técnico
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        15 Mb de Descarga
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        2.5 Mb de Subida
                                                                    </div>
                                                                </div>
                                                                <div className="media font-size-1 text-body mb-3">
                                                                    <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                    <div className="media-body">
                                                                        Folio 1345225
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="position-relative z-index-2 text-center">
                                                    <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
                                                        ¿Te interesa alguno de nuestros planes? <Link className="d-block d-sm-inline-block font-weight-bold ml-sm-3" to="/contacto">Contáctanos <span className="fas fa-angle-right ml-1"></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <p className="space-top-2 text-white"><i className="far fa-file-alt"></i>  El costo de instalación es de $600.00 MXN con contrato a 12 meses. La instalación sin contrato es por $1,500.00 MXN. El equipo se instala en comodato (prestado). Revisa nuestras promociones.</p>
                                    <p className=" text-white"><i className="fas fa-map-marker-alt"></i>  Revisa nuestra cobertura de red fibra óptica y wireless <Link className="text-white font-weight-bold" to="/cobertura">aquí</Link>.</p>
                                    <p className=" text-white"><i className="far fa-question-circle"></i>  Puedes revisar nuestro apartado de preguntas frecuentes <Link className="text-white font-weight-bold" to="/internet/faq">aquí</Link>.</p>
                                    <p className=" text-white"><i className="far fa-question-circle"></i>  Las tarifas se encuentran inscritas en el Registro Público de Concesiones del Instituto Federal de Telecomunicaciones y podrán consultarse en el hipervínculo correspondiente al Visor de Tarifas del Registro Público de Telecomunicaciones de ese Instituto: <a href="https://tarifas.ift.org.mx/ift_visor/" target='_blank'>aquí</a>.</p>


                                </div>

                            </div>
                            {/* End Pricing Section */}
                        </div>
                    </section>
                    <hr style={{border: '1px solid rgb(100,100,100,.1)'}}/>
                    <section className="row m-md-3 mb-3 mr-0 ml-0">
                        <div className="order-lg-2 col-12 col-lg-6 mb-2 mb-sm-6">
                            <article className="card align-items-start flex-wrap flex-row h-380rem bg-navy transition-3d-hover" style={{ backgroundImage: 'url(/assets/img/n4.jpg) ', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                <div className="card-header border-0 bg-transparent w-100">
                                    <a className="small text-white font-weight-bold text-cap mr-2" href="#">YouTube</a>
                                </div>

                                <div className="card-footer border-0 bg-dark-news mt-auto">
                                    <a className="textWhite" target="_blank" href="https://www.youtube.com/channel/UC6Sirelw0bYpaT4VktsK19A">
                                        <h3 className="text-white">¡Red Itmex está en YouTube!</h3>
                                        <span className="text-white-70">Suscríbete y ve <b>GRATIS</b> cursos, podcasts y contenido original <i className="fas fa-angle-right fa-sm ml-1"></i></span>
                                    </a>
                                </div>
                            </article>
                        </div>
                        <div className="order-lg-2 col-12 col-lg-6 mb-2 mb-sm-6 pr-lg-2">
                            <article className="card align-items-start flex-wrap flex-row h-380rem bg-navy transition-3d-hover" style={{ backgroundImage: 'url(/assets/img/n5.jpg) ', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                <div className="card-header border-0 bg-transparent w-100">
                                    <a className="small text-white font-weight-bold text-cap mr-2" href="#">PODCAST</a>
                                </div>

                                <div className="card-footer border-0 bg-dark-news mt-auto">
                                    <Link to="/">
                                        <h3 className="text-white">Historias, retos y aprendizajes</h3>
                                        <span className="text-white-70">Escucha y conoce la historia detrás de las personas que enriquecen nuestra comunidad en <b>Podcast Red Itmex</b> <i className="fas fa-angle-right fa-sm ml-1"></i></span>
                                    </Link>
                                </div>
                            </article>
                        </div>
                    </section>
                </ Layout>
            </div >

        )
    }
};

export default Home;
