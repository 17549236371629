import React from 'react';
export default class Ads extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className="adsbygoogle"
            style={{display:'block', textAlign:'center'}}
            data-ad-layout="in-article"
            data-ad-format="fluid"
            data-ad-client="ca-pub-3485737955427122"
            data-ad-slot="6158652691"></ins>
    );
  }
}