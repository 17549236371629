import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetWireless_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Características, Especificaciones y Alcances de Paquetes de Internet Wireless';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_CARACTERISTICAS_ESPECIFICACIONES_ALCANCE_PAQUETES_INTERNET_WI_2024_V1.pdf?alt=media&token=d6450de0-ebd0-46b2-8642-ee63e5032948';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetWireless_pdf