import React from 'react';
import Layout from './Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';

class E404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            randomPictures: [
                "https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/e404%2F404_1.png?alt=media&token=63315644-3d93-4432-9f9a-9a71eaff3456",
                "https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/e404%2F404_2.png?alt=media&token=d1df51f3-4468-451d-926b-6dcafef97386",
                "https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/e404%2F404_3.png?alt=media&token=39ddc5c6-979b-4b7b-827b-637acab916d8"
            ]
        }
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Contacto">
                    <div className="container " style={{ marginBottom: '30px' }}>
                        <div className="row">
                            <div className="col-lg-6" style={{paddingTop: '20px'}} >
                                <div className="d-none d-md-block" style={{height: '14vh'}}></div>
                                <h1>Oops! &#128540; 404</h1>
                                <h3>Creo que no encontramos lo que estás buscando.</h3>
                            </div>
                            <div className="col-lg-6">
                                <svg viewBox="0 0 550 450" style={{height: 'auto', width: '100%'}} xmlns="http://www.w3.org/2000/svg" id="blobSvg">
                                    <path id="blob" d="M423,355Q371,460,269,427.5Q167,395,136.5,322.5Q106,250,134,174Q162,98,237.5,119Q313,140,394,195Q475,250,423,355Z" fill="#377dff"></path>
                                </svg>
                                <img style={{ width: '100%', position: 'absolute', left: '0', marginTop: '40px' }} src={this.state.randomPictures[Math.floor(Math.random() * this.state.randomPictures.length)]} alt="ERROR 404" />
                            </div>
                        </div>
                    </div>
                </Layout>
            </div >
        )
    }
}

export default E404;