import React from 'react';
import {Link, withRouter} from 'react-router-dom';

const Footer = ()  => (
    <footer className="position-relative bg-dark">
        <div className="container">
            <div className="space-top-2 space-bottom-1 space-bottom-lg-2">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-3 ml-lg-auto mb-5 mb-lg-0">
                        <div className="mb-4">
                            <Link to="/" aria-label="ShaniOffer">
                                <img className="brand" src="/assets/img/logos/LOGOMINBLANCO.png" alt="Red Itmex Logo" />
                            </Link>
                        </div>

                        <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                            <li className="nav-item">
                                <a className="nav-link media" href="javascript:;">
                                    <span className="media">
                                        <span className="fas fa-location-arrow mt-1 mr-2"></span>
                                        <span className="media-body">
                                        Av. 5 de Mayo No.1, Colonia Centro, Ixmiquilpan Hidalgo, C.P. 42300
                                        </span>
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link media" href="tel:+527712438160">
                                    <span className="media">
                                        <span className="fas fa-phone-alt mt-1 mr-2"></span>
                                        <span className="media-body">
                                            +52 771 243 81 60
                                        </span>
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link media" href="mailto:asistencia@reditmex.com">
                                    <span className="media">
                                        <span className="fas fa-envelope-open-text mt-1 mr-2"></span>
                                        <span className="media-body">
                                            asistencia@reditmex.com
                                        </span>
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link media" href="#">
                                    <span className="media">
                                        <span className="fas fa-clock mt-1 mr-2"></span>
                                        <span className="media-body">
                                            Horario de Atención: Lunes a Domingo de 9:00 a 18:00
                                        </span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-3 col-lg mb-5 mb-lg-0">
                        <h5 className="text-white">Compañía</h5>

                        <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                            <li className="nav-item"><Link className="nav-link" to="/nosotros">Nosotros</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/contacto">Contacto</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/podcasts">Podcasts</Link></li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-3 col-lg mb-5 mb-lg-0">
                        <h5 className="text-white">Servicios</h5>

                        <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                            <li className="nav-item"><a className="nav-link" href="/#planesInternet">Planes</a></li>
                            <li className="nav-item"><Link className="nav-link" to="/internet/faq">Preguntas Frecuentes</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/cobertura">Cobertura</Link></li>
                            {/* <li className="nav-item"><Link className="nav-link" to="/promociones">Promociones</Link></li> */}
                        </ul>
                    </div>

                    <div className="col-6 col-md-3 col-lg">
                        <h5 className="text-white">Documentación</h5>

                        <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                        <li className="nav-item"><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/carta-de-derechos-mínimos-de-los-usuarios/pdf/v3">Carta de Derechos Mínimos</a></li>
                        <li className="nav-item"><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/codigo-de-prácticas-comerciales/pdf/v3">Código de Prácticas Comerciales</a></li>
                        <li className="nav-item"><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/titulo-de-concesion/pdf/v3">Título de la Concesión</a></li>
                        <li className="nav-item"><a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.reditmex.com/documentacion/legal/contrato-de-adhesion/pdf/v3">Registro de Contrato de Adhesión de Telecomunicaciones</a></li>
                        <li className="nav-item"><Link className="nav-link" to="/documentacion">Otros</Link></li>

                        </ul>
                    </div>

                    <div className="col-6 col-md-3 col-lg">
                        <h5 className="text-white">Recursos</h5>
                        <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                            <li className="nav-item"><Link className="nav-link" to="/contacto">Contrata</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <hr className="opacity-xs my-0" />

            <div className="space-1">
                <div className="row align-items-md-center mb-7">
                    <div className="col-md-6 mb-4 mb-md-0">
                        <ul className="nav nav-sm nav-white nav-x-sm align-items-center">
                            <li className="nav-item">
                                <Link className="nav-link" to="/privacidad">Política de Privacidad</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-6 text-md-right">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a className="btn btn-xs btn-soft-light" rel="noreferrer" target="_blank" href="https://www.facebook.com/itmexoficial"  style={{padding: "6px 12px"}}>
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-xs btn-soft-light" rel="noreferrer" target="_blank" href="https://www.instagram.com/reditmex/" style={{padding: "6px 12px"}}>
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-xs btn-soft-light" rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UC6Sirelw0bYpaT4VktsK19A" style={{padding: "6px 12px"}}>
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-xs btn-soft-light" rel="noreferrer" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"style={{padding: "6px 12px"}}>
                                    <i className="fab fa-spotify"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-xs btn-soft-light" rel="noreferrer" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"style={{padding: "6px 12px"}}>
                                    <i className="fab fa-apple"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <div className="hs-unfold">
                                    <Link className=" btn btn-xs btn-soft-light" to="/" >
                                        <img className="dropdown-item-icon" src="/assets/vendor/flag-icon-css/flags/4x3/mx.svg" alt="Mexico Flag" />
                                        <span>México</span>
                                    </Link>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="w-md-75 text-lg-center mx-lg-auto">
                    <p className="text-white opacity-sm small">© Red Itmex. 2024 Todos los derechos reservados. Powered by <a href="https://zerep.mx" target="_blank" style={{color: 'white'}}><b>Zerep</b></a></p>
                    <p className=" opacity-sm small">Cuando visitas o interactuas con nuestros sitios, servicios o herramientas, nosotros o nuestros proveedores de servicios autorizados podrían usar cookies para almacer información que ayude a brindarte una mejor, más rapida y más segura, experiencia y con fines de mercadeo.</p>
                </div>
            </div>
        </div>
    </footer>
)
export default withRouter(Footer);