import React from 'react';
import Layout from './Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import { sendBlueMail } from '../firebase/firebase.utils';


class Contacto extends React.Component {
  constructor(props) {
    super(props);this.state = {
        loading: false,
        loadingMail: false,
        successMail:false,
        errorMail: null,
        errorMail_code: null,
        errorMail_response: null,
        errorMail_message: null,
        contactName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        contactSubject: '',
        contactMensaje: ''
    }
  }
  onChangeContactName= (e) =>{
      this.setState({
          contactName: e.target.value
      })
  }
  onChangeContactLastName= (e) =>{
      this.setState({
          contactLastName: e.target.value
      })
  }
  onChangeContactEmail= (e) =>{
      this.setState({
          contactEmail: e.target.value
      })
  }
  onChangeContactPhone= (e) =>{
      this.setState({
          contactPhone: e.target.value
      })
  }
  onChangeContactSubject= (e) =>{
      this.setState({
          contactSubject: e.target.value
      })
  }
  onChangeContactMensaje= (e) =>{
      this.setState({
          contactMensaje: e.target.value
      })
  }
  onClickEnviar= async (e) =>{
      e.preventDefault()
      if (
          this.state.contactName != '' &&
          this.state.contactLastName != '' &&
          this.state.contactEmail != '' &&
          this.state.contactPhone != '' &&
          this.state.contactSubject != '' &&
          this.state.contactMensaje != ''
      ){
          this.setState({ loadingMail: true, errorMail: false }, async () => {
              const newBlueMail = await sendBlueMail(
                  this.state.contactName,
                  this.state.contactLastName,
                  this.state.contactEmail,
                  this.state.contactPhone,
                  this.state.contactSubject,
                  this.state.contactMensaje
              ).then((response)=>{
                console.log(response)
                if(response.success){
                  console.log(response)
                  this.setState({loadingMail:false, successMail: true})
                }else{
                  this.setState({ errorMail: response.error, errorMail_code: response.error_code, errorMail_message: response.error_message })
                }
              })
          })
      }else{
        this.setState({ loading: false, errorMail: true, errorMail_code: "Campos vacíos", errorMail_message: "Faltan llenar todos los valores."},() =>{
        })
      }
  }
    getGoogleMaps() {
      // If we haven't already defined the promise, define it
      if (!this.googleMapsPromise) {
        this.googleMapsPromise = new Promise((resolve) => {
          // Add a global handler for when the API finishes loading
          window.resolveGoogleMapsPromise = () => {
            // Resolve the promise
            resolve();
  
            // Tidy up
            delete window.resolveGoogleMapsPromise;
          };
  
          // Load the Google Maps API
          const script = document.createElement("script");
          const API = process.env.REACT_APP_GOOGLEMAPS_API_KEY;
          script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=initMap`;
          script.async = true;
          document.body.appendChild(script);
        });
      }
  
      // Return a promise for the Google Maps API
      return this.googleMapsPromise;
    }
    componentDidMount(){
        this.getGoogleMaps();
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title='Red Itmex - Contacto';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render(){
        return(
            <div className=" ">
                <Layout title="Red Itmex - Contacto">
    <div className="position-relative">
      <div className="bg-img-hero" style={{backgroundImage: 'url(/assets/svg/components/abstract-shapes-12.svg)'}}>
        <div className="container space-top-3 space-top-lg-4 space-bottom-2 position-relative z-index-2">
          <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-9">
            <h1>¿Tienes preguntas?</h1>
            <p>Nos encantaría platicar contigo.</p>
          </div>

          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0 mb-md-n11">
              <div className="card text-center h-100 transition-3d-hover">
                <div className="card-body p-lg-5">
                  <figure className="max-w-8rem w-100 mx-auto mb-4">
                    <img className="img-fluid" src="/assets/svg/icons/icon-7.svg" alt="SVG" />
                  </figure>
                  <h3 className="h4">Academia</h3>
                  <p className="text-body mb-0">¿Quieres inscribirte a alguno de nuestros cursos?</p>
                </div>
                <div className="card-footer font-weight-bold py-3 px-lg-5">
                  Contacta (771) 243-8160 <i className="fas fa-angle-right fa-sm ml-1"></i>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3 mb-md-0 mb-md-n11">
              <div className="card text-center h-100 transition-3d-hover">
                <div className="card-body p-lg-5">
                  <figure className="max-w-8rem w-100 mx-auto mb-4">
                    <img className="img-fluid" src="/assets/svg/icons/icon-4.svg" alt="SVG" />
                  </figure>
                  <h3 className="h4">Internet</h3>
                  <p className="text-body mb-0">¿Quieres contratar alguno de nuestros planes de internet?</p>
                </div>
                <div className="card-footer font-weight-bold py-3 px-lg-5">
                  Contacta (771) 243-8160<i className="fas fa-angle-right fa-sm ml-1"></i>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-md-n11">
              <div className="card text-center h-100 transition-3d-hover" >
                <div className="card-body p-lg-5">
                  <figure className="max-w-8rem w-100 mx-auto mb-4">
                    <img className="img-fluid" src="/assets/svg/icons/icon-44.svg" alt="SVG" />
                  </figure>
                  <h3 className="h4">CAT</h3>
                  <p className="text-body mb-0">¿Tienes algún problema técnico y buscas ayuda?</p>
                </div>
                <div className="card-footer font-weight-bold py-3 px-lg-5">
                    Contacta (771) 243-8160<i className="fas fa-angle-right fa-sm ml-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <figure className="position-absolute bottom-0 right-0 left-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
          <polygon fill="#fff" points="0,273 1921,273 1921,0 "/>
        </svg>
      </figure>
    </div>
                    <div className="container space-2 ">
                        <div className="row">
                            <a href="https://www.facebook.com/itmexoficial" className="col-sm-12 col-lg-4 p-2">
                            <button type="button" className=" btn w-100 btn-xs btn-facebook2 btn-wide transition-3d-hover text-center ">
                                <span className="media align-items-center">
                                    <span className="fab fa-facebook-f fa-2x mr-3"></span>
                                    <span className="media-body">
                                        <span className="d-block">Síguenos en</span>
                                    <strong className="font-size-1">Facebook</strong>
                                    </span>
                                </span>
                            </button>
                            </a>
                            <a href="https://www.instagram.com/reditmex" className="col-sm-12 col-lg-4 p-2">
                            <button type="button" className=" w-100  btn btn-xs btn-instagram2 btn-wide transition-3d-hover text-center ">
                                <span className="media align-items-center">
                                    <span className="fab fa-instagram fa-2x mr-3"></span>
                                    <span className="media-body">
                                        <span className="d-block">Síguenos en</span>
                                    <strong className="font-size-1">Instagram</strong>
                                    </span>
                                </span>
                            </button>
                            </a>
                            <a href="https://www.youtube.com/channel/UC6Sirelw0bYpaT4VktsK19A" className="col-sm-12 col-lg-4 p-2">
                            <button type="button" className=" w-100  btn btn-xs btn-youtube2 btn-wide transition-3d-hover text-center ">
                                <span className="media align-items-center">
                                    <span className="fab fa-youtube fa-2x mr-3"></span>
                                    <span className="media-body">
                                        <span className="d-block">Síguenos en</span>
                                    <strong className="font-size-1">YouTube</strong>
                                    </span>
                                </span>
                            </button>
                            </a>
                            <a href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7" className="col-sm-12 col-lg-4 p-2">
                            <button type="button" className=" w-100  btn btn-xs btn-spotify2 btn-wide transition-3d-hover text-center ">
                                <span className="media align-items-center">
                                    <span className="fab fa-spotify fa-2x mr-3"></span>
                                    <span className="media-body">
                                        <span className="d-block">Síguenos en</span>
                                    <strong className="font-size-1">Spotify</strong>
                                    </span>
                                </span>
                            </button>
                            </a>
                            <a href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394" className="col-sm-12 col-lg-4 p-2">
                            <button type="button" className=" w-100  btn btn-xs btn-itunes2 btn-wide transition-3d-hover text-center ">
                                <span className="media align-items-center">
                                    <span className="fas fa-podcast fa-2x mr-3"></span>
                                    <span className="media-body">
                                        <span className="d-block">Síguenos en</span>
                                    <strong className="font-size-1">Apple Podcasts</strong>
                                    </span>
                                </span>
                            </button>
                            </a>
                            <a href="https://wa.me/5217712438160?text=Hola%2C+%C2%BFme+podr%C3%ADas+brindar+informaci%C3%B3n+del+servicio+de+internet%3F+%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB" className="col-sm-12 col-lg-4  p-2">
                            <button type="button" className=" w-100  btn btn-xs btn-whatsapp2 btn-wide transition-3d-hover text-center ">
                                <span className="media align-items-center">
                                    <span className="fab fa-whatsapp fa-2x mr-3"></span>
                                    <span className="media-body">
                                        <span className="d-block">Contáctanos por </span>
                                    <strong className="font-size-1">WhatsApp</strong>
                                    </span>
                                </span>
                            </button>
                            </a>
                        </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 mb-9 mb-lg-0">
                          <div className="mb-5">
                            <h1>¡Contáctanos!</h1>
                            <p>Nos encantaría saber como podemos ayudarte.</p>
                          </div>
                          {/* Google Map -->*/}
                          <div className="container-fluid space-bottom-1">
                              <div id="gMapMultipleMarkers" className="embed-responsive embed-responsive-21by9 u-gmap-interactive min-height-450"
                                  data-type="custom"
                                  data-lat="20.4851751"
                                  data-lng="-99.2190059"
                                  data-zoom="17"
                                  data-pin="true"
                                  data-multiple-markers="true"
                                  data-styles='[[
                                  "", "", [
                                      {"saturation": -100},
                                      {"lightness": 50},
                                      {"visibility": "simplified"}
                                  ]],[
                                      "",
                                      "geometry", [
                                      {"color": "#f3f3f3"}
                                  ]],[
                                      "road", "", [
                                      {"color": "#ffffff"},
                                      {"lightness": 100}
                                  ]],[
                                      "road",
                                      "labels.text.fill", [
                                      {"color": "#ffffff"},
                                      {"lightness": -50}]
                                  ],[
                                      "water", "", [
                                      {"color": "#377dff"}
                                  ]]
                                  ]'
                                  data-markers-locations='[[
                                      "/assets/img/map-markers/map-marker1.png",
                                      "Red Itmex",
                                      20.4851751, -99.2190059,
                                      "",
                                      "Red Itmex",
                                      "",
                                      "México",
                                      "Red Itmex"
                                  ]]'></div>
                          </div>
                          {/* End Google Map -->*/}
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <span className="d-block h5 mb-1">Llámanos:</span>
                                <span className="d-block text-body font-size-1">+52 (771) 243-8160</span>
                              </div>

                              <div className="mb-3">
                                <span className="d-block h5 mb-1">Correo:</span>
                                <span className="d-block text-body font-size-1">asistencia@reditmex.com</span>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="mb-3">
                                <span className="d-block h5 mb-1">Dirección:</span>
                                <span className="d-block text-body font-size-1">Av. 5 de Mayo No.1, Colonia Centro, Ixmiquilpan Hidalgo, C.P. 42300</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="ml-lg-5">
                            <form className="js-validate card shadow-lg mb-4" noValidate="novalidate">
                              <div className="card-header border-0 bg-light text-center py-4 px-4 px-md-6">
                                <h2 className="h4 mb-0">Manda un mensaje</h2>
                              </div>

                              <div className="card-body p-4 p-md-6">
                                {this.state.errorMail ?
                                  <div className="alert alert-danger" role="alert">
                                  <b>{this.state.errorMail_code}</b> {this.state.errorMail_message}
                                  </div>
                                  :
                                  null
                                }
                                {this.state.successMail ?
                                  <div className="alert alert-success" role="alert">
                                  <b><i className="far fa-check-circle"></i></b> ¡Correo enviado!
                                  </div>
                                  :
                                  null
                                }
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="js-form-message form-group">
                                      <label htmlFor="firstName" className="input-label">Nombre</label>
                                      <input type="text" value={this.state.contactName} onChange={this.onChangeContactName}  className={"form-control " + (this.state.errorMail && this.state.contactName == '' ? 'is-invalid' : '')}name="firstName" id="firstName" placeholder="Nombre" aria-label="Nombre" required="" data-msg="Ingresa tu Nombre"/>
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="js-form-message form-group">
                                      <label htmlFor="lastName" className="input-label">Apellido</label>
                                      <input type="text" value={this.state.contactLastName} onChange={this.onChangeContactLastName}  className={"form-control " + (this.state.errorMail && this.state.contactLastName == '' ? 'is-invalid' : '')}name="lastName" id="lastName" placeholder="Apellido" aria-label="Apellido" required="" data-msg="Ingresa tu Apellido"/>
                                    </div>
                                  </div>

                                  <div className="col-sm-12">
                                    <div className="js-form-message form-group">
                                      <label htmlFor="emailAddress" className="input-label">Email</label>
                                      <input type="email" value={this.state.contactEmail} onChange={this.onChangeContactEmail}  className={"form-control " + (this.state.errorMail && this.state.contactEmail == '' ? 'is-invalid' : '')}name="emailAddress" id="emailAddress" placeholder="hola@correo.com" aria-label="hola@correo.com" required="" data-msg="Ingresa tu correo"/>
                                    </div>
                                  </div>
                                  <div className="col-sm-12">
                                    <div className="js-form-message form-group">
                                      <label htmlFor="phone" className="input-label">Teléfono</label>
                                      <input type="number" value={this.state.contactPhone} onChange={this.onChangeContactPhone}  className={"form-control " + (this.state.errorMail && this.state.contactPhone == '' ? 'is-invalid' : '')}name="phone" id="phone" placeholder="7711234567" aria-label="7711234567" required="" data-msg="Ingresa tu teléfono"/>
                                    </div>
                                  </div>

                                  <div className="col-sm-12">
                                    <div className="js-form-message form-group">
                                      <label htmlFor="subject" className="input-label">Asunto</label>
                                      <input type="text" value={this.state.contactSubject} onChange={this.onChangeContactSubject}  className={"form-control " + (this.state.errorMail && this.state.contactSubject == '' ? 'is-invalid' : '')} name="subject" id="subject" placeholder="Duda o comentario" aria-label="Duda o comentario" required="" data-msg="Ingresa un asunto"/>
                                    </div>
                                  </div>


                                  <div className="col-sm-12">
                                    <div className="js-form-message form-group">
                                      <label htmlFor="message" className="input-label">Mensaje</label>
                                      <div className="input-group">
                                        <textarea  value={this.state.contactMensaje} onChange={this.onChangeContactMensaje}  className={"form-control " + (this.state.errorMail && this.state.contactMensaje == '' ? 'is-invalid' : '')} rows="4" name="message" id="message" placeholder="¡Hola!, me gustaria saber sobre..." aria-label="¡Hola!, me gustaria saber sobre..." required="" data-msg="Ingresa tu mensaje" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.successMail ?
                                  <button type="submit" className="btn btn-block btn-success transition-3d-hover">Enviado</button>
                                  :
                                  <button onClick={this.onClickEnviar} type="submit" className="btn btn-block btn-primary transition-3d-hover">Enviar</button>
                                }
                              </div>
                            </form>
                            <div className="text-center">
                              <p className="small">Te contactaremos de vuelta lo mas pronto posible. Revisa que tus datos de contacto sean correctos.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </Layout> 
            </div>
        )
    }
};
export default Contacto;