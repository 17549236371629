import React from 'react';
import Menu from './Menu';
import Footer from './Footer';

const Layout = ({title = 'Red Itmex', description = 'Proveedores de Servicios de Internet en Hidalgo', children}) => 
(
    <div>
        <Menu />
        <div>
           {children}
        </div>
        <Footer />
    </div>
)

export default Layout;
