import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../core/Layout';
import AOS from 'aos';
import axios from 'axios';
import 'aos/dist/aos.css'
import { auth, getPaymentRef, getCursoRef, getCursosRef, getMisClasesRef, getClasesRef, getModulosRef, getMisCursosRef, createUserProfileDocument } from '../firebase/firebase.utils'

class E404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            loading: true,
            success: false,
            currentUser: {},
            curso: ''
        }
    }
    checkSession = async (sessionId, userID) => {
        var response = await axios(
            {
                url: '/idSession',
                method: 'post',
                data: {
                    sesion: sessionId
                }
            }
        )
        if (response.data.metadata) {
            if(response.data.metadata.idCurso){
                this.setState({ curso: response.data.metadata.idCurso })
                await getPaymentRef(response.data.payment_intent, response.data.amount_total, this.props.match.params.sessionId, userID, response.data.metadata.idCurso, Math.round(response.data.amount_total/2000)).then((result) => {
                    
                    if (result == false) {
                        this.setState({ error: true, success: false, loading: false })
                    } else {
                        this.setState({ loading: false, success: true, error: false })
                    }
                })
            }else{
                this.setState({ loading: false, success: true, error: false })
            }
        } else {
            this.setState({ error: true, success: false,  loading: false })
        }
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Pago Exitoso';
        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                this.checkSession(this.props.match.params.sessionId, userRef.id)
                userRef.onSnapshot(snapShot => {
                    this.setState({
                        currentUser: {
                            id: snapShot.id,
                        },
                        loading: false
                    });
                })
            }
        })
    }
    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Contacto">
                    <div className="loader" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                        <div className="Box"></div>
                    </div>
                    <div className="container " style={{ height: '55vh', paddingTop: '20vh', display: this.state.loading ? 'none' : 'block' }}>
                        {this.state.error ?
                            <center>
                                <h1>Oops! &#128532;</h1>
                                <h4>Ha habido un error en nuestro sistema. Esto puede ser por que este curso ya lo has comprado anteriormente (revisa <Link to="/perfil/miscursos/">Mis Cursos</Link>), hubo un error en el pago o un error en nuestra base de datos. <Link to="/contacto">Conctáctanos</Link> inmediatamente para saber que sucedió.</h4>
                            </center>
                            :
                            <Fragment></Fragment>
                        }
                        {this.state.success ?
                            <center>
                                <h1>¡Felicidades! &#128516;</h1>
                                <h4>Tu curso ha sido adquirido exitosamente.</h4>
                                <Link className="btn btn-primary btn-pill transition-3d-hover px-5 mt-3" to={'/academia/curso/' + this.state.curso}>Ir al Curso</Link>
                            </center>
                            :
                            <Fragment></Fragment>
                        }
                    </div>
                </Layout>
            </div>
        )
    }
}

export default E404;