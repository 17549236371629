import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'
import axios from 'axios'
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Helmet } from 'react-helmet'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';

class Podcasts extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Blog';
        this.registrarVisita()

    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div>
                <Helmet>
                    <meta name="description" data-react-helmet="true" content="Escucha y conoce la historia detrás de las personas que enriquecen nuestra comunidad en Podcast Red Itmex. Escúchanos en tu plataforma favorita. Nuevo contenido todos los jueves a las 3:00PM CT." />
                    <meta name="robots" data-react-helmet="true" content="index" />
                    <meta property="og:url" data-react-helmet="true" content="https://www.reditmex.com/podcasts" />
                    <meta property="og:type" data-react-helmet="true" content="website" />
                    <meta property="og:title" data-react-helmet="true" content="Red Itmex - Podcasts" />
                    <meta property="og:description" data-react-helmet="true" content="Escucha y conoce la historia detrás de las personas que enriquecen nuestra comunidad en Podcast Red Itmex. Escúchanos en tu plataforma favorita. Nuevo contenido todos los jueves a las 3:00PM CT." />
                    <meta property="og:image" data-react-helmet="true" content="https://www.reditmex.com/assets/img/FBThumbnail01.jpg" />
                    <meta property="fb:app_id" data-react-helmet="true" content="418170456085047" />
                </Helmet>
                <Layout title="Red Itmex - Podcasts">

                    <div class="container space-top-1 space-top-lg-1 space-bottom-1 space-bottom-md-1">
                        <div class="w-md-80 w-lg-75 text-center mx-md-auto">
                            <h1 class="display-4">Podcasts</h1>
                            <p class="lead"><b>¡Escúchanos en tu plataforma favorita!</b></p>
                        </div>
                        <div class="space-2">
                            <div className="row mb-3">
                                <div class="col-sm-6 col-md-4  mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E10</a>
                                                </span>
                                                <h2>Ciclista profesional Representando a Ixmiquilpan, Hidalgo y México CON Francisco Lara</h2>
                                                <p>En el noveno episodio platicamos con Francisco Lara un orgullo Ixmiquilpense en el ciclismo que ha logrado obtener premios nacionales e internacionales. Nos cuenta experiencias que ha tenido practicando el ciclismo, sus retos y motivaciones.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/2xu2VQ8Noe4"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-sm-6 col-md-4  mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E09</a>
                                                </span>
                                                <h2>La importancia de la difusión de las lenguas indígenas como el Hñähñu CON Erasmo Huizache</h2>
                                                <p>En el noveno episodio platicamos con Erasmo Huizache del municipio de Cardonal quien ha sido un promotor de la lengua indígena. Ha su corta edad ha tenido la oportunidad de colaborar en la traducción de 4 libros al Hña-Hñu. Conoce con nosotros sobre la historia de las lenguas indígenas, la discriminación, el peligro que corren por desaparecer y la importancia que tienen los padres en la difusión de la lengua materna.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/ft_a0aE1ThY"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-sm-6 col-md-4  mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E08</a>
                                                </span>
                                                <h2>Nutrióloga habla sobre como tener buena alimentación en tiempos de pandemia CON Saraí Pérez</h2>
                                                <p>En el octavo episodio platicamos con Saraí Pérez nos platica sobre las consecuencias que la pandemia a traído en el campo de la nutrición. Conocemos la ventaja que tenemos de estar en el valle del mezquital pues tenemos una gastronomía y agricultura muy vasta. ¿Ya sabes como cuidar tu salud para no estar en un grupo vulnerable de covid-19?</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/7xTvsIAuKG8"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div class="col-sm-6 col-md-4  mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E07</a>
                                                </span>
                                                <h2>Músico Profesional en pandemia, retos, historia CON Joel Peña</h2>
                                                <p>En el séptimo episodio platicamos con Joel Peña un Músico, Compositor y Catedrático Hidalguense nos habla sobre el impacto de la pandemia en la industria de la música y la ayuda del internet en el sector. También nos cuenta un poco su historia como músico y los principales retos que ha tenido.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/QwuDLu4DIvQ"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-sm-6 col-md-4  mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E06</a>
                                                </span>
                                                <h2>Emprendedores poniendo en alto la cultura indígena CON Textiles Caramaya</h2>
                                                <p>En el sexto episodio platicamos con los fundadores de Textiles Caramaya que están buscando preservar las raíces del valle del mezquital a través de indumentarias con diseños únicos que caracterizan a la cultura indígena. Conoce su proyecto en donde a través de un grupo de artesanos, diseñadores, artistas están haciendo un cambio en la moda.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/ZCqqOPZLekM"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-sm-6 col-md-4  mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E05</a>
                                                </span>
                                                <h2>Innovando el periodismo en Ixmiquilpan CON Roberto Bravo</h2>
                                                <p>En el quinto episodio platicamos con el periodista Roberto Bravo, quien todos los días abre un espacio al diálogo para enterarnos y discutir las noticias de Ixmiquilpan. Nos cuenta sobre cómo le ha ayudado el Internet en su proyecto de RB Periodismo para conversar, qué podemos hacer para informarnos mejor y mucho más.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/zvF8PviAGQ8"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="row mb-3">

                                <div class="col-sm-6 col-md-4  mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E04</a>
                                                </span>
                                                <h2>Joven Ixmiquilpense aspirando a lo grande CON Brenda Esteban</h2>
                                                <p>En el cuarto episodio platicamos con Brenda Esteban, una joven ixmiquilpense que ha superado varios retos. Descubre como es que ella se va a Francia a representar nuestra gastronomía así como el impacto que ha tenido la pandemia en su vida en temas como su libro "Por séptima vez", su emprendimiento y su vida profesional.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/SVUA8MmT9nU"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-sm-6 col-md-4 mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E03</a>
                                                </span>
                                                <h2>Un profesor cambiando a una generación CON Alfonso Hernández</h2>
                                                <p>En el tercer episodio platicamos con Alfonso Hernández, ganador de la Beca Itmex 2020 en apoyo a la educación y un profesor que nos inspira. Hablaremos sobre su forma de ver la vida, sus retos personales, migración a Estados Unidos, docencia en zonas rurales y la manera tan creativa en la que sigue dando clases.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/xIwGjBUZ2UM"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-sm-6 col-md-4 mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">

                                        <div class="col-lg-12">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E02</a>
                                                </span>
                                                <h2>Una mujer japonesa en México CON Katsumi Nakatake</h2>
                                                <p>En el segundo episodio platicamos con Katsumi Nakatake sobre las diferencias entre Japón y México, los cambios que ha traído la pandemia a su familia, los retos que ha afrontado y muchas cosas más.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/IYrOaLjFBc8"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-sm-12 mb-3 mb-sm-7 podcastBox">
                                    <article class="row align-items-lg-center">
                                        <div class="col-lg-8">
                                            <img class="img-fluid rounded" src="/assets/img/podcasts/TH_T01E01_InternetEnPandemia.jpg" alt="Image Description" />
                                        </div>

                                        <div class="col-lg-4">
                                            <div class="py-5 px-lg-3">
                                                <span class="d-block mb-2">
                                                    <a class="small text-body font-weight-bold text-cap" href="#">Internet en Pandemia - T01E01</a>
                                                </span>
                                                <h2>El valor de la vocación de enseñar CON Cesar Araujo</h2>
                                                <p>En el primer episodio platicamos sobre el municipio de Ixmiquilpan y alrededores, educación, tecnología y más con César Araujo, director del Instituto Guadalupe Victoria y maestro en Ixmiquilpan por más de 40 años.</p>
                                                <div className="podcasts-links" >
                                                    <a className="podcast-link-youtube" target="_blank" href="https://youtu.be/6qiuvOP7Qp4"><i class="fab fa-youtube-square m-4"></i>  </a>
                                                    <a className="podcast-link-spotify" target="_blank" href="https://open.spotify.com/show/56dQAu73j4a0QaLRO44oa7"><i class="fab fa-spotify m-4"></i>  </a>
                                                    <a className="podcast-link-itunes" target="_blank" href="https://podcasts.apple.com/mx/podcast/internet-en-pandemia/id1552637394"><i class="fas fa-podcast m-4"></i>  </a>
                                                    <a className="podcast-link-google" target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80YjhmYmMxOC9wb2RjYXN0L3Jzcw=="><i class="fas fa-microphone-alt m-4"></i>  </a>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div >
        )
    }
};
export default Podcasts;