import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import Ads from './Ads';
class PlanesFibra extends React.Component {
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        let eventParallax = new Event('parallaxplanes');
        window.dispatchEvent(event);
        window.dispatchEvent(eventParallax);
        document.title = 'Red Itmex - Planes Fibra';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Planes Fibra">
                    <div class="keyart" id="parallax" style={{marginTop: '-75px'}}>
                        <div class="keyart_layer parallax" id="keyart-0" data-speed="2" ></div>
                        <div class="keyart_layer parallax" id="keyart-1" data-speed="5" ></div>
                        <div class="keyart_layer parallax" id="keyart-2" data-speed="11"></div>
                        <div class="keyart_layer parallax" id="keyart-3" data-speed="16"></div>
                        
                        <div class="keyart_layer parallax" id="keyart-4" data-speed="26"></div>
                        <div class="keyart_layer parallax" id="keyart-5" data-speed="36"></div>
                        <div class="keyart_layer parallax" id="keyart-6" data-speed="49"></div>
                        <div class="keyart_layer parallax" id="keyart-7" data-speed="69"></div>
                        <div class="keyart_layer" id="keyart-8" data-speed="100" ></div> 
                    </div>
                    {/* Pricing Section */}
                    <div className='bg-fibraBody position-relative pt-5'>
                        <div className="">
                            <div className="container">
                                <div className="space-bottom-1 space-bottom-md-1">
                                    <div className="container pt-3">
                                        <div className="position-relative">
                                            <div className="row position-relative z-index-2 mx-n2 mb-5" >

                                                <div className="col-sm-6 col-md-3 px-2 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-header text-center">
                                                            <span className="d-block h3">Básico</span>
                                                            <span className="d-block mb-2">
                                                                <span className="text-dark align-top">$</span>
                                                                <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                    <span id="pricingCount1">300</span>
                                                                </span>
                                                                <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                            </span>
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Tecnología Wireless
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Soporte Técnico
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    3 Mb de Descarga
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    1 Mb de Subida
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    1 Mes Gratuito
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-3 px-2 mb-3">
                                                    <div className="card bg-primaryFibra text-white h-100 shadow-primary-lg">
                                                        <div className="card-header border-0 bg-primaryFibra text-white text-center">
                                                            <span className="d-block h3 text-white">Premium</span>
                                                            <span className="d-block mb-2">
                                                                <span className="text-white align-top">$</span>
                                                                <span className="font-size-3 text-white font-weight-bold mr-n2">
                                                                    <span id="pricingCount2">400</span>
                                                                </span>
                                                                <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                            </span>
                                                        </div>

                                                        <div className="border-top opacity-xs"></div>

                                                        <div className="card-body">
                                                            <div className="media font-size-1 mb-3">
                                                                <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Tecnología Wireless
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 mb-3">
                                                                <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Soporte Técnico
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 mb-3">
                                                                <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    8 Mb de Descarga
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 mb-3">
                                                                <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    1.2 Mb de Subida
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 mb-3">
                                                                <i className="fas fa-check-circle mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    1 Mes Gratuito
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-3 px-2 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-header text-center">
                                                            <span className="d-block h3">Gold</span>
                                                            <span className="d-block mb-2">
                                                                <span className="text-dark align-top">$</span>
                                                                <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                    <span id="pricingCount3">500</span>
                                                                </span>
                                                                <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                            </span>
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Tecnología Wireless
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Soporte Técnico
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    10 Mb de Descarga
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    2 Mb de Subida
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    1 Mes Gratuito
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-3 px-2 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-header text-center">
                                                            <span className="d-block h3">Gamer</span>
                                                            <span className="d-block mb-2">
                                                                <span className="text-dark align-top">$</span>
                                                                <span className="font-size-3 text-dark font-weight-bold mr-n2">
                                                                    <span id="pricingCount4">700</span>
                                                                </span>
                                                                <span className="font-size-1" style={{marginLeft: '5px'}}> / mes</span>
                                                            </span>
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Tecnología Wireless
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    Soporte Técnico
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    15 Mb de Descarga
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    2.5 Mb de Subida
                                                                </div>
                                                            </div>
                                                            <div className="media font-size-1 text-body mb-3">
                                                                <i className="fas fa-check-circle text-success mt-1 mr-2"></i>
                                                                <div className="media-body">
                                                                    1 Mes Gratuito
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="position-relative z-index-2 text-center">
                                                <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
                                                    ¿Te interesa alguno de nuestros planes? <Link className="d-block d-sm-inline-block font-weight-bold ml-sm-3" to="/contacto">Contáctanos <span className="fas fa-angle-right ml-1"></span></Link>
                                                </div>
                                            </div>
                                            <figure className="max-w-13rem w-100 position-absolute bottom-0 left-0">
                                                <div className="mb-3 ml-n9">
                                                    <img className="img-fluid" src="../../assets/svg/components/abstract-shapes-10.svg" alt="" />
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>

                                <p className="space-top-2"><i className="far fa-question-circle"></i> El costo de instalación es de $600.00 MXN con contrato a 12 meses. La instalación sin contrato es por $1,500.00 MXN. El equipo se instala en comodato (prestado). Revisa nuestras promociones.</p>
                            </div>
                        </div>
                    
                        <div className="container space-2 space-md-3 bg-fibraBody" style={{zIndex: '2'}}>
                            {/* Title */}
                            <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
                                <span className="btn btn-xs btn-soft-success btn-pill mb-2">Ayuda</span>
                                <h2 className="text-primary">Preguntas <span className="font-weight-semi-bold">frecuentes</span>:</h2>
                            </div>
                            {/* End Title */}

                            {/* FAQ */}
                            <div className="space-bottom-2 space-bottom-md-3" id="faq">
                                <div className="row">
                                    <div className="col-md-6 mb-5">
                                        <div className="pr-md-4">
                                            <h3 className="text-primary">¿Dónde puedo pagar mis servicios?</h3>
                                            <p>
                                                ¡Pagar tu servicio es muy sencillo!. Puedes pagar directamente en <Link to="/contacto">nuestras oficinas</Link> o solicitando el número de cuenta a nuestros ejecutivos.
                                            </p>
                                            <p>
                                                <b>Dirección:</b>  Av. 5 de Mayo No.1, Colonia Centro, Ixmiquilpan Hidalgo, C.P. 42300
                                            </p>
                                        </div>
                                    </div>


                                    <div className="col-md-6 mb-5 mb-md-0">
                                        <div className="pr-md-4">
                                            <h3 className="text-primary">¿Por qué debo tener encendidos los equipo todo el tiempo?</h3>
                                            <p>
                                                Para que haya un mejor monitoreo en tu servicio y para que nuestros técnicos puedan dar mantenimiento a tus equipos de manera remota sin costo alguno, con el fin de mejorar tu experiencia en internet.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="w-100"></div>

                                    <div className="col-md-6 mb-5">
                                        <div className="">
                                            <h3 className="text-primary">¿Qué día tengo que pagar mi servicio?</h3>
                                            <p>
                                                Todos los servicios tienen como fecha límite de pago el día <b>5 de cada mes</b>. Una vez pasando esta fecha, se cobrarán cargos por reconexión.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-5">
                                        <div className="pr-md-4">
                                            <h3 className="text-primary">¿Qué es el cargo por reconexión?</h3>
                                            <p>
                                                Este cargo es por pagar posteriormente a la fecha límite de pago y es de <b>$30.00</b>.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="w-100"></div>

                                    <div className="col-md-6 mb-5">
                                        <div className="">
                                            <h3 className="text-primary">¿Qué es la asistencia a domicilio?</h3>
                                            <p>
                                                Este servicio es una Visita técnica por causas que no son imputables a la empresa, tales como instalación de servicios adicionales solicitados, reparaciones y mantenimiento.
                                            </p>
                                            <p>
                                                El costo de la asistencia a domicilio es de <b>$80.00 pesos mexicanos</b>. (no incluye reposición de un equipo dañado).
                                            </p>
                                        </div>
                                    </div>



                                    <div className="col-md-6">
                                        <div className="">
                                            <h3 className="text-primary">Ya cuento con servicio de internet, pero quiero cambiarme</h3>
                                            <p>
                                                Si actualmente cuentas con algún proveedor de internet, y quieres utilizar los mismos equipos para no volver a invertir, nosotros hacemos una valoración técnica y te informamos al momento si es posible utilizar los equipos con los que actualmente cuentas o necesitas una nueva instalación. <b>¡Bienvenido a la Red Itmex!</b>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="w-100"></div>

                                    <div className="col-md-6">
                                        <div className="">
                                            <h3 className="text-primary">¿Existe un plazo mínimo de contratación del servicio?</h3>
                                            <p>
                                                El plazo mínimo de contrato es de un año en todos los planes residenciales y de negocio. La instalación del servicio sin contrato tiene un costo mayor.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="">
                                            <h3 className="text-primary">¿Dónde tienen cobertura?</h3>
                                            <p>
                                                Puedes revisar las comunidades donde tenemos cobertura <Link to="/cobertura">aquí</Link>. Si tu comunidad no aparece en la lista, puedes <Link to="/contacto">preguntarnos</Link> sobre la disponibilidad en tu área de residencia.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End FAQ */}

                            {/* Divider */}
                            <div className="w-80 mx-auto">
                                <hr className="my-0" />
                            </div>
                            {/* End Divider */}
                        </div>
                    </div>
                </Layout>
            </div >
        )
    }
};
export default PlanesFibra;