import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';

class Concursos extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Concursos';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div>

                <Layout title="Red Itmex - Concursos">
                    <div class="container space-top-1 space-top-lg-1 space-bottom-1 space-bottom-md-1">
                        <div class="w-md-80 w-lg-75 text-center mx-md-auto">
                            <h1 class="display-4">¡Participa y gana!</h1>
                            <p class="lead">Énterate de los nuevos concursos siguiéndonos en nuestras Redes Sociales.</p>
                        </div>
                    </div>
                    <div className="gradient-half-primary-v2 space-bottom-2" style={{ width: '100%' }}>
                        <div className="container ">
                            <div class="row no-gutters mb-6 podcastBox">
                                <div class="col-lg-8 ">
                                    <div class="position-relative">
                                        <img class="card-img" src="/assets/img/concursos/900x450/c1.jpg" alt="Concurso Alumnos Septiembre 2020" />
                                        <figure class="d-none d-lg-block">
                                            <svg class="ie-curved-x position-absolute top-0 right-0 bottom-0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100.1 1920" height="100%">
                                                <path fill="#fff" d="M0,1920c0,0,93.4-934.4,0-1920h100.1v1920H0z"></path>
                                            </svg>
                                        </figure>
                                        <figure class="d-lg-none mb-n1">
                                            <svg class="ie-curved-y position-absolute right-0 bottom-0 left-0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                                <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                                            </svg>
                                        </figure>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="card-body d-flex flex-column h-100 p-4 p-lg-5">
                                        <h2><Link class="text-inherit" to="/c/concurso-septiembre-2020">Alumnos y Alumnas ganan con Red Itmex</Link></h2>
                                        <p>Toma una foto creativa enseñándonos cómo recibes tus clases a través de Internet y participa para ganar fabulosos premios.</p>
                                        <div class="media align-items-center mt-auto">
                                            <div class="media-body d-flex justify-content-end text-muted font-size-1 ml-2">
                                                Septiembre 07
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row no-gutters mb-6 podcastBox">
                                <div class="col-lg-8">
                                    <div class="position-relative">
                                        <img class="card-img" src="/assets/img/concursos/900x450/c2.jpg" alt="Concurso Maestros Septiembre 2020" />
                                        <figure class="d-none d-lg-block">
                                            <svg class="ie-curved-x position-absolute top-0 right-0 bottom-0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100.1 1920" height="100%">
                                                <path fill="#fff" d="M0,1920c0,0,93.4-934.4,0-1920h100.1v1920H0z"></path>
                                            </svg>
                                        </figure>
                                        <figure class="d-lg-none mb-n1">
                                            <svg class="ie-curved-y position-absolute right-0 bottom-0 left-0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                                <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                                            </svg>
                                        </figure>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="card-body d-flex flex-column h-100 p-4 p-lg-5">
                                        <h2><Link class="text-inherit" to="/c/concurso-septiembre-2020">Maestros y Maestras ganan con Red Itmex</Link></h2>
                                        <p>Toma una foto enseñándonos cómo impartes tus clases de manera creativa a través de Internet y participa para ganar fabulosos premios.</p>
                                        <div class="media align-items-center mt-auto">
                                            <div class="media-body d-flex justify-content-end text-muted font-size-1 ml-2">
                                                Septiembre 07
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div >
        )
    }
};
export default Concursos;