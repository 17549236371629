import React, { Fragment } from 'react';
import Layout from '../core/Layout';
import AOS from 'aos';
import ReactHlsPlayer from 'react-hls-player'
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { auth, getComentariosRef, getComentariosTOPRef, obtenerTotalComentarios, setComentarClase, getCursoRef, setMisClasesRef, setReproduccion, getMisClasesRef, getClaseRef, getModuloRef, getPreviousClaseRef, getNextClaseRef, getCursosRef, getClasesRef, getModulosRef, getMisCursosRef, createUserProfileDocument } from '../firebase/firebase.utils'

import ComentarioItem from './components/ComentarioItem';

class ClaseDetalle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curso: '',
            modulo: '',
            clase: {},
            nombreModulo: '',
            nombreCurso: '',
            misClases: [],
            siguienteClase: {},
            anteriorClase: {},
            clasevista: false,
            user: '',
            comentario: '',
            masComentarios: true,
            loading: true,
            comentarios: [],
            comentariosTOP: [],
            numComentarios: 5,
            totalComentarios: 0,
            loadingComentarios: true,
            normalComentarios: true,
            userStatus: false,
        }
        obtenerTotalComentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso).then((result) => {
            this.setState({ totalComentarios: result })
        })

    }
    handleChange = e => {
        setMisClasesRef(this.state.user, this.state.curso, this.state.clase.id, !this.state.clasevista)
        this.setState({ clasevista: !this.state.clasevista })
    }
    obtenerClase = async (idClase, idModulo, idCurso, idUser) => {
        if (idUser) {
            await getMisClasesRef(idUser, idCurso).then((arrayClases) => {
                if (arrayClases) {
                    arrayClases.find(e => {
                        if (e == idClase) {
                            this.setState({ clasevista: true })
                        }
                    })
                }
                this.setState({ misClases: arrayClases })
            })
            await getMisCursosRef(idUser).then(async (arrayCursos) => {
                this.setState({ misCursos: arrayCursos })
                const cursoRef = await getCursoRef(idCurso)
                cursoRef.get().then((curso) => {
                    this.setState({ curso: curso.id, nombreCurso: curso.data().title })
                })
                const moduloRef = await getModuloRef(idCurso, idModulo);
                this.setState({ nombreModulo: moduloRef.nombreModulo, modulo: idModulo })
                //console.log(moduloRef)
                const claseRef = await getClaseRef(idCurso, idModulo, idClase)
                //console.log(claseRef)
                const nextClaseRef = await getNextClaseRef(idCurso, idModulo, idClase, claseRef.indice)
                //console.log("siguiente", nextClaseRef)
                const previousClaseRef = await getPreviousClaseRef(idCurso, idModulo, idClase, claseRef.indice)
                //console.log("anterior", previousClaseRef)
                if (claseRef) {
                    this.setState({ clase: claseRef })
                    if (nextClaseRef) {
                        this.setState({ siguienteClase: nextClaseRef })
                    }
                    if (previousClaseRef) {
                        this.setState({ anteriorClase: previousClaseRef })
                    }
                    if (!arrayCursos.find(e => e == idCurso) && !claseRef.gratis) {
                        this.props.history.push(`/academia/curso/${idCurso}`);
                    } else {
                        if (arrayCursos.find(e => e == idCurso)) {
                            this.setState({ inscrito: true })
                        }
                        if (this.state.clase.reproducciones) {
                            setReproduccion(idCurso, idModulo, idClase, parseInt(this.state.clase.reproducciones))
                        } else {
                            setReproduccion(idCurso, idModulo, idClase, 0)
                        }
                        this.setState({ loading: false })
                    }
                } else {
                    this.props.history.push(`/academia/cursos`);
                }
            })
        }
        else {
            const cursoRef = await getCursoRef(idCurso)
            cursoRef.get().then((curso) => {
                this.setState({ nombreCurso: curso.data().title })
            })
            const moduloRef = await getModuloRef(idCurso, idModulo);
            this.setState({ nombreModulo: moduloRef.nombreModulo, modulo: idModulo })
            //console.log(moduloRef)
            const claseRef = await getClaseRef(idCurso, idModulo, idClase)
            //console.log(claseRef)
            const nextClaseRef = await getNextClaseRef(idCurso, idModulo, idClase, claseRef.indice)
            //console.log("siguiente", nextClaseRef)
            const previousClaseRef = await getPreviousClaseRef(idCurso, idModulo, idClase, claseRef.indice)
            //console.log("anterior", previousClaseRef)
            if (claseRef) {
                this.setState({ clase: claseRef })
                if (nextClaseRef) {
                    this.setState({ siguienteClase: nextClaseRef })
                }
                if (previousClaseRef) {
                    this.setState({ anteriorClase: previousClaseRef })
                }
                if (!claseRef.gratis) {
                    this.props.history.push(`/academia/curso/${idCurso}`);
                } else {
                    if (this.state.clase.reproducciones) {
                        setReproduccion(idCurso, idModulo, idClase, parseInt(this.state.clase.reproducciones))
                    } else {
                        setReproduccion(idCurso, idModulo, idClase, 0)
                    }
                    this.setState({ loading: false })
                }
            } else {
                this.props.history.push(`/academia/cursos`);
            }
        }
    }
    obtenerComentarios = async (idClase, idModulo, idCurso, numComentarios) => {
        this.setState({ comentarios: [] })
        const comentariosRef = await getComentariosRef(idCurso, idModulo, idClase, numComentarios)
        await comentariosRef.get().then(qSnapshot => {
            var arrayComentarios = []
            qSnapshot.forEach(comentario => {
                var comentarioRaw = comentario.data()
                comentarioRaw.id = comentario.id
                arrayComentarios.push(comentarioRaw)
            })
            if (qSnapshot.size < this.state.totalComentarios) {
                this.setState({ masComentarios: true })
            } else {
                this.setState({ masComentarios: false })
            }
            this.setState({ comentarios: arrayComentarios, loadingComentarios: false })
        })
    }
    obtenerComentariosTOP = async (idClase, idModulo, idCurso, numComentarios) => {
        const comentariosTOPRef = await getComentariosTOPRef(idCurso, idModulo, idClase, numComentarios)
        await comentariosTOPRef.get().then(qSnapshot => {
            var arrayComentarios = []
            qSnapshot.forEach(comentario => {
                var comentarioRaw = comentario.data()
                comentarioRaw.id = comentario.id
                arrayComentarios.push(comentarioRaw)
            })
            this.setState({ comentariosTOP: arrayComentarios, loadingComentarios: false })
        })
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Clase';

        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                userRef.onSnapshot(snapShot => {
                    this.setState({ user: snapShot.id, userImagen: snapShot.data().photoURL, userStatus: snapShot.data().status })
                })

                this.obtenerComentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios)
                this.obtenerComentariosTOP(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, 5)
                this.obtenerClase(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, userRef.id)
            } else {
                this.obtenerClase(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, false)
                this.obtenerComentariosTOP(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, 5)
                this.obtenerComentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios)
            }
        })

    }
    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }
    handleCancelar = (e) => {
        e.preventDefault()
        this.setState({ comentario: '' })
    }
    handleCargarComentarios = () => {
        this.obtenerComentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios + 5)
        this.setState({ numComentarios: this.state.numComentarios + 5 })

    }
    deleteComentario = () => {
        this.setState({ comentarios: [], comentariosTOP: [], totalComentarios: this.state.totalComentarios - 1, loadingComentarios: true })
        this.obtenerComentariosTOP(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios)
        this.obtenerComentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios)
    }
    handleShowNormalComentarios = () => {
        this.setState({ comentarios: [], loadingComentarios: true })
        this.obtenerComentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios)
        this.setState({ normalComentarios: true })
    }
    handleShowTOPComentarios = () => {
        this.setState({ comentariosTOP: [], loadingComentarios: true })
        this.obtenerComentariosTOP(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, 5)
        this.setState({ normalComentarios: false })
    }
    handleComentar = async (e) => {
        e.preventDefault()
        if (this.state.comentario.trim() != "") {
            const newComment = await setComentarClase(this.state.comentario, this.state.user, this.state.curso, this.state.modulo, this.props.match.params.idclase)
            newComment.get().then(comentario => {
                var arrayComentarios = this.state.comentarios
                this.setState({ loadingComentarios: true })
                var comentarioRaw = comentario.data()
                comentarioRaw.id = comentario.id
                this.obtenerComentarios(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios)
                this.obtenerComentariosTOP(this.props.match.params.idclase, this.props.match.params.idmodulo, this.props.match.params.idcurso, this.state.numComentarios)
                this.setState({ comentario: '', totalComentarios: this.state.totalComentarios + 1 })
            })
        }
    }
    handleText = (e) => {
        this.setState({ comentario: e.target.value })
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Nombre del Curso">

                    <div className="loader" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                        <div className="Box"></div>
                    </div>
                    <div className="container" style={{ display: this.state.loading ? 'none' : 'block' }}>
                        <div className="row align-items-end mb-2">
                            <div className="col-lg-12">
                                <h1>{this.state.clase.indice} - {this.state.clase.nombreClase}</h1>
                                <h6><Link to={'/academia/curso/' + this.props.match.params.idcurso}>{this.state.nombreCurso}</Link> - {this.state.nombreModulo}</h6>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                {this.state.anteriorClase.id ?
                                    <a href={`/academia/curso/${this.state.anteriorClase.curso}/${this.state.anteriorClase.modulo}/${this.state.anteriorClase.id}`} className="btn btn btn-primary mb-2" style={{ width: '100%', fontSize: 'calc(1.0rem + 0.075vw)' }}><i className="fas fa-chevron-circle-left"></i> {this.state.anteriorClase.indice} - {this.state.anteriorClase.nombreClase} </a>
                                    :
                                    <Fragment></Fragment>
                                }
                            </div>
                            <div className="col-sm-12 col-lg-6 ">
                                {this.state.siguienteClase.id ?
                                    <a href={`/academia/curso/${this.state.siguienteClase.curso}/${this.state.siguienteClase.modulo}/${this.state.siguienteClase.id}`} className="btn btn btn-success mb-2" style={{ width: '100%', fontSize: 'calc(1.0rem + 0.075vw)' }}>{this.state.siguienteClase.indice} - {this.state.siguienteClase.nombreClase} <i className="fas fa-chevron-circle-right"></i></a>
                                    :
                                    <Fragment></Fragment>
                                }
                            </div>
                        </div>
                        {this.state.clase.src ?
                            this.state.clase.tipo == "video" ?
                                this.state.clase.src ?
                                    <ReactHlsPlayer
                                        url={this.state.clase.src}
                                        autoplay={false}
                                        controls={true}
                                        width="100%"
                                        height="auto"
                                    />
                                    :
                                    null
                                :
                                <div className="row" style={{ margin: '60px 0px' }}>
                                    <div className="col-sm-12 text-align-center">
                                        <center><h2>¡Este link te ayudará durante tu curso!</h2></center>
                                    </div>
                                    <div className="col-sm-12 col-lg-8 offset-lg-2">
                                        <a className="btn btn-info" href="" style={{ width: '100%' }}> Link del artículo</a>
                                    </div>
                                </div>
                            :
                            <Fragment>
                                <div className="row" style={{ margin: '60px 0px' }}>
                                    <div className="col-sm-12 mb-2">
                                        <center><h2>¡Pronto publicaremos esta clase!</h2></center>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        <Fragment>
                            <div className="row">
                                <div className="col-6">
                                    Reproducciones: {this.state.clase.reproducciones ? this.state.clase.reproducciones + 1 : 1}
                                </div>
                                <div className="col-6" style={{ textAlign: 'right' }}>
                                    {(this.state.inscrito) ?
                                        <div className="custom-control custom-switch">
                                            <span style={{ marginRight: '37px' }}>Visto: </span>
                                            <input onChange={this.handleChange} type="checkbox" className="custom-control-input" id={this.state.clase.id} value={this.state.clase.id} checked={this.state.clasevista} />
                                            <label className="custom-control-label" htmlFor={this.state.clase.id}> </label>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                            </div>
                            <hr style={{ borderTop: '5px solid #377dff' }} />
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <button onClick={this.handleShowNormalComentarios} className="btn btn-like">{this.state.totalComentarios} Comentarios</button>
                                </div>
                                <div className="col-6 mb-3" style={{ textAlign: 'right' }}>
                                    <button onClick={this.handleShowTOPComentarios} className="btn btn-like">TOP Comentarios</button>
                                </div>
                            </div>
                            {this.state.user && this.state.userStatus == "activo" ?
                                <div className="row mb-3" style={{ marginTop: '2rem' }}>
                                    <div className="col-2 col-lg-1  mb-1" style={{ textAlign: 'right' }}>
                                        <div className="avatar avatar-circle" >
                                            <img className="avatar-img" src={this.state.userImagen} alt="Image Description" />
                                        </div>
                                    </div>
                                    <div className="col-10 col-lg-11  mb-1">
                                        <div className="comentariosBox">
                                            <textarea className="form-control " value={this.state.comentario} onChange={this.handleText} rows="7" id="descriptionTextarea" placeholder="Escribe algún comentario..." style={{ marginTop: '0px', marginBottom: '0px', height: '104px' }} />
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ textAlign: 'right' }}>
                                        <button onClick={this.handleCancelar} className="btn btn-link mr-2 mb-2">Cancelar</button>
                                        <button onClick={this.handleComentar} className="btn btn-primary mb-2">Comentar</button>
                                    </div>
                                </div>
                                :
                                <Fragment></Fragment>
                            }
                            <div className="loaderMin" style={{ display: this.state.loadingComentarios ? 'flex' : 'none' }}>
                                <div className="Box"></div>
                            </div>
                            <div style={{ display: this.state.normalComentarios ? 'block' : 'none' }}>
                                <div style={{ display: this.state.loadingComentarios ? 'none' : 'block' }}>
                                    {this.state.comentarios.map((comentario, idx) => (
                                        <ComentarioItem key={idx} userStatus={this.state.userStatus} topComentarios={false} deleteComentario={this.deleteComentario} curso={this.state.curso} modulo={this.state.modulo} clase={this.state.clase.id} user={this.state.user} userImagen={this.state.userImagen} comentario={comentario} />
                                    ))}
                                </div>
                                {this.state.masComentarios ?
                                    <center><button className="btn btn-primary mt-3" style={{ width: '100%' }} onClick={this.handleCargarComentarios}>Cargar mas comentarios</button></center>
                                    :
                                    null
                                }
                            </div>
                            <div style={{ display: this.state.normalComentarios ? 'none' : 'block' }}>
                                <div style={{ display: this.state.loadingComentarios ? 'none' : 'block' }}>
                                    {this.state.comentariosTOP.map((comentarioTOP, idx) => (
                                        <ComentarioItem key={idx} userStatus={this.state.userStatus} indice={idx} topComentarios={true} deleteComentario={this.deleteComentario} curso={this.state.curso} modulo={this.state.modulo} clase={this.state.clase.id} user={this.state.user} userImagen={this.state.userImagen} comentario={comentarioTOP} />
                                    ))}
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </Layout>
            </div >
        )
    }
}
export default ClaseDetalle;