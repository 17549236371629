import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'

import QRCode from 'qrcode.react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faGavel, faServer } from '@fortawesome/free-solid-svg-icons'
import {  faCircle } from '@fortawesome/free-regular-svg-icons'


class DocumentacionFiber extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentación - Fibra Óptica';
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Documentación - Fibra Óptica">

                    <main id="content" role="main">
                        <div class=" faqBg">
                            <div class="container space-top-1 space-top-lg-2 textWhite space-bottom-2 position-relative z-index-2">
                                <div class="w-md-80 w-lg-60 text-center mx-md-auto">
                                    <h1 className="textWhite">Documentación</h1>
                                    <h3 className="textWhite">Fibra Óptica</h3>
                                </div>
                            </div>
                        </div>
                        <div class="container space-2 space-bottom-lg-3">
                            <div class="row justify-content-lg-center">
                                <div class="col-lg-10">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/">Red Itmex</Link></li>
                                        <li class="breadcrumb-item"><Link to="/documentacion">Documentación</Link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Fibra Óptica</li>
                                    </ol>
                                </nav>
                                    <div id="basics">
                                        <table class="table table-bordered w-100 mb-4">
                                            <tbody>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/nap-pro/pdf/v1">DATASHEET NAP-PRO</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/nap-pro/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/nap-ult/pdf/v1">DATASHEET NAP-ULT</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/nap-ult/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/nap-basx/pdf/v1">DATASHEET NAP-BASX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/nap-basx/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/nap-basv/pdf/v1">DATASHEET NAP-BASV</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/nap-basv/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/cee-rtix/pdf/v1">DATASHEET CEE-RTIX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/cee-rtix/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/cee-mex/pdf/v1">DATASHEET CEE-MEX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/cee-mex/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/ros-rtix/pdf/v1">DATASHEET ROS-RTIX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/ros-rtix/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/cfo-adss/pdf/v1">DATASHEET CFO-ADSS</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/cfo-adss/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/cfo-asux/pdf/v1">DATASHEET CFO-ASUX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/cfo-asux/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/cfo-droa/pdf/v1">DATASHEET CFO-DROA</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/cfo-droa/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/cfo-drob/pdf/v1">DATASHEET CFO-DROB</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/cfo-drob/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/cfo-rtip/pdf/v1">DATASHEET CFO-RTIP</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/cfo-rtip/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/rac-rtix/pdf/v1">DATASHEET RAC-RTIX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/rac-rtix/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/odf-rtix/pdf/v1">DATASHEET ODF-RTIX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/odf-rtix/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/fas-rtic/pdf/v1">DATASHEET FAS-RTIC</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/fas-rtic/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/bra-rtiu/pdf/v1">DATASHEET BRA-RTIU</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/bra-rtiu/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/sus-rtix/pdf/v1">DATASHEET SUS-RTIX</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/sus-rtix/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/sus-drom/pdf/v1">DATASHEET SUS-DROM</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/sus-drom/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="https://www.reditmex.com/documentacion/fiber/sus-drop/pdf/v1">DATASHEET SUS-DROP</a></td>
                                                    <td>v1</td>
                                                    <td>06/19/2022</td>
                                                    <td className='text-center'><QRCode value="https://www.reditmex.com/documentacion/fiber/sus-drop/pdf/v1" renderAs="canvas" size="256" includeMargin={true}/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr />
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-4 ">
                                                <div class="card w-100" >
                                                    <div class="card-body text-center">
                                                        <h1>
                                                            <span className="fa-layers fa-fw fa-2x">
                                                                <FontAwesomeIcon icon={faCircle}/>
                                                                <FontAwesomeIcon icon={faGavel} transform={'shrink-8'} />
                                                            </span>
                                                        </h1>
                                                        <h3 class="m-4">Legal</h3>
                                                        <Link to="/documentacion/legal/" class="btn btn-sm btn-primary w-100">Ir a Documentación</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
            </div>
        )
    }
}

export default DocumentacionFiber;