import React from 'react';
import { Link } from 'react-router-dom'
import { setMisClasesRef } from '../../firebase/firebase.utils'

class ClaseItemModuloCursoCatalogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clasevista: false,
        }
    }

    handleChange = (event) => {
        
        this.props.agregarClase(this.props.clase.id, this.state.clasevista)
        setMisClasesRef(this.props.user, this.props.curso, this.props.clase.id, !this.state.clasevista)
        this.setState({clasevista: !this.state.clasevista})
    }
    componentDidMount() {
        if (this.props.misclases) {
            this.props.misclases.find(e => {
                if (e == this.props.clase.id) {
                    this.setState({ clasevista: true })
                }
            })
        }
    }
    render() {
        return (

            <div className="border-bottom py-3 pr-3 pl-6">

                {(this.props.inscrito || this.props.clase.gratis) ?
                    <div className="row">
                        <div className="col-8">
                            <Link to={"/academia/curso/" + this.props.clase.curso + "/" + this.props.clase.modulo + "/" + this.props.clase.id}>
                                <i className={this.props.clase.tipo == 'video' ? 'fas fa-play-circle min-w-3rem text-center opacity-lg mt-1 mr-2 ml-1' : this.props.clase.tipo == 'articulo' ? 'fas fa-file-alt min-w-3rem text-center opacity-lg mt-1 mr-2 ml-1' : 'fas fa-file-download min-w-3rem text-center opacity-lg mt-1 mr-2 ml-1'}></i>
                                <span className="media-body">
                                    <span>{this.props.clase.indice} - {this.props.clase.nombreClase}</span>
                                </span>
                            </Link>

                        </div>

                        <div className="col-4 text-right">
                            <div className="row">
                                <div className="col-lg-6">
                                    {(this.props.inscrito) ?
                                        <div className="custom-control custom-switch">
                                            <input onChange={this.handleChange} type="checkbox" className="custom-control-input" id={this.props.clase.id} value={this.props.clase.id} checked={this.state.clasevista} />
                                            <label className="custom-control-label" htmlFor={this.props.clase.id}> </label>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className="col-lg-6">
                                    <span className="font-size-1">{this.props.clase.duracion}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col-8">
                            <i className={this.props.clase.tipo == 'video' ? 'fas fa-play-circle min-w-3rem text-center opacity-lg mt-1 mr-2 ml-1' : this.props.clase.tipo == 'articulo' ? 'fas fa-file-alt min-w-3rem text-center opacity-lg mt-1 mr-2 ml-1' : 'fas fa-file-download min-w-3rem text-center opacity-lg mt-1 mr-2 ml-1'}></i>
                            <span className="media-body">
                                <span>{this.props.clase.indice} - {this.props.clase.nombreClase}</span>
                            </span>
                        </div>
                        <div className="col-4 text-right">
                            <div className="row">
                                <div className="col-lg-6">
                                </div>
                                <div className="col-lg-6">
                                    <span className="font-size-1">{this.props.clase.duracion}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default ClaseItemModuloCursoCatalogo