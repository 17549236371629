import React from 'react';
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import QRCode from 'qrcode.react';

class QRCodeTool extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Tools - QRCode';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div>
                {this.props.location.search.substring(3)} <br />
                <QRCode value={this.props.location.search.substring(3)} renderAs="canvas" />
            </div>
        )
    }
}
export default QRCodeTool