import axios from "axios"
import { setVisitaBluePing } from "./firebase.utils"

export const getURLVisit = async (history, accountID) =>{
    const urlStorage = await localStorage.getItem(history.location.pathname)
    var navigatorValues = {
        language: navigator.language.toString(),
        app: navigator.appVersion.toString(),
        browser: navigator.userAgent.toString(),
        screen: window.screen.width.toString()+"x"+window.screen.height.toString()

    }
    if(!urlStorage){
        const now = new Date()
        const urlLocation = {
            value: { path: history.location.pathname, referrer: document.referrer},
            expiry: now.getTime() + 600000,
        }
        var userLoc =  localStorage.getItem('userLocation') ? JSON.parse( localStorage.getItem('userLocation')).value ? JSON.parse( localStorage.getItem('userLocation')).value : {method: 'No Data2'} : {method: 'No Data'};
        localStorage.setItem(history.location.pathname, JSON.stringify(urlLocation))
        setVisitaBluePing(accountID, userLoc,{ path: history.location.pathname, referrer: document.referrer}, navigatorValues)
    }
    else{
        const urlItem = JSON.parse(urlStorage)
        const now = new Date()
        if(now.getTime() > urlItem.expiry){
            localStorage.removeItem(history.location.pathname)
            const now = new Date()
            const urlLocation = {
                value: { path: history.location.pathname, referrer: document.referrer},
                expiry: now.getTime() + 600000,
            }
            console.log()
            var userLoc =  localStorage.getItem('userLocation') ? JSON.parse( localStorage.getItem('userLocation')).value ? JSON.parse(localStorage.getItem('userLocation')).value : {method: 'No Data3'} : {method: 'No Data4'};
            localStorage.setItem(history.location.pathname, JSON.stringify(urlLocation))
            setVisitaBluePing(accountID, userLoc,{ path: history.location.pathname, referrer: document.referrer }, navigatorValues)
        }else{
            //URI in storage 
        }
    }
}
const getPosition = () =>{
    return new Promise((res, rej)=>{
        navigator.geolocation.getCurrentPosition(res, rej )
    })
}
export const getLocation =  async () => {
    const locationStorage =  localStorage.getItem("userLocation")
    if(!locationStorage){
        if (navigator.geolocation) {
            await getPosition().then(async (pos)=>{
                await showPosition(pos)
            }).catch(async (error)=>{
                await locationError(error)
            })
        } else {
            await this.locationError("Geolocation is not supported")
        }
    }
    else{
        const locationItem = JSON.parse(locationStorage)
        const now = new Date()
        if(now.getTime() > locationItem.expiry){
             localStorage.removeItem("userLocation")
            if (navigator.geolocation) {
                await getPosition().then(async (pos)=>{
                    await showPosition(pos)
                }).catch(async (error)=>{
                    await locationError(error)
                })
            } else {
                await this.locationError("Geolocation is not supported")
            }
        }else{
            //Location in storage
        }
    }
}
const showPosition = async (position) => {
    var response = await axios(
        {
            url: 'https://nominatim.openstreetmap.org/reverse?format=json&lat=' + position.coords.latitude + '&lon=' + position.coords.longitude,
            method: 'get',
        }
    ).then(async (responseJson) => {
        if(responseJson.status === 200){
            // city: "Tulancingo de Bravo"
            // country: "México"
            // country_code: "mx"
            // country_name: "México"
            // latitude: 100.10
            // longitude: -100.10
            // neighbourhood: "Colonia"
            // postcode: "88888"
            // road: "Calle" *
            // shop: "asdf" * 
            // state: "State" *
            responseJson.data.address.method = "gps"
            responseJson.data.address.longitude = position.coords.longitude
            responseJson.data.address.latitude = position.coords.latitude
            responseJson.data.address.country_name = responseJson.data.address.country.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            responseJson.data.address.country_code = responseJson.data.address.country_code.toUpperCase()

            const now = new Date()
            const locationUser = {
                value: responseJson.data.address,
                expiry: now.getTime() + 3600000,
            }
            await localStorage.setItem("userLocation", JSON.stringify(locationUser))
            //console.log(responseJson.data)
        }
        else{
            await this.locationError("Error con OpenStreetMap.")
        }
    }).catch(async (error)=>{
        await this.locationError(error)
    })
    //console.log("Latitude: " + position.coords.latitude + " Longitude: " + position.coords.longitude)
}
const locationError = async (error) => {
    console.log(error)
    var response = await axios(
        {
            url: 'https://geolocation-db.com/json/d720c370-f16d-11eb-ae06-4167033db18b',
            method: 'get',
        }
    ).then(async (responseJson) => {
        // IPv4: "x.x.x.x"
        // city: "Mexico City"
        // country_code: "MX"
        // country_name: "Mexico"
        // latitude: 19.4357
        // longitude: -99.1439
        // postal: "63009"
        // state: "Mexico City"
        const now = new Date()
        responseJson.data.method = "ip"
        const locationUser = {
            value: responseJson.data,
            expiry: now.getTime() + 3600000,
        }
        await localStorage.setItem("userLocation", JSON.stringify(locationUser))
    }).catch((error) =>{
        console.log("Ubicación no registrada.")
    })
}