import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetFibraOptica_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Características, Especificaciones y Alcances de Paquetes de Internet Fibra Óptica';	
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_CARACTERISTICAS_ESPECIFICACIONES_ALCANCE_PAQUETES_INTERNET_FO_2024_V1.pdf?alt=media&token=682fecc7-4621-47cb-8f64-85130f1d978f';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default CaracteristicasEspecificacionesYAlcancesDePaquetesDeInternetFibraOptica_pdf