import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../core/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css'

class AcademiaFaq extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Academia FAQ';
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Academia FAQ">

                    <main id="content" role="main">
                        <div class=" faqBg">
                            <div class="container space-top-3 space-top-lg-4 textWhite space-bottom-2 position-relative z-index-2">
                                <div class="w-md-80 w-lg-60 text-center mx-md-auto">
                                    <h1 className="textWhite">Preguntas Frecuentes</h1>
                                    <p>¿Preguntas sobre la academia? Estamos para resolverlas &#129299;.</p>
                                </div>
                            </div>
                        </div>
                        <div class="container space-2 space-bottom-lg-3">
                            <div class="row justify-content-lg-center">
                                <div class="col-lg-8">
                                    <div id="basics" class="space-bottom-1">
                                        <h2>Básicas</h2>

                                        <div id="basicsAccordion">
                                            <div class="card shadow-none mb-3">
                                                <div class="card-header card-collapse" id="basicsHeadingOne">
                                                    <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseOne" aria-expanded="false" aria-controls="basicsCollapseOne">
                                                        ¿Cómo puedo inscribirme a un curso?
                                                        <span class="card-btn-toggle">
                                                            <span class="card-btn-toggle-default"><i className="fas fa-plus"></i></span>
                                                            <span class="card-btn-toggle-active"><i className="fas fa-minus"></i></span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div id="basicsCollapseOne" class="collapse" aria-labelledby="basicsHeadingOne" data-parent="#basicsAccordion">
                                                    <div class="card-body px-0">
                                                        <p>Por el momento la única opción es comprando el curso a través de alguien del equipo. Para eso no dudes en contactarnos en cualquiera de nuestros puntos de <Link to="/contacto">contacto</Link>. Llamada, correo o mensaje nosotros estaremos contentos de ayudarte en el proceso de inscripción.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
                        </div>
        )
    }
}

export default AcademiaFaq;