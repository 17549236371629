import React, { Fragment } from 'react';

import { formatedTime } from '../../utils/timeUtils'
import { firestore } from 'firebase';
import { getUserRef, deleteSubcomentario, setNoMeGustaSubcomentario, setMeGustaSubcomentario } from '../../firebase/firebase.utils'
class SubComentarioItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subcomentario: '',
            userSubcomentario: '',
            liked: false,
            baneado: false,
        }
    }
    componentDidMount() {
        this.setState({
            subcomentario: this.props.subcomentario.subcomentario,
            createdAt: new Date(this.props.subcomentario.createdAt.seconds * 1000).toLocaleString('es-MX'),
            userSubcomentario: this.props.subcomentario.userID,
            likes: this.props.subcomentario.likes,
            baneado: this.props.subcomentario.baneado,
        })
        if (this.props.subcomentario.likesUser) {
            this.props.subcomentario.likesUser.find(e => {
                if (e == this.props.user) {
                    this.setState({ liked: true })
                }
            })
        }
        getUserRef(this.props.subcomentario.userID).get().then(user => {
            if (user.exists) {
                this.setState({ userSubcomentario: user.data() })
            }
        })
    }
    borrarComentario = async () => {
        await deleteSubcomentario(this.props.curso, this.props.modulo, this.props.clase, this.props.comentario, this.props.subcomentario.id, this.props.subcomentario.userID, this.props.user)
        this.props.deleteSubcomentario()
    }
    meGustaComentario = () => {
        this.setState({ likes: this.state.likes + 1, liked: true })
        setMeGustaSubcomentario(this.props.curso, this.props.modulo, this.props.clase, this.props.comentario, this.props.subcomentario.id, this.props.subcomentario.userID, this.props.user)
    }
    noMeGustaComentario = () => {
        this.setState({ likes: this.state.likes - 1, liked: false })
        setNoMeGustaSubcomentario(this.props.curso, this.props.modulo, this.props.clase, this.props.comentario, this.props.subcomentario.id, this.props.subcomentario.userID, this.props.user)
    }
    render() {
        return (
            <Fragment>
                <div className="row mb-1">
                    <div className="col-2 col-lg-1  mb-1" >
                    </div>
                    <div className="col-2 col-lg-1  mb-1" style={{ textAlign: 'right' }}>
                        <div className="avatar avatar-circle" style={{ marginTop: '10px' }}>
                            <img className="avatar-img" src={this.state.userSubcomentario.photoURL} alt="Image Description" />
                        </div>
                    </div>
                    <div className="col-8 col-lg-10  mb-1">
                        <div className="col-lg-12">
                            <b>{this.state.userSubcomentario.displayName}</b> - {this.state.createdAt} respondió:
                            <p className={this.state.baneado ? "comentario comentarioBaneado" : "comentario "}>{this.state.baneado ? 'Esta respuesta ha sido bloqueada por infringir las Normas de la Comunidad.' : this.state.subcomentario}</p>
                            {this.state.baneado ?
                                null
                                :
                                this.state.liked ?
                                    <button onClick={this.noMeGustaComentario} className="btn btn-likeActive"><i className="fas fa-thumbs-up"></i> {this.state.likes} Me gusta</button>
                                    :
                                    <button onClick={this.meGustaComentario} className="btn btn-like"><i className="far fa-thumbs-up"></i> {this.state.likes} Me gusta</button>
                            }
                            {this.props.subcomentario.userID == this.props.user ?
                                <button onClick={this.borrarComentario} className="btn btn-like d-none d-md-block float-right">Eliminar</button>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default SubComentarioItem