import React, { Fragment } from 'react';
import Layout from '../core/Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'

import { getUserData, signOutItmex } from '../auth/auth.utils'
import UserSideMenu from './components/UserSideMenu';


import { auth, getAwardsRef, createUserProfileDocument } from '../firebase/firebase.utils'
import Recompensa from './components/Recompensa';

class UserRecompensas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUserData(),
            currentUser: {
                id: '',
                displayName: '',
                puntuacion: '',
            },
            awards: []
        }
    }
    obtenerRecompensas = async () => {
        await getAwardsRef().then((arrayAwards) => {
            if (arrayAwards.length > 0) {
                this.setState({ awards: arrayAwards })
            }
        })
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                userRef.onSnapshot(snapShot => {
                    //console.log(snapShot.id)
                    this.setState({
                        currentUser: {
                            id: snapShot.id,
                            displayName: snapShot.data().displayName,
                            puntuacion: snapShot.data().puntuacion
                        },
                        loading: false
                    });
                })
                this.obtenerRecompensas()
            }
        })
        document.title = 'Red Itmex - Recompensas';
    }
    componentWillUnmount() {
        this.unsubscribeFromAuth();
    }
    salir = e => {
        e.preventDefault();
        signOutItmex(() => {
            this.props.history.push('/login');
        });
    }
    render() {
        return (
            <div className=" ">

                <Layout title="Red Itmex - Planes">

                    <main id="content" role="main" className="bg-light">
                        <div className="bg-navy" style={{ backgroundImage: 'url(/assets/svg/components/abstract-shapes-20.svg)' }}>
                            <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="d-none d-lg-block">
                                            <h1 className="h2 text-white">Mis Recompensas</h1>
                                        </div>

                                        <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                                            <li className="breadcrumb-item">Cuenta</li>
                                            <li className="breadcrumb-item active" aria-current="page">Recompensas</li>
                                        </ol>
                                    </div>

                                    <div className="col-auto">
                                        <div className="d-none d-lg-block">
                                            <button onClick={this.salir} className="btn btn-sm btn-soft-light" href="#">Cerrar Sesión</button>
                                        </div>

                                        <button type="button" className="navbar-toggler btn btn-icon btn-sm rounde-circle d-lg-none"
                                            aria-label="Toggle navigation"
                                            aria-expanded="false"
                                            aria-controls="sidebarNav"
                                            data-toggle="collapse"
                                            data-target="#sidebarNav">
                                            <span className="navbar-toggler-default">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
                                                </svg>
                                            </span>
                                            <span className="navbar-toggler-toggled">
                                                <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container space-1 space-top-lg-0 mt-lg-n10">
                            <div className="row">

                                <UserSideMenu />
                                <div className="col-lg-9">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-header-title">Mis Recompensas</h5>
                                        </div>
                                        
                                        <div className="card-body">
                                            <h3>Premios</h3>
                                            <div className="row align-items-end">
                                                {this.state.awards.length > 0 ?
                                                    <Fragment>
                                                        {
                                                            this.state.awards.map((award) => (
                                                                <Recompensa recompensa={award} disponible={this.state.currentUser.puntuacion >= award.puntos ? true : false}/>
                                                            ))
                                                        }
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>   
                                        <div className="card-body">
                                            <h3>¡Hola {this.state.currentUser.displayName}!</h3>
                                            <p>
                                                Estamos contentos de que sigas aprendiendo y adquiriendo nuevas habilidades y conocimientos con nosotros.
                                                ¡Es por eso que hemos decidido darte un <b>premio</b>!
                                            </p>
                                            <p>
                                                A partir de ahora cada acción que tomes en Red Itmex hará que ganes <i className="fas fa-donate PrimerLugar"></i> Monedas, las cuáles puedes canjear por alguno de nuestros increíbles premios. Mientras más monedas tengas, mejores son las recompensas.
                                            </p>
                                            <p>
                                                Te dejamos algunos <b>tips</b> que te ayudarán a ganar monedas (puede que haya algunas más escondidas por ahí):
                                            </p>

                                            <h4><i className="fas fa-comments"></i> ¡Sigue comentando!</h4>
                                            <p>Deja comentarios en cada una de tus clases. Dudas, sugerencias o tips son bien recibidos. Estos podrian ser de ayuda para alguien más. Recuerda no decir malas palabras ni dejar comentarios sin sentido, podrías ser multado por eso.</p>
                                            <h4><i className="fas fa-thumbs-up"></i> ¿Acaso eres influencer?</h4>
                                            <p>Los likes que otros estudiantes le den a tus comentarios te ayudan a seguir ganando, así que deja comentarios útiles, graciosos o que consideres importantes de compartir.</p>
                                            <h4><i className="fas fa-toggle-on"></i> Eso ya me lo sé.</h4>
                                            <p>No olvides marcar las clases que ya hayas visto. No querrás ver clases repetidas la próxima vez que te conectes. ¡Mientras mas aprendas, más lejos llegarás!</p>
                                            <h4><i className="fas fa-shopping-cart"></i> ¿Hoy que aprenderás?</h4>
                                            <p>Comprar nuevos cursos te da más posibilidades de seguir aprendiendo. No te conformes solo con uno, ¡ve por más!</p>


                                            <p>
                                                Si quieres canjear tus monedas por alguno de los premios disponibles, llama o manda un mensaje a alguno de nuestros <Link to="/contacto">asesores</Link>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Layout>
            </div>
        )
    }
}

export default UserRecompensas;