import React from 'react';
import { getLocation } from '../../../firebase/locationUtils';
import { getURLVisit } from '../../../firebase/locationUtils';

class AvisoAtencionAUsuarios_pdf extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Documentacion - Aviso Atención a Usuarios';
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/reditmextrescero.appspot.com/o/datasheets%2Flegal%2FITMEX_AVISO_ATENCION_A_USUARIOS_2024_V1.pdf?alt=media&token=b356d8a3-9764-4619-abe7-1283079c1d51';
    }
    render() {
        return (
            <div>Loading...</div>
        )
    }
}
export default AvisoAtencionAUsuarios_pdf